import "./dateSelector.sass";
import React from "react";

const DateSelector = ({
  date,
  setDateCurrent,
  format = "day",
  className,
  setEndDate,
}) => {
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const addMonths = (date, months) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  };

  const addYears = (date, years) => {
    const result = new Date(date);
    result.setFullYear(result.getFullYear() + years);
    return result;
  };

  const formatDate = (date) => {
    switch (format) {
      case "day":
        const optionsDay = { day: "numeric", month: "long", year: "numeric" };
        return date.toLocaleDateString("ru-RU", optionsDay);
      case "week":
        let dayOfWeek = date.getDay();
        let startOfWeek =
          dayOfWeek === 0 ? addDays(date, -6) : addDays(date, -dayOfWeek + 1);
        let endOfWeek = addDays(startOfWeek, 6);
        const optionsWeek = { day: "numeric", month: "numeric" };
        return `${startOfWeek.toLocaleDateString(
          "ru-RU",
          optionsWeek
        )} - ${endOfWeek.toLocaleDateString("ru-RU", optionsWeek)}`;
      case "month":
        const optionsMonth = { month: "long", year: "numeric" };
        return date.toLocaleDateString("ru-RU", optionsMonth);
      case "year":
        const optionsYear = { year: "numeric" };
        return date.toLocaleDateString("ru-RU", optionsYear);
      default:
        return date.toLocaleDateString("ru-RU");
    }
  };

  const formattedDate = formatDate(date);

  // Обработчики для кнопок, адаптированные под формат
  const handlePreviousClick = () => {
    switch (format) {
      case "week":
        setDateCurrent(addDays(date, -7));
        setEndDate && setEndDate(addDays(date, -7));
        break;
      case "month":
        setDateCurrent(addMonths(date, -1));
        setEndDate && setEndDate(addMonths(date, -1));
        break;
      case "year":
        setDateCurrent(addYears(date, -1));
        setEndDate && setEndDate(addYears(date, -1));
        break;
      default:
        setDateCurrent(addDays(date, -1));
        setEndDate && setEndDate(addDays(date, -1));
    }
  };

  const handleTodayClick = () => {
    setDateCurrent(() => {
      const start = new Date();
      start.setHours(0, 0, 0, 0); // Устанавливаем начало дня
      return start;
    });

    setEndDate && setEndDate(new Date());
  };

  const handleNextClick = () => {
    switch (format) {
      case "week":
        setDateCurrent(addDays(date, 7));
        setEndDate && setEndDate(addDays(date, 7));
        break;
      case "month":
        setDateCurrent(addMonths(date, 1));
        setEndDate && setEndDate(addMonths(date, 1));
        break;
      case "year":
        setDateCurrent(addYears(date, 1));
        setEndDate && setEndDate(addYears(date, 1));
        break;
      default:
        setDateCurrent(addDays(date, 1));
        setEndDate && setEndDate(addDays(date, 1));
    }
  };

  return (
    <div className={`date-picker ${className}`}>
      <button
        className="date-picker__button-left button-date-selector"
        onClick={handlePreviousClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          viewBox="0 -960 960 960"
          width="20"
        >
          <path d="m438-480 164 164q11 11 11 25.5T602-265q-11 11-25.5 11t-25.838-11.338L361-455q-5-5.4-7.5-11.7-2.5-6.3-2.5-13.5t2.5-13.5Q356-500 361-505l189.662-189.662Q562-706 576.5-706q14.5 0 25.5 11t11 25.5q0 14.5-11 25.5L438-480Z" />
        </svg>
      </button>

      <button
        className="date-picker__button-center button-date-selector"
        onClick={handleTodayClick}
      >
        {formattedDate}
      </button>

      <button
        className="date-picker__button-right button-date-selector"
        onClick={handleNextClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          viewBox="0 -960 960 960"
          width="20"
        >
          <path d="M522-480 358-644q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.838 11.338L599-505q5 5.4 7.5 11.7 2.5 6.3 2.5 13.5t-2.5 13.5Q604-460 599-455L409.338-265.338Q398-254 384-254.5q-14-.5-25-11.5t-11-25.5q0-14.5 11-25.5l163-163Z" />
        </svg>
      </button>
    </div>
  );
};

export default DateSelector;
