import SalesPieChart from "../salesChart/SalesPieChart";
import "./homeInfoComponents.sass";
import React, { useState, useEffect } from "react";
import useCityDataStudents from "../../hooks/useCityDataStudents";

const DataStudents = () => {
  const [isData, setData] = useState([]);
  const [isLoadingData, setLoadingData] = useState(false);

  const { data, isLoading, error } = useCityDataStudents("voronezh");

  useEffect(() => {
    setData(data);
  }, [data]);

  return (
    <div className="data-main-container data-mini-student-data">
      <div className="data-mini-student-data__container-data-info">
        <h5 className="data-mini-student-data__title">Кол-во учеников</h5>
        <div className="legend">
          <div className="legend__top">
            <div className="legend__indicator legend__indicator__blue-color"></div>
            <h5 className="legend__title">Активные</h5>
          </div>

          <h5 className="legend__data">
            {isData ? isData.activeStudents : "--"}
          </h5>
        </div>
        <div className="legend">
          <div className="legend__top">
            <div className="legend__indicator legend__indicator__grayblue-color"></div>
            <h5 className="legend__title">Неактивные</h5>
          </div>

          <h5 className="legend__data">
            {isData ? isData.inactiveStudents : "--"}
          </h5>
        </div>
      </div>

      <div className="data-mini-student-data__chart">
        <SalesPieChart initialData={isData} loading={isLoadingData} />
        <div className="data-mini-student-data__container-total-student">
          <h4 className="data-mini-student-data__title-total-student">
            {isData ? isData.totalStudents : "--"}
          </h4>
          <h4 className="data-mini-student-data__subtitle-total-student">
            Всего
          </h4>
        </div>
      </div>
    </div>
  );
};

export default DataStudents;
