import "./viewingLessonStyles.sass";
import recordingAttendanceInDatabase from "../../hooks/recordingAttendanceInDatabase";

import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Query } from "appwrite";
import { databases } from "../../lib/appwrite";

import OptionMenu from "../optionMenu/OptionMenu";
import Input from "../input/Input";
import Button from "../button/Button";
import PopupMenu from "../popupMenu/PopupMenu";
import IconButton from "../iconButton/IconButton";

import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
} from "@mui/material";

const typeOfLesson = (type) => {
  if (type === "indiv") {
    return "Индивидуальный урок";
  } else if (type === "group") {
    return "Групповой урок";
  } else if (type === "acter") {
    return "Актерское мастерство";
  } else {
    return "----";
  }
};

const ViewingLesson = ({ dataLesson, setViewOpen }) => {
  const navigate = useNavigate();

  const { status, studentErrors, markAttendance } =
    recordingAttendanceInDatabase();

  // Состояния
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAtendance, setIsLoadingAtendance] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [savingIsAllowed, setSavingIsAllowed] = useState(false);
  const [idLessonVisible, setIdLessonVisible] = useState(false);
  const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isAlertError, setAlertError] = useState(false);

  const [attendanceSelect, setAttendanceSelect] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentCost, setPaymentCost] = useState("");
  const [paymentCostCheck, setPaymentCostCheck] = useState(false);
  const [teacherData, setTeacherData] = useState(null);
  const [studentData, setStudentData] = useState(null);
  const [isStudentPayment, setStudentPayment] = useState(null);
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isErrorMessageAlert, setErrorMessageAlert] = useState(["", ""]);

  const dateLesson = new Date(dataLesson.date_start).toLocaleDateString(
    "ru-RU",
    { day: "numeric", month: "long" }
  );

  const attendanceData = [
    { value: "", label: "Выберите..." },
    { value: "present", label: "Присутствовал" },
    { value: "absent", label: "Не присутствовал" },
    { value: "respectful", label: "Не присутствовал (уваж.)" },
  ];
  const paymentData = [
    { value: "", label: "Выберите..." },
    { value: "account", label: "Баланс" },
    { value: "points", label: "Баллы" },
    { value: "certificate", label: "Сертификат" },
  ];

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length !== 12) {
      console.error("Ошибка форматирования номера телефона:", phoneNumber);
      setErrorMessageAlert([
        "Ошибка форматирования номера телефона",
        phoneNumber,
      ]);
      setAlertError(true);
      return phoneNumber; // Возвращаем оригинальный номер, если длина неверна
    }

    const parts = phoneNumber.split("");
    return `${parts.slice(0, 2).join("")} (${parts
      .slice(2, 5)
      .join("")}) ${parts.slice(5, 8).join("")}-${parts
      .slice(8, 10)
      .join("")}-${parts.slice(10).join("")}`;
  };

  // Открытие меню
  const handlePopupMenuOpen = () => {
    setIsMenuOpen(true);
  };

  // Закрытие меню
  const handlePopupMenuClose = () => {
    setIsMenuOpen(false);
  };

  // Нажатие на пункт меню
  const handleClickMenu = async (key, infoUser) => {
    switch (key) {
      case "edit":
        handleOpenEditLesson();
        break;
      case "delete":
        handleOpenDialogDeleteLesson();
        break;

      default:
        console.log("Error");
    }
  };

  const getUserData = useCallback(async (teacher, student) => {
    setIsLoading(true);
    try {
      const promises = [];

      if (teacher) {
        promises.push(
          databases.getDocument(
            "65f9d6f4dbeeccbe7e74",
            "65f9d6fd218cc728529a",
            teacher
          )
        );
      } else {
        promises.push(null); // Добавляем null, чтобы сохранить порядок данных
      }

      if (student) {
        promises.push(
          databases.getDocument(
            "65f9d6f4dbeeccbe7e74",
            "65f9d6fd218cc728529a",
            student
          )
        );
      } else {
        promises.push(null); // Добавляем null, чтобы сохранить порядок данных
      }

      const [teacherData, studentData] = await Promise.all(promises);

      if (teacherData) setTeacherData(teacherData);
      else setTeacherData(null); // Оставляем поле пустым, если учителя нет

      if (studentData) setStudentData(studentData);
      else setStudentData(null); // Оставляем поле пустым, если ученика нет
    } catch (error) {
      console.error("Ошибка получения преподавателя/учеников:", error);
      setErrorMessageAlert([
        "Ошибка получения преподавателя/учеников",
        error.message,
      ]);
      setAlertError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getSubscriptionStudentLesson = useCallback(async () => {
    if (dataLesson.attendance && dataLesson.students.length > 0) {
      try {
        const response = await databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "661cdbdec056444bc33f",
          [
            Query.equal("id_lesson", dataLesson.$id),
            Query.equal("id_student", dataLesson.students[0]),
          ]
        );

        if (response.documents.length > 0) {
          setStudentPayment(response.documents[0]);
        } else {
          console.log("Документы не найдены.");
        }
      } catch (error) {
        console.error("Ошибка при проверке данных:", error);
        setErrorMessageAlert(["Ошибка при проверке данных", error.message]);
        setAlertError(true);
      }
    }
  }, [dataLesson]);

  const handleOpenEditLesson = () => {
    navigate("/admin/schedule/creator-lesson", {
      state: { lessonData: dataLesson },
    });
  };

  const handleMarkAttendance = () => {
    // Проверка наличия данных урока и учащихся
    if (
      !dataLesson ||
      !dataLesson.students ||
      dataLesson.students.length === 0
    ) {
      console.error("Данные отсутствуют или учащиеся недоступны");
      setErrorMessageAlert([
        "Ошибка",
        "Данные отсутствуют или учащиеся недоступны",
      ]);
      setAlertError(true);
      return;
    }

    const { $id, students } = dataLesson;

    // Только для индива
    const setTypePayment =
      attendanceSelect === "respectful" ? null : paymentType;
    const setPaymentAmount =
      paymentType === "points" && attendanceSelect !== "respectful"
        ? paymentCost
        : 0;

    const studentsData = [
      {
        id_lesson: $id,
        id_student: students[0],
        attendance: attendanceSelect,
        payment_method: setTypePayment,
        payment_amount: Number(setPaymentAmount),
        module: dataLesson.lesson_module || null,
        lesson: dataLesson.class_number || null,
      },
    ];

    // Вызов функции markAttendance с подготовленными данными
    markAttendance(studentsData, [studentData], true, dataLesson.date_start);
  };

  // Удаление урока
  const handleDeleteLesson = async () => {
    try {
      await databases.deleteDocument(
        "65f9d6f4dbeeccbe7e74",
        "66143d053c25fc1c3d4d",
        dataLesson.$id
      );

      setViewOpen(false);
    } catch (error) {
      console.error("Ошибка при удалении урока:", error);
      setErrorMessageAlert(["Ошибка при удалении урока", error.message]);
      setAlertError(true);
    }
  };

  // Открытие окна удаления
  const handleOpenDialogDeleteLesson = () => {
    setOpenDeleteDialog(true);
  };

  // Закрытие окна удаления
  const handleCloseDialogDeleteLesson = () => {
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    if (paymentType === "points") {
      setPaymentCostCheck(true);
    } else {
      setPaymentCostCheck(false);
    }
  }, [paymentType, dataLesson]);

  useEffect(() => {
    getUserData(dataLesson.teacher, dataLesson.students[0]);
    getSubscriptionStudentLesson();
  }, [dataLesson, getUserData, getSubscriptionStudentLesson]);

  useEffect(() => {
    const isValidAttendance = attendanceSelect === "respectful";
    const hasRequiredPaymentInfo =
      paymentType && (paymentCostCheck ? paymentCost : true);

    setSavingIsAllowed(
      attendanceSelect && (isValidAttendance || hasRequiredPaymentInfo)
    );
  }, [
    attendanceSelect,
    paymentType,
    paymentCost,
    paymentCostCheck,
    setSavingIsAllowed,
  ]);

  useEffect(() => {
    //console.log(`Статус: ${status}`);

    // Обработка статусов
    switch (status) {
      case "loading":
        setIsLoadingAtendance(true);
        break;
      case "completed":
        setIsLoadingAtendance(false);
        dataLesson.attendance = true;
        setViewOpen(false);
        break;

      case "error":
        setIsLoadingAtendance(false);
        if (studentErrors) {
          const errorMessages = {
            dublicate: "Статус урока уже был изменен",
            points: "Недостаточно баллов для оплаты!",
            balance: "Недостаточно средств для оплаты!",
            unknown:
              "Произошла неизвестная ошибка, обновите страницу и попробуйте снова",
          };
          // Перебор массива ошибок и отображение сообщений
          studentErrors.forEach((errorInfo) => {
            console.error(
              `Ошибка у ученика ${errorInfo.studentId} : ${
                errorMessages[errorInfo.error.split(" ").pop()] ||
                "Произошла ошибка, повторите попытку позже"
              }`
            );
            // Здесь можно добавить логику для отображения ошибок в интерфейсе пользователя
            setErrorMessage(
              `Ошибка (${errorInfo.studentId}): ${
                errorMessages[errorInfo.error.split(" ").pop()] ||
                "Произошла ошибка, повторите попытку позже"
              }`
            );
            setErrorMessageAlert([
              `Ошибка (${errorInfo.studentId})` ||
                "Произошла ошибка, повторите попытку позже",
              `${errorMessages[errorInfo.error.split(" ").pop()]}`,
            ]);
            setAlertError(true);
          });
        }
        break;
      default:
        setErrorMessage("");
        break;
    }
  }, [status, studentErrors, dataLesson, setViewOpen]);

  useEffect(() => {
    if (isStudentPayment) {
      setAttendanceSelect(isStudentPayment.attendance);
      setPaymentType(
        isStudentPayment.payment_method ? isStudentPayment.payment_method : ""
      );
      setPaymentCost(isStudentPayment.payment_amount);
    }
  }, [isStudentPayment]);

  return (
    <div className="viewing-lesson-blackout">
      <div className="viewing-lesson">
        {isLoading ? (
          <div className="loading-container-lesson">
            <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
              <CircularProgress color="inherit" />
            </Stack>
          </div>
        ) : (
          <div className="viewing-lesson__content">
            {isLoadingAtendance && (
              <div className="loading-container-lesson-attendance">
                <Stack sx={{ color: "#748091" }} spacing={2} direction="row">
                  <CircularProgress color="inherit" />
                </Stack>
                <h4 className="loading-container-lesson-attendance__text">
                  Обновление данных...
                </h4>
              </div>
            )}
            <IconButton
              className={"viewing-lesson__popup-menu"}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400Z" />
                </svg>
              }
              onClick={handlePopupMenuOpen}
            />

            {dataLesson.attendance ? (
              <PopupMenu
                onMouseLeave={handlePopupMenuClose}
                inOpen={isMenuOpen}
                menuItems={[{ label: "Удалить", key: "delete" }]}
                onMenuItemClick={(item) => {
                  handleClickMenu(item.key);

                  handlePopupMenuClose();
                }}
                styleMenu={"menu-lesson-viewing"}
              />
            ) : (
              <PopupMenu
                onMouseLeave={handlePopupMenuClose}
                inOpen={isMenuOpen}
                menuItems={[
                  { label: "Редактировать", key: "edit" },
                  { label: "Удалить", key: "delete" },
                ]}
                onMenuItemClick={(item) => {
                  handleClickMenu(item.key);

                  handlePopupMenuClose();
                }}
                styleMenu={"menu-lesson-viewing"}
              />
            )}

            <h3 className="viewing-lesson__title-date">
              {`${dateLesson}, ${Math.floor(
                dataLesson.time_start + 10
              )}:${String(
                Math.floor(((dataLesson.time_start + 10) % 1) * 60)
              ).padStart(2, "0")} - ${Math.floor(
                dataLesson.time_start + 10 + dataLesson.duration
              )}:${String(
                Math.floor(
                  ((dataLesson.time_start + 10 + dataLesson.duration) % 1) * 60
                )
              ).padStart(2, "0")}`}
            </h3>
            {idLessonVisible ? (
              <h4
                className="viewing-lesson__title-type-lesson"
                onDoubleClick={() => setIdLessonVisible(!idLessonVisible)}
              >
                {`ID ${dataLesson.$id}`}
              </h4>
            ) : (
              <h4
                className="viewing-lesson__title-type-lesson"
                onDoubleClick={() => setIdLessonVisible(!idLessonVisible)}
              >
                {typeOfLesson(dataLesson.type_lesson)}
              </h4>
            )}
            <div className="viewing-lesson__teacher">
              {teacherData && teacherData.avatar_image ? (
                <img
                  className="viewing-lesson__avatar"
                  src={teacherData.avatar_image}
                  alt="Avatar"
                />
              ) : (
                <div className="viewing-lesson__avatar"></div>
              )}

              <div className="viewing-lesson__data-container">
                <h4 className="viewing-lesson__username">
                  {teacherData
                    ? `${teacherData.name} ${teacherData.sur_name}`
                    : "----"}
                </h4>
                <h4 className="viewing-lesson__phone">
                  {teacherData
                    ? formatPhoneNumber(teacherData.phone_number)
                    : "----"}
                </h4>
              </div>
            </div>
            <h4 className="viewing-lesson__title-students">Ученик</h4>
            {dataLesson.students.length === 0 ? (
              <div className="viewing-lesson__student">
                <h4 className="viewing-lesson__username">Ученик не указан</h4>
              </div>
            ) : (
              <div className="viewing-lesson__student">
                {studentData && studentData.avatar_image ? (
                  <img
                    className="viewing-lesson__avatar"
                    src={studentData.avatar_image}
                    alt="Avatar"
                  />
                ) : (
                  <div className="viewing-lesson__avatar"></div>
                )}

                <div className="viewing-lesson__data-container">
                  <h4 className="viewing-lesson__username">
                    {studentData
                      ? `${studentData.name} ${studentData.sur_name}`
                      : "----"}
                  </h4>
                  <h4 className="viewing-lesson__phone">
                    {studentData
                      ? formatPhoneNumber(studentData.phone_number)
                      : "----"}
                  </h4>
                </div>
              </div>
            )}
            <h4 className="viewing-lesson__title-lesson">Урок</h4>
            <h4 className="viewing-lesson__number-lesson">
              {dataLesson.lesson_module === 0 && dataLesson.class_number === 0
                ? "Урок не указан"
                : `${dataLesson.lesson_module} модуль ${dataLesson.class_number} урок`}
            </h4>
            <h4 className="viewing-lesson__title-comment">Комментарий</h4>
            <h4 className="viewing-lesson__comment">
              {dataLesson.comment ? dataLesson.comment : "----"}
            </h4>
            <OptionMenu
              id={"attendanceSelectMenu"}
              options={attendanceData}
              title={"Присутствие"}
              value={attendanceSelect}
              className={"viewing-lesson__attendance-select"}
              onChange={(e) => setAttendanceSelect(e.target.value)}
              classSelect={dataLesson.attendance && "option-hide"}
              disabled={dataLesson.attendance}
            />
            <div className="viewing-lesson__payment-container">
              <OptionMenu
                id={"paymentTypeMenu"}
                options={paymentData}
                title={"Оплата"}
                value={paymentType}
                className={"viewing-lesson__payment-type"}
                onChange={(e) => setPaymentType(e.target.value)}
                classSelect={
                  attendanceSelect === "respectful" || dataLesson.attendance
                    ? "option-hide"
                    : ""
                }
                disabled={
                  attendanceSelect === "respectful" || dataLesson.attendance
                    ? true
                    : false
                }
              />
              <Input
                title={"."}
                id={"paymentCostInput"}
                className={`viewing-lesson__payment-cost ${
                  !paymentCostCheck && "input-hide"
                } ${attendanceSelect === "respectful" && "input-hide"} ${
                  dataLesson.attendance && "input-hide"
                }`}
                classContainer={"viewing-lesson__payment-cost-container"}
                placeHolder={"title-hide"}
                type={"number"}
                value={paymentCost}
                disabled={
                  !paymentCostCheck ||
                  attendanceSelect === "respectful" ||
                  dataLesson.attendance
                }
                onChange={(e) => setPaymentCost(e.target.value)}
                placeholder={"Сумма"}
              />
            </div>
            <div className="viewing-lesson__buttons">
              <Button
                colorStyle={"invisible"}
                className={"viewing-lesson__button-canel"}
                onClick={() => setViewOpen(false)}
              >
                Отмена
              </Button>
              <Button
                colorStyle={"blue"}
                className={"viewing-lesson__button-save"}
                disabled={
                  !dataLesson.attendance && savingIsAllowed ? false : true
                }
                onClick={handleMarkAttendance}
              >
                Сохранить
              </Button>
            </div>
            {isErrorMessage && (
              <h4 className="viewing-lesson__error-message-bottom-toolbar">
                {isErrorMessage}
              </h4>
            )}
          </div>
        )}
      </div>

      <Dialog
        open={isOpenDeleteDialog}
        onClose={handleCloseDialogDeleteLesson}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Вы уверены, что хотите удалить этот урок?`}
        </DialogTitle>

        <DialogActions>
          <Button
            colorStyle={"invisible"}
            onClick={handleCloseDialogDeleteLesson}
          >
            Отмена
          </Button>
          <Button
            colorStyle={"red"}
            onClick={() => handleDeleteLesson()}
            autoFocus
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>

      {/* <div
        className="viewing-lesson-box-close"
        onClick={() => setViewOpen(false)}
      ></div> */}

      <Snackbar
        open={isAlertError}
        autoHideDuration={6000}
        onClose={() => setAlertError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAlertError(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <AlertTitle>{isErrorMessageAlert[0]}</AlertTitle>
          {isErrorMessageAlert[1]}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ViewingLesson;
