import "./statistic.sass";
import { useCallback, useEffect, useState } from "react";

import Sidebar from "../../components/sidebar/Sidebar";
import TypeSelector from "../../components/typSelector/TypeSelector";
import StatisticGraphic from "../../components/statisticGraphic/StatisticGraphic";
import IconButton from "../../components/iconButton/IconButton";

const Statistic = () => {
  const [timePeriod, setTimePeriod] = useState("week");
  const [isTypeGraphic, setTypeGraphic] = useState("day");
  const [isUpdate, setUpdate] = useState(false);

  const typeSelectorOptions = [
    { value: "week", label: "Неделя" },
    { value: "month", label: "Месяц" },
    { value: "year", label: "Год" },
    { value: "allTime", label: "Всё время" },
  ];

  useEffect(() => {
    document.title = "Статистика";
  }, []);

  const handleSelectionChange = useCallback((selectedValue) => {
    if (selectedValue === "week" || selectedValue === "month") {
      setTypeGraphic("day");
    } else if (selectedValue === "year") {
      setTypeGraphic("month");
    } else {
      setTypeGraphic("year");
    }
    setTimePeriod(selectedValue);
  }, []);

  const handleUpdateStatisticInfo = () => {
    setUpdate(true);
  };

  return (
    <div className="statistic desktop-version">
      <Sidebar />
      <div className="main-container-pages">
        <div className="main-container-pages__container">
          <div className="main-container-pages__texts">
            <h2 className="main-container-pages__title">Статистика</h2>
            <h3 className="main-container-pages__city">Белгород</h3>
          </div>

          <div className="main-container-pages__toolbar">
            <TypeSelector
              className={"main-container-pages__selector-pages-start"}
              options={typeSelectorOptions}
              initialSelected={timePeriod}
              onSelectType={(value) => handleSelectionChange(value)}
            />
            <IconButton
              onClick={handleUpdateStatisticInfo}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-70q0-17 11.5-28.5T760-800q17 0 28.5 11.5T800-760v200q0 17-11.5 28.5T760-520H560q-17 0-28.5-11.5T520-560q0-17 11.5-28.5T560-600h128q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q68 0 124.5-34.5T692-367q8-14 22.5-19.5t29.5-.5q16 5 23 21t-1 30q-41 80-117 128t-169 48Z" />
                </svg>
              }
            />
          </div>

          <StatisticGraphic
            type={isTypeGraphic}
            typeOperation={"realization"}
            period={timePeriod}
            title={"Реализация"}
            isUpdate={isUpdate}
            setUpdate={setUpdate}
          />

          <StatisticGraphic
            type={isTypeGraphic}
            typeOperation={"salesVolume"}
            period={timePeriod}
            title={"Объём продаж"}
            isUpdate={isUpdate}
            setUpdate={setUpdate}
          />

          <StatisticGraphic
            type={isTypeGraphic}
            typeOperation={"numberOfStudents"}
            period={timePeriod}
            title={"Кол-во учеников"}
            isUpdate={isUpdate}
            setUpdate={setUpdate}
          />

          <StatisticGraphic
            type={isTypeGraphic}
            typeOperation={"indexOfGroups"}
            period={timePeriod}
            title={"Индекс групп"}
            subtitle={"Среднее значение"}
            isUpdate={isUpdate}
            setUpdate={setUpdate}
          />
        </div>
      </div>
    </div>
  );
};

export default Statistic;
