import { useState } from "react";
import "./studentInfoComponents.sass";
import SearchInput from "../searchInput/SearchInput";
import FutureLessonsList from "../futureLessonsList/FutureLessonsList";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const StudentFutureLessons = ({ data }) => {
  const [isSearchTerm, setSearchTerm] = useState("");
  const [isLoading, setLoading] = useState(false);
  return (
    <div className="student-future-lessons main-container">
      {isLoading && (
        <div className="loading-container-lessons">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}
      <div className="student-lessons-toolbar">
        <div className="student-lessons-toolbar__toolbar-top">
          <h4 className="student-lessons-toolbar__toolbar-title">Ближайшие</h4>
          <SearchInput
            className={"student-lessons-toolbar__search"}
            holderText={"Поиск записи..."}
            onChange={(e) => setSearchTerm(e.target.value)}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M380-320q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l224 224q11 11 11 28t-11 28q-11 11-28 11t-28-11L532-372q-30 24-69 38t-83 14Zm0-80q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
              </svg>
            }
          />
        </div>
        <div className="student-lessons-toolbar__toolbar-bottom">
          <h4 className="student-lessons-toolbar__text-start">Дата</h4>
          <h4 className="student-lessons-toolbar__text-center">Вид занятия</h4>
        </div>
      </div>
      <div className="student-future-lessons__content">
        <FutureLessonsList studentData={data} searchTerm={isSearchTerm} setLoading={setLoading}/>
      </div>
    </div>
  );
};

export default StudentFutureLessons;
