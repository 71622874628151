import { useEffect, useState } from "react";
import "./futureLessonsList.sass";
import { databases } from "../../lib/appwrite";
import { Query } from "appwrite";

const selectTypeLesson = (type) => {
  switch (type) {
    case "indiv":
      return "Индивидуальный урок";
    case "group":
      return "Групповой урок";
    case "acter":
      return "Актерское мастерство";
    case "trial":
      return "Пробный урок";
    default:
      return "Неизвестно";
  }
};

const FutureLessonsList = ({ studentData, searchTerm, setLoading }) => {
  const [isInfoLessons, setInfoLessons] = useState([]);

  async function fetchFutureLessons(userId) {
    const currentDate = new Date().toISOString();

    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "66143d053c25fc1c3d4d",
        [
          Query.greaterThan("date_start", currentDate),
          Query.select(["date_start", "type_lesson", "students"]),
          Query.limit(25),
          Query.offset(0),
        ]
      );

      // Фильтрация документов, чтобы вернуть только те, которые содержат userId в массиве students
      const filteredDocuments = response.documents.filter((lesson) =>
        lesson.students.includes(userId)
      );

      // Возвращаем только документы, без метаданных
      return filteredDocuments;
    } catch (error) {
      console.error("Error fetching future lessons:", error);
      throw error;
    }
  }

  useEffect(() => {
    if (studentData?.$id) {
      setLoading(true);
      fetchFutureLessons(studentData.$id)
        .then((lessons) => {
          setInfoLessons(lessons);
          setLoading(false);
        })
        .catch((error) => console.error(error));
    }
  }, [studentData]);

  // Поиск по ученикам
  const filteredLessons = isInfoLessons.filter(
    (lesson) =>
      Intl.DateTimeFormat("ru-RU", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
        .format(new Date(lesson.date_start))
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      selectTypeLesson(lesson.type_lesson)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  const renderItemLesson = (lessons) => {
    return lessons.map((lesson, index) => (
      <div className="future-lessons-list__item" key={index}>
        <h4 className="future-lessons-list__text-start">
          {Intl.DateTimeFormat("ru-RU", {
            day: "numeric",
            month: "long",
            year: "numeric",
          }).format(new Date(lesson.date_start))}
        </h4>
        <h4 className="future-lessons-list__text-center">
          {selectTypeLesson(lesson.type_lesson)}
        </h4>
      </div>
    ));
  };

  return (
    <div className="future-lessons-list">
      {renderItemLesson(filteredLessons.slice().reverse())}
    </div>
  );
};

export default FutureLessonsList;
