import { CircularProgress, Stack } from "@mui/material";
import Button from "../button/Button";
import IconButton from "../iconButton/IconButton";
import "./teacherProfile.sass";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.length !== 12) {
    console.error("Неверная длина номера телефона:", phoneNumber);
    return phoneNumber; // Возвращаем оригинальный номер, если длина неверна
  }

  const parts = phoneNumber.split("");
  return `${parts.slice(0, 2).join("")} (${parts.slice(2, 5).join("")}) ${parts
    .slice(5, 8)
    .join("")}-${parts.slice(8, 10).join("")}-${parts.slice(10).join("")}`;
};

const TeacherProfile = ({ userData, modalOpen, loading }) => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const handleLogout = async () => {
    try {
      logout();
      navigate("/login");
    } catch (error) {
      console.error(`Ошибка при выходе из аккаунта: ${error}`);
    }
  };

  return (
    <div className="teacher-profile-container">
      <div className="teacher-profile-container__close-shadow" onClick={() => modalOpen(false)}></div>

      <div className="teacher-profile">
        <IconButton
          className={"teacher-profile__close-button"}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
            >
              <path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" />
            </svg>
          }
          onClick={() => modalOpen(false)}
        />

        {loading && (
          <div className="teacher-profile__loading">
            <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
              <CircularProgress color="inherit" />
            </Stack>
          </div>
        )}
        {!loading && (
          <>
            <div className="teacher-profile__main-info">
              {userData && userData.avatar_image ? (
                <img
                  className="teacher-profile__avatar"
                  src={userData.avatar_image}
                  alt="Avatar"
                />
              ) : (
                <div className="teacher-profile__avatar"></div>
              )}

              <h4 className="teacher-profile__username">
                {userData ? `${userData.name} ${userData.sur_name}` : "..."}
              </h4>
              <h4 className="teacher-profile__role">Преподаватель</h4>
            </div>
            <div className="teacher-profile__full-info">
              <div className="teacher-profile__info">
                <h4 className="teacher-profile__title">Телефон</h4>
                <h4 className="teacher-profile__content">
                  {userData ? formatPhoneNumber(userData.phone_number) : "--"}
                </h4>
              </div>
              <div className="teacher-profile__info">
                <h4 className="teacher-profile__title">Дата рождения</h4>
                <h4 className="teacher-profile__content">
                  {userData
                    ? new Date(userData.date_of_birth).toLocaleDateString(
                        "ru-RU",
                        {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                        }
                      )
                    : "--"}
                </h4>
              </div>
              <div className="teacher-profile__info">
                <h4 className="teacher-profile__title">Дата прихода</h4>
                <h4 className="teacher-profile__content">
                  {userData
                    ? new Date(userData.date_of_arrival).toLocaleDateString(
                        "ru-RU",
                        {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                        }
                      )
                    : "--"}
                </h4>
              </div>
            </div>
            <Button colorStyle={"red-inverse"} onClick={handleLogout}>
              Выйти
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default TeacherProfile;
