import "./creatorLesson.sass";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStudents } from "../../context/StudentsContext";
import Button from "../../components/button/Button";
import InfoOfLesson from "../../components/infoOfLesson/InfoOfLesson";
import SelectedStudentsList from "../../components/selectedStudentsList/SelectedStudentsList";
import Sidebar from "../../components/sidebar/Sidebar";
import AddNewStudent from "../../components/addNewStudent/AddNewStudent";

const CreatorLesson = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { clearStudents, findAndAddStudents, isLoadingStudentsData } =
    useStudents();

  const [studentsUid, setStudentsUid] = useState([]);
  const [maxStudents, setMaxStudents] = useState(5);
  const [isLoadingCreator, setLoadingCreator] = useState(false);
  const [isOpenAddStudent, setOpenAddStudent] = useState(false);
  const [isUpdateStudentsList, setUpdateStudentsList] = useState(false);
  const [isTypeLesson, setTypeLesson] = useState("")

  useEffect(() => {
    return () => clearStudents();
  }, []);

  useEffect(() => {
    document.title = "Информация об уроке";
  }, []);

  useEffect(() => {
    setLoadingCreator(isLoadingStudentsData);
  }, [isLoadingStudentsData]);

  const handleBackNavigate = () => {
    navigate("/admin/schedule");
  };

  useEffect(() => {
    const { state } = location;
    if (state && state.lessonData) {
      const { lessonData } = state;
      const studentIds =
        lessonData.students && lessonData.students.length > 0
          ? lessonData.students
          : [];
      setStudentsUid(studentIds);
    }
  }, [location.state]);

  useEffect(() => {
    if (studentsUid.length > 0) {
      findAndAddStudents(studentsUid);
    }
  }, [studentsUid]);

  useEffect(() => {
    const toggleBodyOverflow = (value) => {
      document.body.style.overflow = value;
    };

    if (isOpenAddStudent) {
      toggleBodyOverflow("hidden");
    } else {
      toggleBodyOverflow("visible");
    }

    return () => toggleBodyOverflow("visible");
  }, [isOpenAddStudent]);

  return (
    <div className="creator-lesson">
      <Sidebar />
      <div className="main-container-pages">
        <div className="main-container-pages__container">
          <div className="main-container-pages__texts">
            <div className="main-container-pages__toolbar-texts">
              <Button
                className={"main-container-pages__button-back"}
                colorStyle={"blue-inverse icons"}
                onClick={handleBackNavigate}
              >
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z" />
                </svg>
              </Button>
              <h2 className="main-container-pages__title">
                {location.state ? "Редактирование урока" : "Новый урок"}
              </h2>
            </div>
          </div>
          <div className="creator-lesson-components">
            <InfoOfLesson
              setMaxStudents={setMaxStudents}
              maxStudents={maxStudents}
              lessonData={location.state ? location.state.lessonData : []}
              isLoadingCreator={isLoadingCreator}
              setLoadingCreator={setLoadingCreator}
              lessonType={setTypeLesson}
            />
            <SelectedStudentsList
              maxStudents={maxStudents}
              isLoadingCreator={isLoadingCreator}
              setOpenAddStudent={setOpenAddStudent}
              isUpdateStudentsList={isUpdateStudentsList}
              setUpdateStudentsList={setUpdateStudentsList}
              lessonType={isTypeLesson}
            />
          </div>
        </div>
      </div>
      {isOpenAddStudent && (
        <div className="modal-dialog-student-add">
          <AddNewStudent
            onModalOpen={setOpenAddStudent}
            setUpdateStudentsList={setUpdateStudentsList}
          />
        </div>
      )}
    </div>
  );
};

export default CreatorLesson;
