import "./recordTask.sass";
import { useState } from "react";
import IconButton from "../iconButton/IconButton";
import PopupMenu from "../popupMenu/PopupMenu";

const RecordTaskStudentCardMini = ({ type, client, date, overdue, onClick, itemClick }) => {
  // Состояния
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Открытие меню
  const handlePopupMenuOpen = () => {
    setIsMenuOpen(true);
  };

  // Закрытие меню
  const handlePopupMenuClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="task-row task-student-mini">
      <div className="task-row__info" onClick={onClick}>
        <div className="task-row__top">
          <h5 className="task-title">{type}</h5>
          {overdue && (
            <h5 className="task-subtitle red-text-overdue">Просроченно</h5>
          )}
        </div>
        <div className="task-row__bottom">
          <h5 className={`task-subtitle ${overdue && "text-overdue"} task-student-mini-overdue`}>
            {date}
          </h5>
          
        </div>
      </div>
      <div className="task-cell">
        <IconButton
          className={"task-row__icon-button"}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 -960 960 960"
              width="20"
            >
              <path d="M263.788-408Q234-408 213-429.212q-21-21.213-21-51Q192-510 213.212-531q21.213-21 51-21Q294-552 315-530.788q21 21.213 21 51Q336-450 314.788-429q-21.213 21-51 21Zm216 0Q450-408 429-429.212q-21-21.213-21-51Q408-510 429.212-531q21.213-21 51-21Q510-552 531-530.788q21 21.213 21 51Q552-450 530.788-429q-21.213 21-51 21Zm216 0Q666-408 645-429.212q-21-21.213-21-51Q624-510 645.212-531q21.213-21 51-21Q726-552 747-530.788q21 21.213 21 51Q768-450 746.788-429q-21.213 21-51 21Z" />
            </svg>
          }
          onClick={handlePopupMenuOpen}
        />
        <PopupMenu
          onMouseLeave={handlePopupMenuClose}
          inOpen={isMenuOpen}
          menuItems={[
            { label: "Выполнено", key: "done" },
            { label: "Редактировать", key: "edit" },
            { label: "Удалить", key: "delete" },
          ]}
          onMenuItemClick={(item) => {
            if (itemClick) {
              itemClick(item);
            }
            handlePopupMenuClose();
          }}
          styleMenu={"menu-task"}
        />
      </div>
    </div>
  );
};

export default RecordTaskStudentCardMini;
