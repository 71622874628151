import { useEffect, useState } from "react";
import "./historyLessonsList.sass";
import { databases } from "../../lib/appwrite";
import { Query } from "appwrite";

const PAGE_SIZE = 25;

const selectTypeLesson = (type) => {
  switch (type) {
    case "indiv":
      return "Индивидуальный урок";
    case "group":
      return "Групповой урок";
    case "acter":
      return "Актерское мастерство";
    case "trial":
      return "Пробный урок";
    default:
      return "Неизвестно";
  }
};

const selectTypeAttendance = (type) => {
  switch (type) {
    case "present":
      return "Присутствовал";
    case "absent":
      return "Не присутствовал";
    case "respectful":
      return "Не присутствовал (уваж.)";
    default:
      return "Неизвестно";
  }
};

const HistoryLessonsList = ({ studentData, searchTerm, setLoading }) => {
  const [isInfoLessons, setInfoLessons] = useState([]);

  async function fetchHistoryLessons(userId, limit = 25, offset = 0) {
    const currentDate = new Date().toISOString();
    let lessonsHistory = [];
    let totalFetched = 0;

    try {
      while (true) {
        const response = await databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "66143d053c25fc1c3d4d",
          [
            Query.lessThanEqual("date_start", currentDate),
            Query.select(["date_start", "type_lesson", "students", "$id"]),
            Query.limit(limit),
            Query.offset(offset),
          ]
        );

        // Фильтрация документов, чтобы вернуть только те, которые содержат userId в массиве students
        const filteredDocuments = response.documents.filter((lesson) =>
          lesson.students.includes(userId)
        );

        lessonsHistory = lessonsHistory.concat(filteredDocuments);
        totalFetched += filteredDocuments.length;

        if (response.documents.length < limit || totalFetched >= limit) {
          break; // Если меньше чем limit документов, значит это последняя страница или достигнут лимит
        }

        offset += limit;
      }

      return lessonsHistory;
    } catch (error) {
      console.error("Error fetching history lessons:", error);
      throw error;
    }
  }

  async function fetchAttendance(id_lesson, id_student) {
    let attendanceData = [];
    let offset = 0;

    try {
      while (true) {
        const response = await databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "661cdbdec056444bc33f",
          [
            Query.equal("id_lesson", id_lesson),
            Query.equal("id_student", id_student),
            Query.select(["attendance"]),
          ],
          PAGE_SIZE,
          offset
        );

        attendanceData = attendanceData.concat(response.documents);

        if (response.documents.length < PAGE_SIZE) {
          break; // Если меньше чем PAGE_SIZE документов, значит это последняя страница
        }

        offset += PAGE_SIZE;
      }

      return attendanceData[0]?.attendance || null;
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      return null;
    }
  }

  // Поиск по ученикам
  const filteredLessons = isInfoLessons.filter(
    (lesson) =>
      Intl.DateTimeFormat("ru-RU", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
        .format(new Date(lesson.date_start))
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      selectTypeLesson(lesson.type_lesson)
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      selectTypeAttendance(lesson.attendanceInfo)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (studentData?.$id) {
      setLoading(true);
      fetchHistoryLessons(studentData.$id)
        .then(async (lessons) => {
          const lessonsWithAttendance = await Promise.all(
            lessons.map(async (lesson) => {
              const attendanceInfo = await fetchAttendance(
                lesson.$id,
                studentData.$id
              );

              return { ...lesson, attendanceInfo };
            })
          );
          setInfoLessons(lessonsWithAttendance);
          setLoading(false);
        })
        .catch((error) => console.error(error));
    }
  }, [studentData]);

  const renderItemLesson = (lessons) => {
    return lessons.map((lesson, index) => (
      <div className="history-lessons-list__item" key={index}>
        <h4 className="history-lessons-list__text-start">
          {Intl.DateTimeFormat("ru-RU", {
            day: "numeric",
            month: "long",
            year: "numeric",
          }).format(new Date(lesson.date_start))}
        </h4>
        <h4 className="history-lessons-list__text-center">
          {selectTypeLesson(lesson.type_lesson)}
        </h4>
        <h4 className="history-lessons-list__text-end">
          {selectTypeAttendance(lesson.attendanceInfo) ||
            "Информация отсутствует"}
        </h4>
      </div>
    ));
  };

  return (
    <div className="history-lessons-list">
      {renderItemLesson(filteredLessons.slice().reverse())}
    </div>
  );
};

export default HistoryLessonsList;
