import "./addNewStudent.sass";
import React, { useEffect, useState } from "react";
import { account, databases, storage } from "../../lib/appwrite";
import { ID, Query } from "appwrite";
import Button from "../../components/button/Button";
import ImageUploader from "../../components/imageUploader/ImageUploader";
import Input from "../../components/input/Input";
import OptionMenu from "../../components/optionMenu/OptionMenu";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import recordingAnOperation from "../../hooks/recordingAnOperation";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import InputMultiline from "../input/InputMultiline";

const incrementNumberOfUsers = async (documentId) => {
  try {
    // Получение текущего значения поля number_of_users
    const document = await databases.getDocument(
      "65f9d6f4dbeeccbe7e74",
      "6648cd930032312f629d",
      documentId
    );
    const currentNumberOfUsers = document.number_of_users;

    // Инкрементирование значения поля number_of_users
    const newNumberOfUsers = currentNumberOfUsers + 1;

    // Обновление документа с новым значением
    await databases.updateDocument(
      "65f9d6f4dbeeccbe7e74",
      "6648cd930032312f629d",
      documentId,
      {
        number_of_users: newNumberOfUsers,
      }
    );
  } catch (error) {
    console.error(
      "Произошла ошибка при обновлении числа пользователей:",
      error
    );
  }
};

const AddNewStudent = ({ onModalOpen, setUpdateStudentsList }) => {
  const { updateOperationInfo } = recordingAnOperation();

  // Состояния
  const [isLoader, setIsLoader] = useState(false);
  const [isContainerImage, setContainerImage] = useState(true);
  // Значения полей ввода
  const [isName, setIsName] = useState("");
  const [isSurName, setIsSurName] = useState("");
  const [isPhoneNumber, setIsPhoneNumber] = useState("");
  const [isDateBirth, setIsDateBirth] = useState("");
  const [selectChannel, setSelectChannel] = useState("");
  const [isStartBalance, setIsStartBalance] = useState("");
  const [isStartLessons, setIsStartLessons] = useState("");
  const [isComment, setComment] = useState("");
  const [fileImage, setFileImage] = useState("");
  // Значения ошибок
  const [error, setError] = useState(false);
  const [errorNullName, setErrorNullName] = useState(false);
  const [errorNullSurName, setErrorNullSurName] = useState(false);
  const [errorNullPhone, setErrorNullPhone] = useState(false);
  const [errorAppWrite, setErrorAppWrite] = useState(false);
  const [errorNullBalance, setErrorNullBalance] = useState(false);
  const [errorNullDateOfBirth, setErrorNullDateOfBirth] = useState(false);
  const [isAlertError, setAlertError] = useState(false);
  const [isErrorMessageAlert, setErrorMessageAlert] = useState(["", ""]);
  const errors = {
    isNameEmpty: isName.length === 0,
    isSurNameEmpty: isSurName.length === 0,
    isPhoneNumberEmpty: isPhoneNumber.length === 0,
    isInvalidBalance: isStartBalance !== "" && isStartLessons === "",
    isDateOfBirthEmpty: isDateBirth.length === 0,
  };
  const [marketingChannels, setMarketingChannels] = useState([
    { value: "", label: "Выберите..." },
  ]);

  // Загрузка аватара пользователя
  async function uploadUserAvatar(userId, fileImage) {
    // Проверка наличия изображения
    if (!fileImage) {
      console.error("Отсутствует файл изображения.");
      return;
    }

    try {
      const bucketId = "65fb23f53117da6475ba";
      const newAvatarId = new Date().getTime().toString();

      // Создание файла в хранилище
      await storage.createFile(bucketId, newAvatarId, fileImage);

      // Получение URL для загруженного файла
      const downloadURL = storage.getFilePreview(bucketId, newAvatarId);

      // Обновление документа пользователя с новым URL аватара
      await databases.updateDocument(
        "65f9d6f4dbeeccbe7e74",
        "65f9d6fd218cc728529a",
        userId,
        { avatar_image: downloadURL, avatar_id: newAvatarId }
      );
    } catch (error) {
      console.error("Ошибка при загрузке аватара пользователя:", error);
      setErrorMessageAlert(["Ошибка при загрузке аватара", error.message]);
      setAlertError(true);
      throw error;
    }
  }

  const gettingData = async () => {
    try {
      const dataChannels = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "6648cd930032312f629d",
        [Query.equal("city", "voronezh")]
      );
      const formattedChannels = dataChannels.documents.map((channel) => ({
        value: channel.$id,
        label: channel.marketing_channel,
      }));

      setMarketingChannels((prevChannels) => {
        // Создаем Set из текущих значений чтобы избежать дубликатов
        const existingValues = new Set(
          prevChannels.map((channel) => channel.value)
        );
        // Добавляем только уникальные значения
        const uniqueChannels = formattedChannels.filter(
          (channel) => !existingValues.has(channel.value)
        );
        return [...prevChannels, ...uniqueChannels];
      });
    } catch (err) {
      console.error(
        "Произошла ошибка при получении данных о каналах маркетинга:",
        err
      );
      setErrorMessageAlert([
        "Ошибка получения каналов маркетинга",
        err.message,
      ]);
      setAlertError(true);
    }
  };

  useEffect(() => {
    gettingData();
  }, []);

  useEffect(() => {
    //console.log("Изображение загружено", isContainerImage)
  }, [isContainerImage]);

  // Сброс ошибок и закрытие окна
  const handleModalClose = () => {
    onModalOpen(false);
    setIsName("");
    setIsSurName("");
    setIsPhoneNumber("");
    setIsDateBirth("");
    setIsStartBalance("");
    setIsStartLessons("");
    setFileImage("");
    setErrorNullName(false);
    setErrorNullSurName(false);
    setErrorNullPhone(false);
    setErrorAppWrite(false);
    setErrorNullBalance(false);
    setErrorNullDateOfBirth(false);
    setError(false);
  };

  // Добавление нового ученика
  const handleSaveDataNewStudent = async (e) => {
    e.preventDefault();
    setErrorNullName(false);
    setErrorNullSurName(false);
    setErrorNullPhone(false);
    setErrorNullBalance(false);
    setErrorNullDateOfBirth(false);
    setError(false);
    setErrorAppWrite(false);

    const hasError = Object.values(errors).some(Boolean);

    if (hasError) {
      setError(true);

      // Установка конкретных ошибок
      setErrorNullName(errors.isNameEmpty);
      setErrorNullSurName(errors.isSurNameEmpty);
      setErrorNullPhone(errors.isPhoneNumberEmpty);
      setErrorNullBalance(errors.isInvalidBalance);
      setErrorNullDateOfBirth(errors.isDateOfBirthEmpty);
    } else {
      setIsLoader(true);
      try {
        // Создание нового пользователя в AppWrite
        const user = await account.create(
          ID.unique(),
          `${isPhoneNumber}@phone.gl`,
          `${isPhoneNumber}@password.${isPhoneNumber.slice(-2)}`
        );

        // Заполнение данными
        await databases.createDocument(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          user.$id,
          {
            user_id: user.$id,
            name: `${isName.charAt(0).toUpperCase()}${isName.slice(1)}`,
            sur_name: `${isSurName.charAt(0).toUpperCase()}${isSurName.slice(
              1
            )}`,
            phone_number: isPhoneNumber,
            role: "student",
            city: "voronezh",
            date_of_arrival: new Date().toISOString(),
            date_of_birth: new Date(isDateBirth).toISOString(),
            points: 0,
            trial_lesson_available: true,
            comment: "",
          }
        );

        // Загрузка изображения
        if (fileImage) {
          await uploadUserAvatar(user.$id, fileImage);
        }

        if (selectChannel) {
          incrementNumberOfUsers(selectChannel);
        }

        // Закрытие окна
        handleModalClose();
        setUpdateStudentsList && setUpdateStudentsList(true);
        setIsLoader(false);

        // Заполнение дополнительными данными
        if (isStartBalance !== "" || isStartLessons !== "") {
          createSubscription(user.$id, isStartLessons, isStartBalance);
        }
      } catch (err) {
        setErrorAppWrite(true);
        console.error(err);
        setErrorMessageAlert(["Ошибка добавления нового ученика", err.message]);
        setAlertError(true);
      } finally {
        setIsLoader(false);
      }
    }
  };

  // Создание первого абонемента
  async function createSubscription(userUid, startLessons, startBalance) {
    const currentDate = new Date();
    const endDate = new Date(currentDate);
    endDate.setMonth(currentDate.getMonth() + 6);

    // Преобразование входных данных в числа
    const lessons = parseInt(startLessons, 10) || 0;
    const balance = parseFloat(startBalance) || 0;

    try {
      await databases.createDocument(
        "65f9d6f4dbeeccbe7e74",
        "65fef6b362018b62c8c6",
        ID.unique(),
        {
          user_id: userUid,
          start_date: currentDate.toISOString(),
          end_date: endDate.toISOString(),
          number_of_lessons: lessons,
          price: balance,
          remains_balance: balance,
          remains_lessons: lessons,
          introduced: balance,
          type_payment: "full-amount",
        }
      );
      updateOperationInfo("salesVolume", Math.floor(Number(balance)));
    } catch (error) {
      console.error("Ошибка при создании абонемента:", error);
      setErrorMessageAlert(["Ошибка создания абонемента", error.message]);
      setAlertError(true);
      throw error; // Переброс ошибки для дальнейшей обработки
    }
  }

  // Поля ввода
  const handleAddStudent = (e) => {
    e.preventDefault();
  };

  const handleInputName = (e) => {
    setIsName(e.target.value.trim());
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("surNameInput").focus();
    }
  };

  const handleInputSurName = (e) => {
    setIsSurName(e.target.value.trim());
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("numberPhoneInput").focus();
    }
  };

  const handleInputPhone = (e) => {
    const value = e.target.value.trim();
    if (value.length <= 12) {
      if (value.length > 0 && !value.startsWith("+")) {
        setIsPhoneNumber(`+${value}`);
      } else {
        setIsPhoneNumber(value);
      }
    }
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("dateBirthSelect").focus();
    }
  };

  const handleInputDateBirth = (e) => {
    setIsDateBirth(e.target.value);
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("startBalanceInput").focus();
    }
  };

  const handleInputStartBalance = (e) => {
    setIsStartBalance(e.target.value.trim());
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("dateBirthInput").focus();
    }
  };

  const handleInputStartLessons = (e) => {
    setIsStartLessons(e.target.value.trim());
  };

  return (
    <div className="modal-student">
      <div className="modal-student__content-container">
        <h2>Новый ученик</h2>

        <div className="modal-student__new-data-student">
          <ImageUploader
            imageFile={setFileImage}
            containerSetImage={setContainerImage}
          />

          <form
            onSubmit={handleAddStudent}
            className="form-student"
            autoComplete="off"
          >
            <Input
              title={"Имя"}
              className={`form-student__input-name capitalize ${
                errorNullName ? "error" : ""
              }`}
              id={"nameInput"}
              classContainer={"input-add-student"}
              type={"text"}
              value={isName}
              onChange={handleInputName}
              placeholder={"Имя"}
              onKeyDown={handleInputName}
            />
            <Input
              title={"Фамилия"}
              id={"surNameInput"}
              className={`form-student__input-sur-name capitalize ${
                errorNullSurName ? "error" : ""
              }`}
              classContainer={"input-add-student"}
              type={"text"}
              value={isSurName}
              onChange={handleInputSurName}
              placeholder={"Фамилия"}
              onKeyDown={handleInputSurName}
            />
            <Input
              title={"Номер телефона"}
              id={"numberPhoneInput"}
              className={`form-student__input-phone  ${
                errorNullPhone ? "error" : ""
              }`}
              classContainer={"input-add-student"}
              type={"text"}
              value={isPhoneNumber}
              onChange={handleInputPhone}
              placeholder={"Номер телефона (+7)"}
              onKeyDown={handleInputPhone}
            />
            <Input
              title={"Дата рождения"}
              id={"dateBirthSelect"}
              className={`form-student__input-date-birth  ${
                errorNullDateOfBirth ? "error" : ""
              }`}
              classContainer={"input-add-student"}
              type={"date"}
              value={isDateBirth}
              onChange={handleInputDateBirth}
              placeholder={"Дата рождения"}
              onKeyDown={handleInputDateBirth}
            />

            <Input
              title={"Начальный баланс"}
              id={"startBalanceInput"}
              subTitle={"Необязательно"}
              className={"form-student__input-start-balance"}
              classContainer={"input-add-student"}
              type={"number"}
              value={isStartBalance}
              onChange={handleInputStartBalance}
              placeholder={"Сумма (₽)"}
              onKeyDown={handleInputStartBalance}
            />
            <Input
              title={"Заглушка"}
              id={"dateBirthInputNewStudent"}
              placeHolder={"text-invisible"}
              className={`form-student__input-start-lessons  ${
                errorNullBalance ? "error" : ""
              }`}
              classContainer={"input-add-student"}
              type={"text"}
              value={isStartLessons}
              onChange={handleInputStartLessons}
              placeholder={"Уроки"}
            />

            <OptionMenu
              id={"marketingChannelsOptionNewStudent"}
              options={marketingChannels}
              title={"Канал маркетинга"}
              onChange={(e) => setSelectChannel(e.target.value)}
              className={
                "form-student__option-post-container input-add-student"
              }
            />

            <InputMultiline
              title={"Комментарий"}
              id={"commentAddNewStudent"}
              className={`form-student__input-comment`}
              classContainer={"input-add-student-comment"}
              type={"text"}
              value={isComment}
              onChange={(e) => setComment(e.target.value)}
              placeholder={"Комментарий"}
            />
          </form>
        </div>

        <div className="modal-student__buttons">
          {error && <h4>Пожалуйста, заполните все обязательные поля!</h4>}

          {errorAppWrite && <h4>Произошла ошибка, попробуйте снова</h4>}

          <Button
            className={"modal-student__close-button"}
            colorStyle={"invisible"}
            onClick={handleModalClose}
          >
            Отмена
          </Button>
          <Button
            className={"modal-student__save-button"}
            colorStyle={"blue"}
            disabled={!isName || !isSurName || !isPhoneNumber || !isDateBirth}
            onClick={handleSaveDataNewStudent}
          >
            Сохранить
          </Button>
        </div>
        {isLoader && (
          <div className="modal-student__container-loader-upload">
            <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
              <CircularProgress color="inherit" />
            </Stack>
          </div>
        )}
      </div>
      <div
        className="modal-student__element-close-modal"
        onClick={handleModalClose}
      ></div>
      <Snackbar
        open={isAlertError}
        autoHideDuration={6000}
        onClose={() => setAlertError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAlertError(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <AlertTitle>{isErrorMessageAlert[0]}</AlertTitle>
          {isErrorMessageAlert[1]}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddNewStudent;
