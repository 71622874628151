import React, { useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import "./errorMessage.sass";

function selectIconError(type) {
  if (type === "high") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        viewBox="0 -960 960 960"
        width="20"
        fill="#ff0000"
      >
        <path d="M479.789-288Q495-288 505.5-298.289q10.5-10.29 10.5-25.5Q516-339 505.711-349.5q-10.29-10.5-25.5-10.5Q465-360 454.5-349.711q-10.5 10.29-10.5 25.5Q444-309 454.289-298.5q10.29 10.5 25.5 10.5Zm0-144Q495-432 505.5-442.35 516-452.7 516-468v-168q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465-672 454.5-661.65 444-651.3 444-636v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35Zm.487 336Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30Z" />
      </svg>
    );
  } else if (type === "medium") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        viewBox="0 -960 960 960"
        width="20"
        fill="#ffbe00"
      >
        <path d="M111.914-144q-10.914 0-19.131-4.95Q84.565-153.9 80-162q-5-8-4.5-18t5.5-19l368-614q5-9 13.526-13 8.525-4 17.5-4 8.974 0 17.474 4t13.5 13l368 614q5 9 5.5 19t-4.5 18q-5 8-13 13t-18.914 5H111.914Zm367.875-120Q495-264 505.5-274.289q10.5-10.29 10.5-25.5Q516-315 505.711-325.5q-10.29-10.5-25.5-10.5Q465-336 454.5-325.711q-10.5 10.29-10.5 25.5Q444-285 454.289-274.5q10.29 10.5 25.5 10.5Zm0-120Q495-384 505.5-394.35 516-404.7 516-420v-120q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465-576 454.5-565.65 444-555.3 444-540v120q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35Z" />
      </svg>
    );
  }
}
;

const ErrorMessageTwo = ({ textMessage, typeError, className }) => {
  const iconRef = useRef(null);
  const messageRef = useRef(null); // Создаем ref для сообщения
  const [messageVisible, setMessageVisible] = useState(false);

  return (
    <>
      <div ref={iconRef} className={`error-container-two ${className}`}>
        <i
          className="error-container-two__error-icon"
          onMouseEnter={() => setMessageVisible(true)}
          onMouseLeave={() => setMessageVisible(false)}
        >
          {selectIconError(typeError)}
        </i>
      </div>
      <CSSTransition
        in={messageVisible}
        timeout={150}
        classNames="error-message-animation"
        unmountOnExit
        nodeRef={messageRef} // Передаем ref в CSSTransition
      >
        <div
          ref={messageRef} // Привязываем ref к DOM элементу
          className={`error-message ${
            typeError === "high"
              ? "error-message__high"
              : "error-message__medium"
          }`}
        >
          {textMessage}
        </div>
      </CSSTransition>
    </>
  );
};

export default ErrorMessageTwo;
