import { useState } from "react";
import Button from "../button/Button";
import IconButton from "../iconButton/IconButton";
import "./viewingTask.sass";
import PopupMenu from "../popupMenu/PopupMenu";
import { databases } from "../../lib/appwrite";

const ViewingTask = ({
  isOpenViewing,
  userDataView,
  openingCreatorTask,
  selectedInfo,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const options = { day: "numeric", month: "long", year: "numeric" };

  // Открытие меню
  const handlePopupMenuOpen = () => {
    setIsMenuOpen(true);
  };

  // Закрытие меню
  const handlePopupMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuItemClick = async (item) => {
    setIsMenuOpen(false);
    switch (item.key) {
      case "edit":
        isOpenViewing(false);
        openingCreatorTask(true);
        selectedInfo(userDataView);
        break;
      case "delete":
        await databases.deleteDocument(
          "65f9d6f4dbeeccbe7e74",
          "66041861c5966a6ddda5",
          userDataView.$id
        );
        isOpenViewing(false);
        break;
    }
  };

  const handleTaskDone = async () => {
    await databases.deleteDocument(
      "65f9d6f4dbeeccbe7e74",
      "66041861c5966a6ddda5",
      userDataView.$id
    );
    isOpenViewing(false);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const parts = phoneNumber.split("");
    return `${parts.slice(0, 2).join("")} (${parts
      .slice(2, 5)
      .join("")}) ${parts.slice(5, 8).join("")}-${parts
      .slice(8, 10)
      .join("")}-${parts.slice(10, 12).join("")}`;
  };

  return (
    <div className="blackout">
      <div className="viewing-task">
        <IconButton
          className={"viewing-task__button-more"}
          onClick={handlePopupMenuOpen}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="M240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400Z" />
            </svg>
          }
        />
        <PopupMenu
          onMouseLeave={handlePopupMenuClose}
          inOpen={isMenuOpen}
          menuItems={[
            { label: "Редактировать", key: "edit" },
            { label: "Удалить", key: "delete" },
          ]}
          onMenuItemClick={handleMenuItemClick}
          styleMenu={"menu-task-viewing"}
        />

        <h4 className="viewing-task__type">{userDataView.type}</h4>
        <h3 className="viewing-task__client">{userDataView.client}</h3>
        <span className="viewing-task__phone-number">
          {formatPhoneNumber(userDataView.phone_number)}
        </span>
        <h4 className="viewing-task__comment">Комментарий</h4>
        <span className="viewing-task__comment-info">
          {userDataView.comment ? userDataView.comment : "Без комментария"}
        </span>
        <h4 className="viewing-task__date-of-completion">Дата выполнения</h4>
        <span className="viewing-task__date-text">
          {Intl.DateTimeFormat("ru-RU", options).format(
            new Date(userDataView.date_of_completion)
          )}
        </span>
        <div className="viewing-task__buttons">
          <Button
            className={"viewing-task__button-close"}
            colorStyle={"invisible"}
            onClick={() => isOpenViewing(false)}
          >
            Закрыть
          </Button>
          <Button
            className={"viewing-task__button-complete"}
            colorStyle={"blue-inverse"}
            onClick={handleTaskDone}
          >
            Выполнено
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewingTask;
