import React, { useCallback, useEffect, useState } from "react";
import { databases, client } from "../../lib/appwrite";
import { Query } from "appwrite";
import RecordTaskStudentCardMini from "../recordTask/RecordTaskStudentCardMini";
import "./studentInfoComponents.sass";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const StudentTasks = ({
  userUID,
  viewingTaskOpen,
  creatorTaskOpen,
  setSelectedUserInfo,
  creatorTaskOpenCheck,
}) => {
  // Значения
  const [isTasks, setTasks] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // Сегодняшняя дата
  const options = { day: "numeric", month: "long", year: "numeric" };
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Функция для получения данных по ролям
  const fetchDataByRole = async (uid) => {
    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "66041861c5966a6ddda5",
        [Query.equal("uid", uid)]
      );
      return response.documents.map((doc) => ({ ...doc, uid }));
    } catch (error) {
      console.error("Ошибка при получении данных клиента:", error);
      return [];
    }
  };

  // Обновление списка
  const handleUpdate = useCallback(async () => {
    try {
      const dataTasks = await fetchDataByRole(userUID);
      setTasks(dataTasks);
    } catch (error) {
      console.error("Неожиданная ошибка при обновлении списка задач:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Обновление списка при открытии
  useEffect(() => {
    setLoading(true);
    handleUpdate();
  }, [userUID]);

  // Обновление списка в реальном времени
  useEffect(() => {
    // Подписка на изменения в коллекции
    const unsubscribe = client.subscribe(
      "databases.65f9d6f4dbeeccbe7e74.collections.66041861c5966a6ddda5.documents",
      () => {
        handleUpdate();
      }
    );

    // Отписка от событий при размонтировании компонента
    return () => unsubscribe();
  }, []);

  // Очистка пользователя
  useEffect(() => {
    if (!creatorTaskOpenCheck) {
      setSelectedUserInfo("");
    }
  }, [creatorTaskOpenCheck]);

  // Открытие окна просмотра
  const handleOpenViewingTask = (info) => {
    setSelectedUserInfo(info);
    viewingTaskOpen(true);
  };

  // Нажатие на пункт меню
  const handleClickMenu = async (key, infoUser) => {
    switch (key) {
      case "done":
        await databases.deleteDocument(
          "65f9d6f4dbeeccbe7e74",
          "66041861c5966a6ddda5",
          infoUser.$id
        );
        break;

      case "edit":
        setSelectedUserInfo(infoUser);
        creatorTaskOpen(true);
        break;

      case "delete":
        await databases.deleteDocument(
          "65f9d6f4dbeeccbe7e74",
          "66041861c5966a6ddda5",
          infoUser.$id
        );
        break;
    }
  };

  const checkDate = (task) => {
    const completionDate = new Date(task.date_of_completion);
    completionDate.setHours(0, 0, 0, 0); // Устанавливаем начало дня для даты выполнения
    return completionDate.getTime();
  };

  // Запись
  const renderTasks = (tasks) => {
    return tasks.map((task, index) => (
      <RecordTaskStudentCardMini
        onClick={() => handleOpenViewingTask(task)}
        key={index}
        type={task.type}
        client={task.client}
        date={Intl.DateTimeFormat("ru-RU", options).format(
          new Date(task.date_of_completion)
        )}
        overdue={checkDate < today.getTime() ? true : false}
        itemClick={(item) => {
          handleClickMenu(item.key, task);
        }}
      />
    ));
  };

  return (
    <div className="student-tasks main-container">
      <div className="student-tasks__toolbar">
        <h4>Задачи</h4>
      </div>
      <div className="student-tasks__container-tasks">
        {renderTasks(isTasks)}
      </div>
      {isLoading && (
        <div className="loading-container-task-student">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}
    </div>
  );
};

export default StudentTasks;
