import { useEffect, useState } from "react";
import "./studentInfoComponents.sass";
import { databases } from "../../lib/appwrite";
import { Query } from "appwrite";

const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.length !== 12) {
    console.error("Неверная длина номера телефона:", phoneNumber);
    return phoneNumber; // Возвращаем оригинальный номер, если длина неверна
  }

  const parts = phoneNumber.split("");
  return `${parts.slice(0, 2).join("")} (${parts.slice(2, 5).join("")}) ${parts
    .slice(5, 8)
    .join("")}-${parts.slice(8, 10).join("")}-${parts.slice(10).join("")}`;
};

const StudentFullInfo = ({ data }) => {
  const [isAllBalance, setAllBalance] = useState(0);
  const [isAllLessons, setAllLessons] = useState(0);

  // получение всего баланса
  async function fetchSubscriptions(userId) {
    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "65fef6b362018b62c8c6",
        [Query.equal("user_id", userId)]
      );

      let totalRemainsBalance = 0;
      let totalRemainsLessons = 0;

      response.documents.forEach((doc) => {
        totalRemainsBalance += doc.remains_balance;
        totalRemainsLessons += doc.remains_lessons;
      });

      setAllBalance(totalRemainsBalance);
      setAllLessons(totalRemainsLessons);
    } catch (error) {
      console.error("Ошибка при получении данных о балансе:", error);
      throw error;
    }
  }

  useEffect(() => {
    fetchSubscriptions(data?.$id);
  }, [data]);

  return (
    <div className="student-full-info main-container">
      <h4 className="student-full-info__title">Номер телефона</h4>
      <h5 className="student-full-info__information">
        {data ? formatPhoneNumber(data.phone_number) : "---"}
      </h5>
      <h4 className="student-full-info__title">Общий баланс</h4>

      <h5 className="student-full-info__information">{`${isAllBalance} ₽`}</h5>
      <h4 className="student-full-info__title">Всего занятий</h4>
      <h5 className="student-full-info__information">{isAllLessons}</h5>
      <h4 className="student-full-info__title">Бесплатные занятия</h4>
      <h5 className="student-full-info__information">
        {data ? data.free_classes : "---"}
      </h5>
      <h4 className="student-full-info__title">Кол-во баллов</h4>
      <h5 className="student-full-info__information">
        {data ? data.points : "---"}
      </h5>
      <h4 className="student-full-info__title">Дата рождения</h4>
      <h5 className="student-full-info__information">
        {data
          ? Intl.DateTimeFormat("ru-RU").format(new Date(data.date_of_birth))
          : "---"}
      </h5>
      <h4 className="student-full-info__title">Дата регистрации</h4>
      <h5 className="student-full-info__information">
        {data
          ? Intl.DateTimeFormat("ru-RU").format(new Date(data.date_of_arrival))
          : "---"}
      </h5>
      <h4 className="student-full-info__title">Комментарий</h4>
      <h5 className="student-full-info__information">
        {data && data.comment ? data.comment : "---"}
      </h5>
    </div>
  );
};

export default StudentFullInfo;
