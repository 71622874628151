import "./addNewWorker.sass";
import React, { useEffect, useState } from "react";
import { account, databases, storage } from "../../lib/appwrite";
import { ID } from "appwrite";
import Button from "../../components/button/Button";
import ImageUploader from "../../components/imageUploader/ImageUploader";
import Input from "../../components/input/Input";
import OptionMenu from "../../components/optionMenu/OptionMenu";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

// Загрузка аватара пользователя
async function uploadUserAvatar(userId, fileImage) {
  // Проверка наличия изображения
  if (!fileImage) {
    console.error("Отсутствует файл изображения.");
    return;
  }

  try {
    const bucketId = "65fb23f53117da6475ba";
    const newAvatarId = new Date().getTime().toString();

    // Создание файла в хранилище
    await storage.createFile(bucketId, newAvatarId, fileImage);

    // Получение URL для загруженного файла
    const downloadURL = storage.getFilePreview(bucketId, newAvatarId);

    // Обновление документа пользователя с новым URL аватара
    await databases.updateDocument(
      "65f9d6f4dbeeccbe7e74",
      "65f9d6fd218cc728529a",
      userId,
      { avatar_image: downloadURL, avatar_id: newAvatarId }
    );
  } catch (error) {
    console.error("Ошибка при загрузке аватара пользователя:", error);
  }
}

const AddNewWorker = ({ onModalOpen }) => {
  // Состояния
  const [isLoader, setIsLoader] = useState(false);
  const [isContainerImage, setContainerImage] = useState(true);
  // Значения полей ввода
  const [isName, setIsName] = useState("");
  const [isSurName, setIsSurName] = useState("");
  const [isPhoneNumber, setIsPhoneNumber] = useState("");
  const [isDateBirth, setIsDateBirth] = useState("");
  const [isEmail, setIsEmail] = useState("");
  const [isPost, setIsPost] = useState("");
  const [isNewPassword, setIsNewPassword] = useState("");
  const [fileImage, setFileImage] = useState("");
  // Значения ошибок
  const [error, setError] = useState(false);
  const [errorAppWrite, setErrorAppWrite] = useState(false);
  const [errorNullName, setErrorNullName] = useState(false);
  const [errorNullSurName, setErrorNullSurName] = useState(false);
  const [errorNullPhone, setErrorNullPhone] = useState(false);
  const [errorNullEmail, setErrorNullEmail] = useState(false);
  const [errorNullPost, setErrorNullPost] = useState(false);
  const [errorIncorrectEmail, setErrorIncorrectEmail] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [errorNullNewPassword, setErrorNullNewPassword] = useState(false);
  const [errorIncorrectNewPassword, setErrorIncorrectNewPassword] =
    useState(false);
  const [errorNullDateOfBirth, setErrorNullDateOfBirth] = useState(false);
  const validations = [
    { condition: isName.length === 0, setError: setErrorNullName },
    { condition: isSurName.length === 0, setError: setErrorNullSurName },
    { condition: isPhoneNumber.length === 0, setError: setErrorNullPhone },
    { condition: isDateBirth.length === 0, setError: setErrorNullDateOfBirth },
    {
      condition: isPost.length === 0 || isPost === "none-select",
      setError: setErrorNullPost,
    },
    {
      condition: isEmail.length === 0,
      setError: setErrorNullEmail,
    },

    {
      condition: isNewPassword.length === 0,
      setError: setErrorNullNewPassword,
    },
  ];
  //Значения селектора
  const userRole = [
    { value: "none-select", label: "Выберите..." },
    { value: "admin", label: "Администратор" },
    { value: "teacher", label: "Преподаватель" },
  ];

  useEffect(() => {
    //console.log("Изображение загружено", isContainerImage)
  }, [isContainerImage]);

  // Сброс ошибок и закрытие окна
  const handleModalClose = () => {
    onModalOpen(false);
    setIsName("");
    setIsSurName("");
    setIsPhoneNumber("");
    setIsDateBirth("");
    setFileImage("");
    setIsPost("");
    setErrorNullName(false);
    setErrorNullSurName(false);
    setErrorNullPhone(false);
    setErrorNullPost(false);
    setErrorAppWrite(false);
    setErrorNullDateOfBirth(false);
    setErrorNullNewPassword(false);
    setIsValidEmail(false);
    setErrorNullEmail(false);
    setErrorIncorrectEmail(false);
    setErrorIncorrectNewPassword(false);
    setError(false);
  };

  // Добавление нового сотрудника
  const handleSaveDataNewWorker = async (e) => {
    e.preventDefault();
    setErrorNullName(false);
    setErrorNullSurName(false);
    setErrorNullPhone(false);
    setErrorAppWrite(false);
    setErrorNullPost(false);
    setErrorNullDateOfBirth(false);
    setErrorNullNewPassword(false);
    setErrorNullEmail(false);
    setErrorIncorrectEmail(false);
    setErrorIncorrectNewPassword(false);
    setError(false);
    let hasError = false;
    let hasErrorValid = false;

    validations.forEach(({ condition, setError }) => {
      if (condition) {
        setError(true);
        hasError = true;
        hasErrorValid = false;
      } else {
        if (!hasError) {
          setError(false);
          hasErrorValid = true;
        } else {
          setError(true);
          hasErrorValid = false;
        }
      }
    });

    if (hasErrorValid) {
      if (!isValidEmail) {
        setErrorIncorrectEmail(true);
        setErrorNullEmail(true);
        hasError = true;
      }

      if (isNewPassword.length < 8) {
        setErrorIncorrectNewPassword(true);
        setErrorNullNewPassword(true);
        hasError = true;
      }
    } else {
      setError(true);
    }

    if (!hasError) {
      setIsLoader(true);
      try {
        // Создание нового пользователя в AppWrite
        const user = await account.create(ID.unique(), isEmail, isNewPassword);

        // Заполнение данных пользователя
        await databases.createDocument(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          user.$id,
          {
            user_id: user.$id,
            name: `${isName.charAt(0).toUpperCase()}${isName.slice(1)}`,
            sur_name: `${isSurName.charAt(0).toUpperCase()}${isSurName.slice(
              1
            )}`,
            phone_number: isPhoneNumber,
            email: isEmail,
            role: isPost,
            city: "voronezh",
            date_of_arrival: new Date().toISOString(),
            date_of_birth: new Date(isDateBirth).toISOString(),
            comment: ""
          }
        );

        // Загрузка изображения
        if (fileImage) {
          await uploadUserAvatar(user.$id, fileImage);
        }

        // Закрытие окна
        handleModalClose();
        setIsLoader(false);
      } catch (err) {
        setErrorAppWrite(true);
        setIsLoader(false);
        console.error(err);
      } finally {
        setIsLoader(false);
      }
    }
  };

  // Поля ввода
  const handleAddWorker = (e) => {
    e.preventDefault();
  };

  const handleInputName = (e) => {
    setIsName(e.target.value.trim());
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("surNameInput").focus();
    }
  };

  const handleInputSurName = (e) => {
    setIsSurName(e.target.value.trim());
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("numberPhoneInput").focus();
    }
  };

  const handleInputPhone = (e) => {
    const value = e.target.value.trim();
    if (value.length <= 12) {
      if (value.length > 0 && !value.startsWith("+")) {
        setIsPhoneNumber(`+${value}`);
      } else {
        setIsPhoneNumber(value);
      }
    }
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("dateBirthSelect").focus();
    }
  };

  const handleInputDateBirth = (e) => {
    setIsDateBirth(e.target.value.trim());
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("emailWorker").focus();
    }
  };

  const handleInputEmail = (e) => {
    setIsEmail(e.target.value.trim());
    setIsValidEmail(validateEmail(e.target.value));
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("newPasswordWorkerInput").focus();
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputNewPassword = (e) => {
    setIsNewPassword(e.target.value.trim());
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("newPasswordWorkerInput").blur();
    }
  };

  return (
    <div className="modal">
      <div className="modal__content-container">
        <h2>Новый сотрудник</h2>

        <div className="modal__new-data-worker">
          <ImageUploader
            imageFile={setFileImage}
            containerSetImage={setContainerImage}
          />

          <form
            onSubmit={handleAddWorker}
            className="form-worker"
            autoComplete="off"
          >
            <div className="form-worker__inputs">
              <Input
                title={"Имя"}
                className={`form-worker__input-name capitalize ${
                  errorNullName && "error"
                }`}
                classContainer={"worker-input"}
                id={"nameInput"}
                type={"text"}
                value={isName}
                onChange={handleInputName}
                placeholder={"Имя"}
                onKeyDown={handleInputName}
              />
              <Input
                title={"Фамилия"}
                id={"surNameInput"}
                className={`form-worker__input-sur-name capitalize ${
                  errorNullSurName && "error"
                }`}
                classContainer={"worker-input"}
                type={"text"}
                value={isSurName}
                onChange={handleInputSurName}
                placeholder={"Фамилия"}
                onKeyDown={handleInputSurName}
              />
              <Input
                title={"Номер телефона"}
                id={"numberPhoneInput"}
                className={`form-worker__input-phone ${
                  errorNullPhone && "error"
                }`}
                classContainer={"worker-input"}
                type={"text"}
                value={isPhoneNumber}
                onChange={handleInputPhone}
                placeholder={"Номер телефона (+7)"}
                onKeyDown={handleInputPhone}
              />
              <Input
                title={"Дата рождения"}
                id={"dateBirthSelect"}
                className={`form-worker__input-date-birth ${
                  errorNullDateOfBirth && "error"
                }`}
                classContainer={"worker-input"}
                type={"date"}
                value={isDateBirth}
                onChange={handleInputDateBirth}
                placeholder={"Дата рождения"}
                onKeyDown={handleInputDateBirth}
                autoComplete={"bday"}
              />

              <Input
                title={"Почта"}
                id={"emailWorker"}
                className={`form-worker__input-email ${
                  errorNullEmail && "error"
                }`}
                classContainer={"worker-input"}
                type={"email"}
                value={isEmail}
                onChange={handleInputEmail}
                placeholder={"Почта"}
                onKeyDown={handleInputEmail}
                autoComplete={"email"}
              />

              <Input
                title={"Новый пароль"}
                id={"newPasswordWorkerInput"}
                className={`form-worker__input-new-password ${
                  errorNullNewPassword && "error"
                }`}
                classContainer={"worker-input"}
                type={"password"}
                value={isNewPassword}
                onChange={handleInputNewPassword}
                placeholder={"Пароль"}
                onKeyDown={handleInputNewPassword}
                autoComplete={"new-password"}
              />

              <OptionMenu
                options={userRole}
                title={"Должность"}
                id={"addWorkerOptionPost"}
                classSelect={`form-worker__option-post ${
                  errorNullPost && "error"
                }`}
                className={"worker-input form-worker__option-post-container"}
                onChange={(e) => setIsPost(e.target.value)}
              />
            </div>
          </form>
        </div>

        <div className="modal__buttons">
          {error && <h4>Пожалуйста, заполните все поля!</h4>}

          {errorAppWrite && <h4>Произошла ошибка, попробуйте снова</h4>}

          {errorIncorrectEmail && (
            <h4>Электронная почта введена некорректно!</h4>
          )}

          {errorIncorrectNewPassword && (
            <h4>Пароль должен быть не менее 8 символов!</h4>
          )}

          <Button
            className={"modal__close-button"}
            colorStyle={"invisible"}
            onClick={handleModalClose}
          >
            Отмена
          </Button>
          <Button
            className={"modal__save-button"}
            colorStyle={"blue"}
            onClick={handleSaveDataNewWorker}
            disabled={
              !isName ||
              !isSurName ||
              !isPhoneNumber ||
              !isDateBirth ||
              !isEmail ||
              !isPost ||
              !isNewPassword
            }
          >
            Сохранить
          </Button>
        </div>
      </div>
      {isLoader && (
        <div className="modal__container-loader-upload">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}
    </div>
  );
};

export default AddNewWorker;
