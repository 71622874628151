import { useCallback, useEffect, useState } from "react";
import CheckBox from "../checkBox/CheckBox";
import Input from "../input/Input";
import OptionMenu from "../optionMenu/OptionMenu";
import "./studentIsViewingLesson.sass";

const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.length !== 12) {
    console.error("Неверная длина номера телефона:", phoneNumber);
    return phoneNumber; // Возвращаем оригинальный номер, если длина неверна
  }

  const parts = phoneNumber.split("");
  return `${parts.slice(0, 2).join("")} (${parts.slice(2, 5).join("")}) ${parts
    .slice(5, 8)
    .join("")}-${parts.slice(8, 10).join("")}-${parts.slice(10).join("")}`;
};

const StudentIsViewingLesson = ({
  student,
  onAttendanceChange,
  disabled,
  detailsLessonData,
  lessonType,
}) => {
  let paymentMethodOptions = [
    { value: "account", label: "Баланс" },
    { value: "points", label: "Баллы" },
    { value: "certificate", label: "Сертификат" },
  ];

  let reasonOptions = [
    { value: "absent", label: "Неуважительная" },
    { value: "respectful", label: "Уважительная" },
  ];
  if (detailsLessonData) {
    reasonOptions.push({ value: "none", label: "----" });
    paymentMethodOptions.push({ value: "none", label: "----" });
  }

  const [attendance, setAttendance] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("account");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [validReason, setValidReason] = useState("absent");

  useEffect(() => {
    if (detailsLessonData) {
      setAttendance(detailsLessonData.attendance === "present");
      setPaymentMethod(
        detailsLessonData.attendance === "respectful"
          ? "none"
          : detailsLessonData.payment_method || "account"
      );
      setPaymentAmount(detailsLessonData.payment_amount || "");
      setValidReason(
        detailsLessonData.attendance === "present"
          ? "none"
          : detailsLessonData.attendance
      );
    }
  }, [detailsLessonData]);

  const handleAttendanceChange = useCallback(
    (attendanceValue, reason) => {
      onAttendanceChange(student.$id, {
        attendance: attendanceValue ? "present" : reason,
        paymentMethod,
        paymentAmount,
      });
    },
    [student, paymentMethod, paymentAmount]
  );

  const handleAttendance = useCallback((checked) => {
    setAttendance(checked);
    handleAttendanceChange(checked, validReason);
  }, []);

  const handlePaymentMethodChange = useCallback(
    (event) => {
      const method = event.target.value;
      setPaymentMethod(method);
      handleAttendanceChange(attendance, validReason);
    },
    [handleAttendanceChange, attendance, validReason]
  );

  const handlePaymentAmountChange = useCallback(
    (event) => {
      const amount = event.target.value;
      setPaymentAmount(amount);
      handleAttendanceChange(attendance, validReason);
    },
    [handleAttendanceChange, attendance, validReason]
  );

  const handleValidReasonChange = useCallback(
    (event) => {
      const reason = event.target.value;
      setValidReason(reason);
      handleAttendanceChange(attendance, reason);
    },
    [handleAttendanceChange, attendance]
  );

  return (
    <div className="student-viewing">
      <CheckBox
        setCheckbox={handleAttendance}
        disabled={disabled}
        setActiveCheckBoxStudent={attendance}
      />
      {student && student.avatar_image ? (
        <img
          className="student-viewing__avatar"
          src={student.avatar_image}
          alt="Avatar"
        />
      ) : (
        <div className="student-viewing__avatar"></div>
      )}
      <div className="student-viewing__username">
        <h4 className="title-lesson-one">
          {student ? `${student.name} ${student.sur_name}` : "----"}
        </h4>
        <h4 className="title-lesson-two color-gray">
          {student ? formatPhoneNumber(student.phone_number) : "----"}
        </h4>
      </div>

      {lessonType !== "trial" && (
        <div className="student-viewing__payment-container">
          <OptionMenu
            id={`studentPaymentMethod_${student.$id}`}
            options={paymentMethodOptions}
            value={paymentMethod}
            classSelect={"student-viewing__payment-method"}
            onChange={handlePaymentMethodChange}
            disabled={(!attendance && validReason === "respectful") || disabled}
            titleVisible={false}
          />

          <Input
            id={`studentPaymentCostInput_${student.$id}`}
            className={`student-viewing__payment-cost ${
              !paymentAmount &&
              paymentMethod === "points" &&
              validReason !== "respectful" &&
              "error"
            }`}
            classContainer={"student-viewing__payment-cost-container"}
            type={"number"}
            value={paymentAmount}
            disabled={
              (!attendance && validReason === "respectful") ||
              paymentMethod === "account" ||
              paymentMethod === "certificate" ||
              disabled
            }
            onChange={handlePaymentAmountChange}
            placeholder={"Сумма"}
            titleVisible={false}
          />
        </div>
      )}

      {lessonType !== "trial" && (
        <OptionMenu
          id={`studentValidReasonStudent_${student.$id}`}
          options={reasonOptions}
          value={validReason}
          classSelect={"student-viewing__reason"}
          onChange={handleValidReasonChange}
          disabled={attendance || disabled}
          titleVisible={false}
        />
      )}
    </div>
  );
};

export default StudentIsViewingLesson;
