import "./teacherHome.sass";

import React, { useCallback, useEffect, useState } from "react";
import { account, databases } from "../../lib/appwrite";

import TeacherProfile from "../../components/teacherProfile/TeacherProfile";
import TypeSelector from "../../components/typSelector/TypeSelector";
import { CircularProgress, Stack } from "@mui/material";
import DateSelector from "../../components/dateSelector/DateSelector";
import ToolbarWeek from "../../components/toolbarWeek/ToolbarWeek";
import ScheduleEntries from "../../components/scheduleEntries/ScheduleEntries";
import useScheduleData from "../../hooks/useScheduleData";
import ViewingLessonTeacher from "../../components/viewingLesson/viewingLessonTeacher";

const TeacherHome = () => {
  // Переменные
  const [currentDateSchedule, setCurrentDateSchedule] = useState(() => {
    const start = new Date();
    start.setHours(0, 0, 0, 0); // Устанавливаем начало дня
    return start;
  });

  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpenProfile, setOpenProfile] = useState(false);
  const [isOpenModalLesson, setOpenModalLesson] = useState(false);
  const [scheduleType, setScheduleType] = useState("day");
  const [isVisibleSelector, setVisibleSelector] = useState(true);
  const [dataLessonOpen, setDataLessonOpen] = useState();

  const typeSelectorOptions = [
    { value: "day", label: "День" },
    { value: "week", label: "Неделя" },
  ];

  useEffect(() => {
    document.title = "Расписание занятий";
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  // Эффект для блокировки и разблокировки прокрутки
  useEffect(() => {
    const toggleBodyOverflow = (value) => {
      document.body.style.overflow = value;
    };

    if (isOpenModalLesson) {
      toggleBodyOverflow("hidden");
    } else {
      toggleBodyOverflow("visible");
      setDataLessonOpen("");
    }

    return () => toggleBodyOverflow("visible");
  }, [isOpenModalLesson]);

  const handleOpenViewingLesson = useCallback((item) => {
    setOpenModalLesson((prevState) => {
      if (!prevState) {
        setDataLessonOpen(item);
      }
      return true;
    });
  }, []);

  const getUser = async () => {
    try {
      const userData = await account.get();
      setUser(userData);

      const response = await databases.getDocument(
        "65f9d6f4dbeeccbe7e74",
        "65f9d6fd218cc728529a",
        userData.$id
      );
      setUserData(response);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1023) {
        setScheduleType("day");
        setVisibleSelector(false);
      } else {
        setVisibleSelector(true);
      }
    };

    // Устанавливаем начальное состояние при монтировании компонента
    handleResize();

    // Добавляем слушатель события изменения размера окна
    window.addEventListener("resize", handleResize);

    // Удаляем слушатель при размонтировании компонента
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { isLoading, scheduleItems } = useScheduleData(
    currentDateSchedule,
    userData,
    "teacher"
  );

  const handleSelectionChange = useCallback((selectedValue) => {
    setScheduleType(selectedValue);
  }, []);

  return (
    <div className="teacher-home">
      <div className="teacher-toolbar">
        <img
          alt="Логотип ERP системы Голосовое"
          className="teacher-toolbar__logo"
        />
        <h4 className="teacher-toolbar__date">
          {new Date().toLocaleDateString("ru-RU", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </h4>
        <div
          className="teacher-toolbar__user-info"
          onClick={() => {
            setOpenProfile(!isOpenProfile);
          }}
        >
          <div className="teacher-toolbar__username-container">
            <h4 className="teacher-toolbar__username">
              {userData ? `${userData.name} ${userData.sur_name}` : "..."}
            </h4>
            <h4 className="teacher-toolbar__role">Преподаватель</h4>
          </div>

          {userData && userData.avatar_image ? (
            <img
              className="teacher-toolbar__avatar"
              src={userData.avatar_image}
              alt="Avatar"
            />
          ) : (
            <div className="teacher-toolbar__avatar"></div>
          )}
        </div>
      </div>
      {!loading && (
        <div className="main-container-teacher">
          <div className="main-container-teacher__elements">
            <div className="calendar">
              {isLoading && (
                <div className="loading-container-calendar">
                  <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
                    <CircularProgress color="inherit" />
                  </Stack>
                </div>
              )}
              <div className="toolbar-calendar">
                <div className="toolbar-calendar__top">
                  <DateSelector
                    date={currentDateSchedule}
                    setDateCurrent={setCurrentDateSchedule}
                    format={scheduleType}
                  />
                  {isVisibleSelector && (
                    <TypeSelector
                      className={"main-container-teacher__type-selector"}
                      options={typeSelectorOptions}
                      initialSelected={scheduleType}
                      onSelectType={(value) => handleSelectionChange(value)}
                    />
                  )}
                </div>
                {scheduleType === "week" && (
                  <div className="toolbar-calendar__bottom ">
                    <ToolbarWeek currentDateSchedule={currentDateSchedule} />
                  </div>
                )}
              </div>
              <div className="calendar__content">
                <ScheduleEntries
                  items={scheduleItems}
                  currentDate={currentDateSchedule}
                  typeSession={scheduleType}
                  handleItemClick={handleOpenViewingLesson}
                  userRole={"teacher"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="teacher-home__loading-element">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}

      {isOpenProfile && (
        <TeacherProfile
          userData={userData}
          modalOpen={setOpenProfile}
          loading={loading}
        />
      )}
      {isOpenModalLesson && (
        <ViewingLessonTeacher
          isModalOpen={setOpenModalLesson}
          dataLesson={dataLessonOpen}
        />
      )}
    </div>
  );
};

export default TeacherHome;
