import "./workerList.sass";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { databases, client } from "../../lib/appwrite";
import { Query } from "appwrite";
import EditWorker from "../editWorker/EditWorker";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const WorkerList = ({ searchTerm, listUpdate }) => {
  // Сосотояния
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUid, setSelectedUid] = useState(null);
  const [isModalEditOpen, setIsModalEditOpen] = useState("");

  // Функция для получения данных по ролям
  const fetchDataByRole = async (role) => {
    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "65f9d6fd218cc728529a",
        [Query.equal("role", role), Query.equal("city", "voronezh")]
      );
      return response.documents.map((doc) => ({ ...doc, role }));
    } catch (error) {
      console.error("Ошибка при получении данных по роли:", error);
      return [];
    }
  };

  // Обновление списка
  const handleUpdate = useCallback(async () => {
    try {
      const dataAdmin = await fetchDataByRole("admin");
      const dataTeacher = await fetchDataByRole("teacher");
      setUsers([...dataAdmin, ...dataTeacher]);
    } catch (error) {
      console.error(
        "Неожиданная ошибка при обновлении списка пользователей:",
        error
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    handleUpdate();
  }, [listUpdate]);

  useEffect(() => {
    !isModalEditOpen && handleUpdate();
  }, [isModalEditOpen]);

  useEffect(() => {
    // Подписка на изменения в коллекции
    const unsubscribe = client.subscribe(
      "databases.65f9d6f4dbeeccbe7e74.collections.65f9d6fd218cc728529a.documents",
      () => {
        handleUpdate();
      }
    );

    // Отписка от событий при размонтировании компонента
    return () => unsubscribe();
  }, []);

  // Форматирование данных
  const formatPhoneNumber = (phoneNumber) => {
    const parts = phoneNumber.split("");
    return `${parts.slice(0, 2).join("")} (${parts
      .slice(2, 5)
      .join("")}) ${parts.slice(5, 8).join("")}-${parts
      .slice(8, 10)
      .join("")}-${parts.slice(10, 12).join("")}`;
  };

  // Поиск по сотрудникам
  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.sur_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone_number.includes(searchTerm)
  );

  // Запись сотрудника
  const renderUsers = (userStudents) => {
    return userStudents.map((user, index) => (
      <tr
        key={index}
        className="record-worker"
        onClick={() => {
          setSelectedUid(user.user_id);
          setIsModalEditOpen(true);
        }}
      >
        <td className="record-worker__text-start">
          <div className="record-worker__container-worker">
            {user.avatar_image ? (
              <img src={user.avatar_image} alt="Avatar" />
            ) : (
              <div className="avatar-placeholder-worker" />
            )}
            <h4 className="record-worker__text">{`${user.name} ${user.sur_name}`}</h4>
          </div>
        </td>

        <td className="record-worker__text-center">
          {formatPhoneNumber(user.phone_number)}
        </td>
        <td className="record-worker__text-center">
          {user.role === "admin" && "Администратор"}
          {user.role === "teacher" && "Преподаватель"}
        </td>
        <td className="record-worker__text-center">
          {user.date_of_arrival
            ? Intl.DateTimeFormat("ru-RU").format(
                new Date(user.date_of_arrival)
              )
            : "--"}
        </td>
        <td className="record-worker__text-last">
          {user.date_of_birth
            ? Intl.DateTimeFormat("ru-RU").format(new Date(user.date_of_birth))
            : "--"}
        </td>
        <td className="record-worker__edit-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            viewBox="0 -960 960 960"
            width="20"
          >
            <path d="M216-216h51l375-375-51-51-375 375v51Zm-35.818 72Q165-144 154.5-154.297q-10.5-10.298-10.5-25.52v-86.856Q144-281 149-294q5-13 16-24l477-477q11-11 23.836-16 12.836-5 27-5T720-811q13 5 24 16l51 51q11 11 16 24t5 26.542q0 14.444-5.022 27.534Q805.957-652.833 795-642L318-165q-11 11-23.95 16-12.949 5-27.239 5h-86.629ZM744-693l-51-51 51 51Zm-127.947 76.947L591-642l51 51-25.947-25.053Z" />
          </svg>
        </td>
      </tr>
    ));
  };

  return (
    <div className="students-list">
      {isModalEditOpen && (
        <EditWorker
          onModalEditOpen={setIsModalEditOpen}
          workerUID={selectedUid}
        />
      )}
      {isLoading ? (
        <div className="students-list__container-loader">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      ) : (
        <table>
          <tbody>{renderUsers(filteredUsers)}</tbody>
        </table>
      )}
    </div>
  );
};

export default WorkerList;
