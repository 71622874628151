import { useState } from "react";
import "./studentInfoComponents.sass";

const userActive = (timestamp) => {
  const nowDate = new Date();
  const dateLastActive = new Date(timestamp);
  const difference = nowDate - dateLastActive;
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const daysInMonth = 30;
  const millisecondsPerMonth = daysInMonth * millisecondsPerDay;

  if (difference <= millisecondsPerMonth) {
    return true;
  } else {
    return false;
  }
};

const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.length !== 12) {
    console.error("Неверная длина номера телефона:", phoneNumber);
    return phoneNumber; // Возвращаем оригинальный номер, если длина неверна
  }

  const parts = phoneNumber.split("");
  return `${parts.slice(0, 2).join("")} (${parts.slice(2, 5).join("")}) ${parts
    .slice(5, 8)
    .join("")}-${parts.slice(8, 10).join("")}-${parts.slice(10).join("")}`;
};

const MainStudentInfo = ({ data }) => {
  const [isIdVisible, setIdVisible] = useState(false);

  return (
    <div className="main-student-info main-container">
      {data && data.avatar_image ? (
        <img
          className="main-student-info__avatar"
          src={data.avatar_image}
          alt="Avatar"
        />
      ) : (
        <div className="main-student-info__avatar"></div>
      )}

      <div className="main-student-info__information">
        <h4 className="main-student-info__student-name">
          {data ? `${data.name} ${data.sur_name}` : "Загрузка..."}
        </h4>
        <h5 className="main-student-info__phone-number">
          {data && formatPhoneNumber(data.phone_number)}
        </h5>
      </div>
      <h5
        className={`main-student-info__status-student
        ${
          data && userActive(data.last_activity)
            ? "active-status"
            : "non-active-status"
        }
         `}
      >
        {data && userActive(data.last_activity) ? "Активный" : "Неактивный"}
      </h5>

      {isIdVisible ? (
        <h5 className="main-student-info__id" onDoubleClick={() => setIdVisible(!isIdVisible)}>{data && `ID ${data.$id}`}</h5>
      ) : (
        <h5 className="main-student-info__id-unvisible" onDoubleClick={() => setIdVisible(!isIdVisible)}>{data && `ID`}</h5>
      )}
    </div>
  );
};

export default MainStudentInfo;
