import "./optionMenu.sass";
import React from "react";

const OptionMenu = ({ options, title, onChange, classMenu, classSelect, value, className, id, disabled, titleVisible = true }) => {
  return (
    <div className={`container-option-menu ${className}`}>
      {titleVisible && <h3 className={`title-text-option-menu ${classMenu}`}>{title}</h3>}
      <select className={`select-elements ${classSelect} ${disabled && "option-hide"}`} onChange={onChange} value={value} id={id}>
        {options.map((option) => (
          <option key={option.value} value={option.value} disabled={disabled}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default OptionMenu;
