import "./recordTask.sass";
import Button from "../button/Button";

const RecordTaskStudent = ({
  type,
  date,
  overdue,
  onClick,
  comment,
  phone,
  onButtonDoneClick,
  taskToday,
}) => {
  return (
    <div className="task-row-student">
      <div className="task-row-student__info">
        <h5 className="task-title task-type-student">{type}</h5>
        {overdue && (
          <h5 className="task-text-overdue red-text-overdue">Просроченно</h5>
        )}
        {taskToday && (
          <h5 className="task-text-task-today">Сегодня</h5>
        )}
        <h4 className={`task-row-student__phone-title task-title-2`}>
          Номер телефона
        </h4>
        <h5
          className={`task-row-student__phone-number task-subtitle bottom-12`}
        >
          {phone}
        </h5>

        <h4 className={`task-row-student__comment-title task-title-2`}>
          Комментарий
        </h4>
        <h5 className={`task-row-student__comment task-subtitle bottom-12`}>
          {comment}
        </h5>

        <h4 className={`task-row-student__date-title task-title-2`}>
          Дата выполнения
        </h4>
        <h5 className={`task-row-student__date task-subtitle`}>{date}</h5>
      </div>
      <div className="task-row-student__click-zone" onClick={onClick}></div>
      <Button
        colorStyle={"blue"}
        className={"task-row-student__button-done"}
        onClick={onButtonDoneClick}
      >
        Выполнено
      </Button>
    </div>
  );
};

export default RecordTaskStudent;
