const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        currentUser: action.payload.user,
        role: action.payload.role,
      };
    case "LOGOUT":
      return {
        ...state,
        currentUser: null,
        role: null,
      };
    case "SET_ROLE":
      return {
        ...state,
        role: action.payload.role,
      };
    default:
      return state;
  }
};

export default AuthReducer;