import { useState } from "react";
import "./addFreeLessons.sass";
import Input from "../input/Input";
import Button from "../button/Button";
import { databases } from "../../lib/appwrite";

const AddFreeLessons = ({ uid, data, modalOpen }) => {
  const [isFreeLessons, setFreeLessons] = useState("");
  const [isErrorAppWrite, setErrorAppWrite] = useState(false);

  const handleAddFreeLessons = async () => {
    if (isFreeLessons && uid) {
      try {
        const additionalFreeClasses = Number(isFreeLessons);
        const newFreeClassesTotal = data.free_classes + additionalFreeClasses;

        await databases.updateDocument(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          uid,
          { free_classes: newFreeClassesTotal }
        );
      } catch (error) {
        console.error("Failed to update free classes:", error);
        setErrorAppWrite(true);
      }
    }

    modalOpen(false);
  };

  return (
    <div className="add-free-lessons-blackout">
      <div className="add-free-lessons">
        <h4 className="add-free-lessons__title">
          Добавление бесплатных уроков
        </h4>
        <Input
          title={"Кол-во уроков"}
          id={"lessonFreeInputLessons"}
          classContainer={"add-free-lessons__input-free-lesson"}
          type={"number"}
          value={isFreeLessons}
          onChange={(e) => setFreeLessons(e.target.value)}
          placeholder={"0"}
        />
        <div className="add-free-lessons__buttons">
          {isErrorAppWrite && (
            <h4 className="add-free-lessons__error-message">
              Произошла ошибка на стороне сервера, повторите попытку
            </h4>
          )}
          <Button
            className={"add-free-lessons__button-cancel"}
            colorStyle={"invisible"}
            onClick={() => {
              modalOpen(false);
            }}
          >
            Отмена
          </Button>
          <Button colorStyle={"blue"} onClick={handleAddFreeLessons}>
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddFreeLessons;
