import "./tasks.sass";
import React, { useCallback, useEffect, useState } from "react";
import { databases, client } from "../../lib/appwrite";
import { Query } from "appwrite";
import Sidebar from "../../components/sidebar/Sidebar";
import Button from "../../components/button/Button";
import SearchInput from "../../components/searchInput/SearchInput";
import IconButton from "../../components/iconButton/IconButton";
import TasksMini from "../../components/tasksMini/TasksMini";
import RecordTask from "../../components/recordTask/RecordTask";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import CreatorTask from "../../components/creatorTask/CreatorTask";
import ViewingTask from "../../components/viewingTask/ViewingTask";

const Tasks = () => {
  // Значения
  const [isTasks, setTasks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [modalNewTaskOpen, setModalNewTaskOpen] = useState(false);
  const [modalViewingTaskOpen, setModalViewingTaskOpen] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState("");

  // Сегодняшняя дата
  const currentDate = new Date();
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = currentDate.toLocaleDateString("ru-RU", options);

  useEffect(() => {
    document.title = "Задачи";
  }, []);

  // Функция для получения данных по ролям
  const fetchDataByRole = async (city) => {
    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "66041861c5966a6ddda5",
        [Query.equal("city", city)]
      );
      return response.documents.map((doc) => ({ ...doc, city }));
    } catch (error) {
      console.error("Ошибка при получении данных города:", error);
      return [];
    }
  };

  // Обновление списка
  const handleUpdate = useCallback(async () => {
    try {
      const dataTasks = await fetchDataByRole("voronezh");
      setTasks(dataTasks);
    } catch (error) {
      console.error("Неожиданная ошибка при обновлении списка задач:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Обновление списка при открытии
  useEffect(() => {
    setLoading(true);
    handleUpdate();
  }, []);

  // Обновление списка в реальном времени
  useEffect(() => {
    // Подписка на изменения в коллекции
    const unsubscribe = client.subscribe(
      "databases.65f9d6f4dbeeccbe7e74.collections.66041861c5966a6ddda5.documents",
      () => {
        handleUpdate();
      }
    );

    // Отписка от событий при размонтировании компонента
    return () => unsubscribe();
  }, []);

  // Очистка пользователя
  useEffect(() => {
    if (!modalNewTaskOpen) {
      setSelectedUserInfo("");
    }
  }, [modalNewTaskOpen]);

  // Фильтрация и поиск записей
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const filteredTasksToday = isTasks.filter((task) => {
    const completionDate = new Date(task.date_of_completion);
    completionDate.setHours(0, 0, 0, 0); // Устанавливаем начало дня для даты выполнения
    return (
      (task.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        task.client.toLowerCase().includes(searchTerm.toLowerCase()) ||
        task.phone_number.includes(searchTerm)) &&
      completionDate.getTime() === today.getTime()
    );
  });

  const filteredTasksOverdue = isTasks.filter((task) => {
    const completionDate = new Date(task.date_of_completion);
    completionDate.setHours(0, 0, 0, 0); // Устанавливаем начало дня для даты выполнения
    return (
      (task.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        task.client.toLowerCase().includes(searchTerm.toLowerCase()) ||
        task.phone_number.includes(searchTerm)) &&
      completionDate.getTime() < today.getTime()
    );
  });

  const filteredTasksAll = isTasks.filter((task) => {
    const completionDate = new Date(task.date_of_completion);
    completionDate.setHours(0, 0, 0, 0); // Устанавливаем начало дня для даты выполнения
    return (
      (task.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        task.client.toLowerCase().includes(searchTerm.toLowerCase()) ||
        task.phone_number.includes(searchTerm)) &&
      completionDate.getTime() > today.getTime()
    );
  });

  // Открытие окна реадактирования
  const handleOpenModalNewTask = (e) => {
    e.preventDefault();
    setSelectedUserInfo("");
    setModalNewTaskOpen(true);
  };

  // Открытие окна просмотра
  const handleOpenViewingTask = (info) => {
    setSelectedUserInfo(info);
    setModalViewingTaskOpen(true);
  };

  // Нажатие на пункт меню
  const handleClickMenu = async (key, infoUser) => {
    switch (key) {
      case "done":
        await databases.deleteDocument(
          "65f9d6f4dbeeccbe7e74",
          "66041861c5966a6ddda5",
          infoUser.$id
        );
        break;

      case "edit":
        setSelectedUserInfo(infoUser);
        setModalNewTaskOpen(true);
        break;

      case "delete":
        await databases.deleteDocument(
          "65f9d6f4dbeeccbe7e74",
          "66041861c5966a6ddda5",
          infoUser.$id
        );
        break;
    }
  };

  // Запись
  const renderTasks = (tasks, overdue) => {
    return tasks.map((task, index) => (
      <RecordTask
        onClick={() => handleOpenViewingTask(task)}
        key={index}
        type={task.type}
        client={task.client}
        date={Intl.DateTimeFormat("ru-RU", options).format(
          new Date(task.date_of_completion)
        )}
        overdue={overdue ? true : false}
        itemClick={(item) => {
          handleClickMenu(item.key, task);
        }}
      />
    ));
  };

  return (
    <div className="tasks desktop-version">
      <Sidebar />
      <div className="main-container-pages">
        <div className="main-container-pages__container">
          <div className="main-container-pages__texts">
            <h2 className="main-container-pages__title">Задачи</h2>
            <div className="toolbar-tasks">
              <Button
                className={"toolbar-tasks__button-new-task"}
                colorStyle={"blue"}
                onClick={handleOpenModalNewTask}
              >
                Новая задача
              </Button>
              <SearchInput
                className={"toolbar-tasks__search-tasks"}
                holderText={"Поиск задачи..."}
                width={"width-519"}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M660-120q-75 0-127.5-52.5T480-300q0-75 52.5-127.5T660-480q75 0 127.5 52.5T840-300q0 26-7.5 50T812-204l80 80q11 11 11 28t-11 28q-11 11-28 11t-28-11l-80-80q-22 13-46 20.5t-50 7.5Zm0-80q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm-460 80q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h167q11-35 43-57.5t70-22.5q40 0 71.5 22.5T594-840h166q33 0 56.5 23.5T840-760v160q0 17-11.5 28.5T800-560q-17 0-28.5-11.5T760-600v-160h-80v80q0 17-11.5 28.5T640-640H320q-17 0-28.5-11.5T280-680v-80h-80v560h160q17 0 28.5 11.5T400-160q0 17-11.5 28.5T360-120H200Zm280-640q17 0 28.5-11.5T520-800q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800q0 17 11.5 28.5T480-760Z" />
                  </svg>
                }
              />

              <IconButton
                className={"toolbar-tasks__button-update-data-tasks"}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-70q0-17 11.5-28.5T760-800q17 0 28.5 11.5T800-760v200q0 17-11.5 28.5T760-520H560q-17 0-28.5-11.5T520-560q0-17 11.5-28.5T560-600h128q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q68 0 124.5-34.5T692-367q8-14 22.5-19.5t29.5-.5q16 5 23 21t-1 30q-41 80-117 128t-169 48Z" />
                  </svg>
                }
                onClick={() => handleUpdate()}
              />
            </div>
            <h3 className="main-container-pages__date">{formattedDate}</h3>
          </div>

          <div className="main-container-pages__container-data types-of-tasks">
            <TasksMini
              className={"types-of-tasks__overdue-container"}
              colorLine={"overdue"}
              title={"Просроченные"}
            >
              {filteredTasksOverdue.length > 0
                ? renderTasks(filteredTasksOverdue, true)
                : !isLoading && (
                    <h4 className="types-of-tasks__text-placeholder">
                      Нет записей
                    </h4>
                  )}
            </TasksMini>
            <TasksMini
              className={"types-of-tasks__today-container"}
              colorLine={"today"}
              title={"На сегодня"}
            >
              {isLoading && (
                <div className="types-of-tasks__container-loader">
                  <Stack sx={{ color: "#0065FD" }} spacing={10} direction="row">
                    <CircularProgress color="inherit" />
                  </Stack>
                </div>
              )}

              {filteredTasksToday.length > 0
                ? renderTasks(filteredTasksToday, false)
                : !isLoading && (
                    <h4 className="types-of-tasks__text-placeholder">
                      Нет записей
                    </h4>
                  )}
            </TasksMini>
            <TasksMini
              className={"types-of-tasks__all-container"}
              colorLine={"all"}
              title={"Все"}
            >
              {filteredTasksAll.length > 0
                ? renderTasks(filteredTasksAll, false)
                : !isLoading && (
                    <h4 className="types-of-tasks__text-placeholder">
                      Нет записей
                    </h4>
                  )}
            </TasksMini>
          </div>
        </div>
        {modalNewTaskOpen && (
          <CreatorTask
            isModalOpen={setModalNewTaskOpen}
            editUserData={selectedUserInfo}
          />
        )}
        {modalViewingTaskOpen && (
          <ViewingTask
            isOpenViewing={setModalViewingTaskOpen}
            userDataView={selectedUserInfo}
            openingCreatorTask={setModalNewTaskOpen}
            selectedInfo={setSelectedUserInfo}
          />
        )}
      </div>
    </div>
  );
};

export default Tasks;
