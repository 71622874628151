import React from "react";
import RecordLesson from "../recordsSchedule/RecordLesson";
import RecordLessonWeek from "../recordsSchedule/RecordLessonWeek";
import "./recordSession.sass";

// Распределение ширины для каждой записи
function distributeColumns(scheduleItems, currentDate) {
  // Фильтруем элементы, чтобы оставить только те, что соответствуют заданной дате
  const filteredItems = scheduleItems.filter((item) => {
    const itemDate = new Date(item.date_start);
    return (
      itemDate.getDate() === currentDate.getDate() &&
      itemDate.getMonth() === currentDate.getMonth() &&
      itemDate.getFullYear() === currentDate.getFullYear()
    );
  });

  // Сортируем отфильтрованные элементы по времени начала
  filteredItems.sort(
    (a, b) => a.time_start - b.time_start || a.duration - b.duration
  );

  // Группируем перекрывающиеся элементы в блоки
  let blocks = [];
  filteredItems.forEach((item) => {
    let added = false;
    for (let block of blocks) {
      if (
        block.some(
          (other) =>
            other.time_start < item.time_start + item.duration &&
            other.time_start + other.duration > item.time_start
        )
      ) {
        block.push(item);
        added = true;
        break;
      }
    }
    if (!added) {
      blocks.push([item]);
    }
  });

  // Распределяем элементы по колонкам внутри каждого блока
  blocks.forEach((block) => {
    block.sort(
      (a, b) => a.time_start - b.time_start || a.duration - b.duration
    ); // Сортируем элементы в блоке
    let columns = [];
    block.forEach((item) => {
      let placed = false;
      for (let column of columns) {
        if (
          !column.some(
            (other) =>
              other.time_start < item.time_start + item.duration &&
              other.time_start + other.duration > item.time_start
          )
        ) {
          column.push(item);
          placed = true;
          break;
        }
      }
      if (!placed) {
        columns.push([item]);
      }
    });

    // Устанавливаем количество колонок для каждого элемента в блоке
    const totalColumns = columns.length;
    block.forEach((item) => {
      item.columns = 60 / totalColumns;
    });
  });

  // Возвращаем результат после всех операций
  return blocks;
}

const RecordItem = React.memo(
  ({ item, index, blockIndex, Component, onItemClick, userRole }) => (
    <Component
      key={`${blockIndex}-${index}`}
      id={`${blockIndex}-${index}`}
      className="schedule-item"
      style={{
        gridColumn: `span ${item.columns}`,
        gridRowStart: item.time_start === 0 ? 1 : item.time_start * 2 + 1,
        gridRowEnd: `span ${item.duration / 0.5}`,
      }}
      userData={item}
      attendance={
        userRole !== "teacher" && item.passed ? item.attendance : true
      }
      userRole={userRole}
      passed={item.passed}
      onClick={() => onItemClick(item)}
    />
  )
);

const renderBlocks = (blocks, Component, onItemClick, userRole) =>
  blocks.map((block, blockIndex) =>
    block.map((item, itemIndex) => (
      <RecordItem
        key={`${blockIndex}-${itemIndex}`}
        item={item}
        index={itemIndex}
        blockIndex={blockIndex}
        Component={Component}
        onItemClick={onItemClick}
        userRole={userRole}
      />
    ))
  );

const RecordSession = ({
  items,
  date,
  typeSession,
  handleItemClick,
  userRole,
}) => {
  const isWeekSession = typeSession === "week";
  const Component = isWeekSession ? RecordLessonWeek : RecordLesson;
  const sessionDate = isWeekSession ? date : new Date(date);
  const blocks = distributeColumns(items, sessionDate);

  return (
    <div className={isWeekSession ? "schedule-grid-week" : "schedule-grid"}>
      {renderBlocks(blocks, Component, handleItemClick, userRole)}
    </div>
  );
};

export default RecordSession;
