import React, { useState, useRef, useEffect } from "react";
import "./popupMenu.sass";
import { CSSTransition } from "react-transition-group";

const PopupMenu = ({
  onMouseLeave,
  inOpen,
  menuItems,
  onMenuItemClick,
  styleMenu,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const menuRef = useRef(null); // Создаем ref для ul элемента
  const [menuStyle, setMenuStyle] = useState({});

  const handleItemClick = (item) => {
    setSelectedItem(item); // Обновляем стейт выбранного элемента
    if (onMenuItemClick) {
      onMenuItemClick(item); // Вызываем колбэк с данными выбранного элемента
    }
  };

  useEffect(() => {
    if (inOpen && menuRef.current) {
      const menuBounds = menuRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const spaceBelow = viewportHeight - menuBounds.bottom;
      const spaceAbove = menuBounds.top;

      if (spaceBelow < menuBounds.height && spaceAbove > menuBounds.height) {
        // Если снизу не хватает места, но сверху достаточно, показываем меню сверху
        setMenuStyle({
          bottom: "0px", // Примерное значение, нужно подстроить под вашу верстку
        });
      } else {
        // В противном случае показываем меню снизу или оставляем его текущее положение
        setMenuStyle({});
      }
    }
  }, [inOpen]); // Зависимость от inOpen гарантирует перерасчет при каждом открытии меню

  return (
    <CSSTransition
      in={inOpen}
      timeout={150}
      classNames="menu"
      unmountOnExit
      nodeRef={menuRef}
    >
      <ul
        className={`menu ${styleMenu}`}
        onMouseLeave={onMouseLeave}
        ref={menuRef}
        style={menuStyle}
      >
        {" "}
        {menuItems.map((item, index) => (
          <li
            key={index}
            className={`menu__menu-item ${
              selectedItem === item ? "selected" : ""
            }`}
            onClick={() => handleItemClick(item)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </CSSTransition>
  );
};

export default PopupMenu;
