import "./login.sass";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Input from "../../components/input/Input";
import { account, databases } from "../../lib/appwrite";
import Button from "../../components/button/Button";
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Snackbar,
  Stack,
} from "@mui/material";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageInfo, setErrorMessageInfo] = useState(false);
  const [errorMessageInfoMessage, setErrorMessageInfoMessage] = useState("");
  const [successfulLogin, setSuccessfulLogin] = useState(false);

  const navigate = useNavigate();
  const { dispatch, currentUser, logout } = useContext(AuthContext);

  useEffect(() => {
    document.title = "Авторизация";
  }, []);

  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const handleEmailKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("passwordInput").focus();
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      if (currentUser) {
        await logout();
      }

      const dataUser = await account.createEmailSession(email, password);

      const dataDB = await databases.getDocument(
        "65f9d6f4dbeeccbe7e74",
        "65f9d6fd218cc728529a",
        dataUser.userId
      );

      if (
        dataDB &&
        (dataDB.role === "admin" ||
          dataDB.role === "developer" ||
          dataDB.role === "teacher")
      ) {
        // Сохраняем роль в localStorage
        localStorage.setItem("role", dataDB.role);

        // Добавляем роль в payload для dispatch
        dispatch({
          type: "LOGIN",
          payload: { user: dataUser, role: dataDB.role },
        });

        // Перенаправление после успешного логина
        if (dataDB.role === "teacher") {
          setSuccessfulLogin(true);
          navigate("/teacher");
        } else {
          setSuccessfulLogin(true);
          navigate("/admin");
        }
      } else {
        console.error("Доступ запрещён или документ не существует.");
        setErrorMessage("Доступ запрещён или документ не существует.");
        setError(true);
      }
    } catch (error) {
      console.error(`Ошибка в процессе аутентификации: ${error.message}`);
      setErrorMessageInfo(true);
      setErrorMessageInfoMessage(error.message);

      if (error.code === "USER_NOT_FOUND") {
        setErrorMessage("Неверный логин или пароль.");
      } else if (error.code === "NETWORK_ERROR") {
        setErrorMessage("Проблема с подключением к интернету.");
      } else {
        setErrorMessage(
          "Произошла неизвестная ошибка. Пожалуйста, попробуйте снова."
        );
      }

      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-erp-system">
      <div className="logo-half">
        <img
          className="logo-half__logo-image"
          alt="Логотип ERP системы Голосовое"
        />
      </div>
      <div className="form-half">
        <form action="" className="form-half__form" autoComplete="on">
          <h4 className="form-half__title">Авторизация</h4>

          <Input
            title={"Почта"}
            autoComplete={"email"}
            className={"form-half__email-input"}
            id={"emailLoginInput"}
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={"Почта"}
            onKeyDown={handleEmailKeyDown}
          />

          <Input
            title={"Пароль"}
            className={"form-half__password-input"}
            id={"passwordInput"}
            type={"password"}
            autoComplete={"current-password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={"Пароль"}
          />

          <Button
            colorStyle={"blue"}
            className={"form-half__button-login"}
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? (
              <Stack sx={{ color: "#ffffff" }} spacing={2} direction="row">
                <CircularProgress color="inherit" size={16} thickness={5} />
              </Stack>
            ) : (
              "Вход"
            )}
          </Button>
        </form>

        {error && (
          <div className="form-half__error-message">{errorMessage}</div>
        )}
        {!error && <div className="form-half__error-message-hidden">.</div>}

        <img
          className="form-half__logo-image"
          alt="Логотип ERP системы Голосовое"
        />
      </div>

      <Snackbar
        open={errorMessageInfo}
        autoHideDuration={6000}
        onClose={() => setErrorMessageInfo(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrorMessageInfo(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <AlertTitle>Ошибка</AlertTitle>
          {errorMessageInfoMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successfulLogin}
        autoHideDuration={6000}
        onClose={() => setSuccessfulLogin(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessfulLogin(false)}
          severity="succes"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <AlertTitle>Успешно!</AlertTitle>С возвращением!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
