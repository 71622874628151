import "./tasksMini.sass";

const TasksMini = ({ className, colorLine, title, children }) => {
  return (
    <div className={`container-tasks-mini ${className}`}>
      <div
        className={`container-tasks-mini__toolbar-container toolbar-line-${colorLine}`}
      >
        <h4 className="container-tasks-mini__toolbar-title">{title}</h4>
      </div>
      <div className="container-tasks-mini__tasks-list">{children}</div>
    </div>
  );
};

export default TasksMini;
