import ViewingLesson from '../viewingLesson/ViewingLesson'
import ViewingLessonGroup from '../viewingLesson/ViewingLessonGroup'
import './transitionToLesson.sass'
 
const TransitionToLesson = ({data, setViewOpen}) => {

    if(data.type_lesson === "group" || data.type_lesson === "trial"){
        return(
            <ViewingLessonGroup dataLesson={data} setViewOpen={setViewOpen}/>
        )
    }else{
        return(
            <ViewingLesson dataLesson={data} setViewOpen={setViewOpen}/>
        )
    }
    
}

export default TransitionToLesson