import { useEffect, useState } from "react";
import Button from "../button/Button";
import Input from "../input/Input";
import "./settingsMarketingChannels.sass";
import { databases } from "../../lib/appwrite";
import { ID, Query } from "appwrite";

import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";

const FieldChannelMarketing = ({ channelData, onDelete, onSave }) => {
  const [isChannel, setChannel] = useState(
    channelData ? channelData.marketing_channel : ""
  );

  const handleDelete = () => {
    onDelete(channelData);
  };

  const handleSave = () => {
    onSave({ ...channelData, marketing_channel: isChannel });
  };

  return (
    <div className="field-channel-marketing">
      <Input
        titleVisible={false}
        className={`field-channel-marketing__input-channel`}
        id={`marketingChannels_${Math.random().toString(16).slice(2)}`}
        type={"text"}
        value={isChannel || ""}
        onChange={(e) => setChannel(e.target.value)}
        placeholder={"Новый канал"}
      />
      <Button colorStyle={"red-inverse icons"} onClick={handleDelete}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          className="icon-center"
        >
          <path d="M480-424 364-308q-11 11-28 11t-28-11q-11-11-11-28t11-28l116-116-116-115q-11-11-11-28t11-28q11-11 28-11t28 11l116 116 115-116q11-11 28-11t28 11q12 12 12 28.5T651-595L535-480l116 116q11 11 11 28t-11 28q-12 12-28.5 12T595-308L480-424Z" />
        </svg>
      </Button>

      <Button
        colorStyle={"green-inverse icons"}
        onClick={handleSave}
        disabledIcon={
          channelData
            ? channelData.marketing_channel === isChannel || !isChannel
            : !isChannel
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          className="icon-center"
        >
          <path d="m382-354 339-339q12-12 28-12t28 12q12 12 12 28.5T777-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z" />
        </svg>
      </Button>
    </div>
  );
};

const SettingsMarketingChannels = ({ modalOpen }) => {
  const [isChannelsData, setChannelsData] = useState([]);
  const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isTemporaryStorage, setTemporaryStorage] = useState("");
  const [isLoading, setLoading] = useState(false);

  // Загрузка данных
  const gettingData = async () => {
    setLoading(true);
    try {
      const dataChannels = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "6648cd930032312f629d",
        [Query.equal("city", "voronezh")]
      );
      if (dataChannels.documents.length > 0) {
        setChannelsData(dataChannels.documents);
      } else {
        addNewChannel();
      }
    } catch (err) {
      console.error(
        "Произошла ошибка при получении данных о каналах маркетинга:",
        err
      );
    } finally {
      setLoading(false);
    }
  };

  // Вызов загрузки данных
  useEffect(() => {
    if (modalOpen) {
      gettingData();
    }
  }, [modalOpen]);

  // Добавление поля для нового канала
  const addNewChannel = () => {
    setChannelsData([
      ...isChannelsData,
      { $id: `new-${Date.now()}`, new: true },
    ]);
  };

  // Удаление пустого или подтверждение удаления
  const checkingBeforeDeleting = (channel) => {
    if (channel.new) {
      setChannelsData(isChannelsData.filter((ch) => ch.$id !== channel.$id));
    } else {
      setTemporaryStorage(channel);
      handleOpenDialogDeleteLesson();
    }
  };

  // Удаление канал
  const deleteChannel = async () => {
    handleCloseDialogDeleteLesson();
    if (isTemporaryStorage) {
      try {
        await databases.deleteDocument(
          "65f9d6f4dbeeccbe7e74",
          "6648cd930032312f629d",
          isTemporaryStorage.$id
        );
        setChannelsData(
          isChannelsData.filter((ch) => ch.$id !== isTemporaryStorage.$id)
        );
      } catch (err) {
        console.error("Произошла ошибка при удалении канала маркетинга:", err);
      } finally {
        setTemporaryStorage("");
      }
    }
  };

  // Сохранение/Обновление канала
  const saveChannel = async (channel) => {
    if (channel.new) {
      if (channel.marketing_channel) {
        try {
          const newChannel = await databases.createDocument(
            "65f9d6f4dbeeccbe7e74",
            "6648cd930032312f629d",
            ID.unique(),
            {
              marketing_channel: channel.marketing_channel,
              city: "voronezh",
              number_of_users: 0,
            }
          );
          setChannelsData(
            isChannelsData.map((ch) =>
              ch.$id === channel.$id ? newChannel : ch
            )
          );
        } catch (err) {
          console.error(
            "Произошла ошибка при добавлении нового канала маркетинга:",
            err
          );
        }
      }
    } else {
      try {
        await databases.updateDocument(
          "65f9d6f4dbeeccbe7e74",
          "6648cd930032312f629d",
          channel.$id,
          { marketing_channel: channel.marketing_channel }
        );
        setChannelsData(
          isChannelsData.map((ch) => (ch.$id === channel.$id ? channel : ch))
        );
      } catch (err) {
        console.error(
          "Произошла ошибка при обновлении канала маркетинга:",
          err
        );
      }
    }
  };

  // Открытие окна удаления
  const handleOpenDialogDeleteLesson = () => {
    setOpenDeleteDialog(true);
  };

  // Закрытие окна удаления
  const handleCloseDialogDeleteLesson = () => {
    setOpenDeleteDialog(false);
    setTimeout(() => {
      setTemporaryStorage("");
    }, 300);
  };

  return (
    <div className="blackout-modal">
      <div className="setting-marketing-channels">
        <h2>Каналы маркетинга</h2>

        {isChannelsData.map((channel) => (
          <FieldChannelMarketing
            key={channel.$id}
            channelData={channel}
            onDelete={checkingBeforeDeleting}
            onSave={saveChannel}
          />
        ))}

        <Button colorStyle={"blue-inverse"} onClick={addNewChannel}>
          Новый канал
        </Button>
        {isLoading && (
          <div className="loading-container-channels">
            <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
              <CircularProgress color="inherit" />
            </Stack>
          </div>
        )}
      </div>
      <div
        className="blackout-modal-click-close"
        onClick={() => modalOpen(false)}
      ></div>
      <Dialog
        open={isOpenDeleteDialog}
        onClose={handleCloseDialogDeleteLesson}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Удалить канал "${isTemporaryStorage.marketing_channel}"?`}
        </DialogTitle>

        <DialogActions>
          <Button
            colorStyle={"invisible"}
            onClick={handleCloseDialogDeleteLesson}
          >
            Отмена
          </Button>
          <Button colorStyle={"red"} onClick={() => deleteChannel()} autoFocus>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SettingsMarketingChannels;
