import "./timeGrid.sass";

// Компонент для отображения одного временного интервала
const TimeSlot = ({ time }) => (
  <div className="time-slot">
    <span className="time-text">{time}</span>
    <div className="time-line"></div>
  </div>
);

const TimeGrid = () => {
  const times = [];
  for (let hour = 10; hour <= 22; hour++) {
    times.push(`${hour}:00`);
  }

  return (
    <div className="time-grid">
      {times.map((time) => (
        <TimeSlot key={time} time={time} />
      ))}
    </div>
  );
};


export default TimeGrid;

