import "./homeInfoComponents.sass";

const WorkerRating = () => {
  return (
    <div className="data-main-container worker-rating">
      <div className="worker-rating__toolbar">
        <h4>Рейтинг</h4>

      </div>
      <div className="worker-rating__content"></div>
      <div className="worker-rating__hide">Рейтинг в разработке</div>
    </div>
  );
};

export default WorkerRating;
