import { useState } from "react";
import { ID, Query } from "appwrite";
import { databases } from "../lib/appwrite";
import recordingAnOperation from "./recordingAnOperation";

// Константы для типов оплаты
const PAYMENT_NONE = "none";
const PAYMENT_POINTS = "points";
const PAYMENT_ACCOUNT = "account";
const PAYMENT_TRIAL = "trial";
const PAYMENT_CERTIFICATE = "certificate";
const PAYMENT_FREE_CLASSES = "freeClasses";

// Проверка на дублирование информации
const checkingForDuplicateInformation = async (lessonID, studentID) => {
  try {
    const response = await databases.listDocuments(
      "65f9d6f4dbeeccbe7e74",
      "661cdbdec056444bc33f",
      [Query.equal("id_lesson", lessonID), Query.equal("id_student", studentID)]
    );

    //console.log("Поиск абонемента на дублирование", lessonID, studentID);

    // Проверяем количество найденных документов
    if (response.total > 0) {
      //console.log("Дубликат найден");
      return true; // Возвращаем true, если найден дубликат
    } else {
      //console.log("Дубликат не найден");
      return false; // Возвращаем false, если дубликат не найден
    }
  } catch (error) {
    console.error("Ошибка при поиске в базе данных:", error);
  }
};

// Проверка абонементов
async function findValidPass(userId) {
  //console.log("Search subscription...");
  try {
    let response = await databases.listDocuments(
      "65f9d6f4dbeeccbe7e74",
      "65fef6b362018b62c8c6",
      [Query.equal("user_id", userId), Query.notEqual("remains_lessons", 0)],
      1,
      0,
      "end_date"
    );

    if (response && response.documents) {
      if (response.documents.length === 0) {
        //console.log("Нет абонемента");
        return null;
      } else {
        //console.log(response.documents[0]);
        return response.documents[0];
      }
    } else {
      return null;
    }

    // Возвращаем документ с наименьшей датой окончания и оставшимися занятиями
  } catch (error) {
    console.error(`Ошибка при поиске абонемента: ${error}`);
  }
}

// Списание баллов
const deductionOfPoints = async (data, student) => {
  try {
    await databases.updateDocument(
      "65f9d6f4dbeeccbe7e74",
      "65f9d6fd218cc728529a",
      data.id_student,
      {
        points: student.points - data.payment_amount,
      }
    );
    //console.log(`Списано ${data.payment_amount} баллов`);
  } catch (error) {
    console.error("Ошибка при списании баллов", error);
  }
};

// Списание бесплатных уроков
const cancellationOfFreeLessons = async (data, student) => {
  try {
    await databases.updateDocument(
      "65f9d6f4dbeeccbe7e74",
      "65f9d6fd218cc728529a",
      data.id_student,
      {
        free_classes: student.free_classes - 1,
      }
    );
    //console.log(`Списан 1 бесплатный урок`);
  } catch (error) {
    console.error("Ошибка при списании урока", error);
  }
};

// Списание средств с абонемента
const paymentFromASubscription = async (subscription) => {
  const pricePerLesson = subscription.price / subscription.number_of_lessons;
  const totalAmount =
    pricePerLesson > subscription.remains_balance
      ? subscription.remains_balance
      : pricePerLesson;

  try {
    await databases.updateDocument(
      "65f9d6f4dbeeccbe7e74",
      "65fef6b362018b62c8c6",
      subscription.$id,
      {
        remains_lessons: subscription.remains_lessons - 1,
        remains_balance: Math.floor(
          Number(subscription.remains_balance) - totalAmount
        ),
      }
    );

    //console.log(`Списание средств прошло успешно (${totalAmount})`);
  } catch (error) {
    console.error("Ошибка при снятии средств", error);
  }
};

// Обновление статуса урока
const updatingLessonAttendance = async (lessonID) => {
  try {
    await databases.updateDocument(
      "65f9d6f4dbeeccbe7e74",
      "66143d053c25fc1c3d4d",
      lessonID,
      {
        attendance: true,
      }
    );
    //console.log("Статус урока обновлен");
  } catch (error) {
    console.error("Ошибка обновления статуса урока", error);
  }
};

// Обновление статуса последней активности ученика
const updatingStudentLastActivity = async (studentID, lessonDate) => {
  try {
    await databases.updateDocument(
      "65f9d6f4dbeeccbe7e74",
      "65f9d6fd218cc728529a",
      studentID,
      {
        last_activity: new Date(lessonDate).toISOString(),
      }
    );
    //console.log("Статус последней активности обновлен");
  } catch (error) {
    console.error("Ошибка обновления статуса ученика", error);
  }
};

// Утилита для создания документа в базе данных
async function createAttendanceRecord(state, subscription) {
  return await databases.createDocument(
    "65f9d6f4dbeeccbe7e74",
    "661cdbdec056444bc33f",
    ID.unique(),
    {
      ...state,
      transaction_date: new Date().toISOString(),
      id_subscription: subscription,
    }
  );
}
const trialLessonHasBeenAttended = async (studentID) => {
  try {
    await databases.updateDocument(
      "65f9d6f4dbeeccbe7e74",
      "65f9d6fd218cc728529a",
      studentID,
      {
        trial_lesson_available: false,
      }
    );
    //console.log("Статус последней активности обновлен");
  } catch (error) {
    console.error("Ошибка обновления статуса пробного урока у ученика", error);
  }
};

// Обновление посещаемости в базе данных
async function updateAttendanceInDatabase(
  state,
  infoStudent,
  payment,
  subscription
) {
  try {
    const response = await createAttendanceRecord(state, subscription.$id);
    //console.log("Документ создан:", response);
    console.log(state);
    if (state.module && state.lesson && state.attendance === "present") {
      setNewProgressStudent(infoStudent, state.module, state.lesson);
    }
    switch (payment) {
      case PAYMENT_POINTS:
        await deductionOfPoints(state, infoStudent);
        break;

      case PAYMENT_ACCOUNT:
        await paymentFromASubscription(subscription);
        break;

      case PAYMENT_FREE_CLASSES:
        await cancellationOfFreeLessons(state, infoStudent);
        break;

      case PAYMENT_TRIAL:
        await trialLessonHasBeenAttended(infoStudent.$id);
        break;

      case PAYMENT_NONE:
        break;

      default:
        //console.log("Без оплаты");
        break;
    }
  } catch (error) {
    console.error("Ошибка создания документа:", error);
  }
}

// Добавление модуля и урока в аккаунт
const setNewProgressStudent = async (student, module, lesson) => {
  //console.log(student.progress);
  student.progress.push(`${module}|${lesson}`);
  //console.log("Новый прогресс", student.progress);
  try {
    await databases.updateDocument(
      "65f9d6f4dbeeccbe7e74",
      "65f9d6fd218cc728529a",
      student.$id,
      {
        progress: student.progress,
      }
    );
    //console.log(`Прогресс добавлен`);
  } catch (error) {
    console.error("Ошибка при добавлении прогресса", error);
  }
};

async function providerAttendanceCheck(
  dataLesson,
  dataStudent,
  changeOfStatus,
  dateOperation
) {
  //console.log("Ученик для операций", dataStudent, dataLesson)
  // Проверка на дублирование
  const isDuplicate = await checkingForDuplicateInformation(
    dataLesson.id_lesson,
    dataStudent.$id
  );
  if (isDuplicate) {
    // Если найден дубликат, возвращаем статус ошибки и прекращаем выполнение
    return {
      newStatus: "error duplicate",
      lessonCost: null,
    };
  }

  // Обновление посещаемости и последней активности студента
  const updateAttendanceAndActivity = async (status, cost = null) => {
    !status.startsWith("error") &&
      updatingStudentLastActivity(dataStudent.$id, dateOperation);
    if (changeOfStatus) {
      await updatingLessonAttendance(dataLesson.id_lesson);
    }
    return {
      newStatus: status,
      lessonCost: cost,
    };
  };

  if (dataLesson.attendance === "respectful") {
    await updateAttendanceInDatabase(dataLesson, dataStudent, PAYMENT_NONE, "");
    return updateAttendanceAndActivity("completed");
  }

  switch (dataLesson.payment_method) {
    case PAYMENT_POINTS:
      if (dataLesson.payment_amount > dataStudent.points) {
        return updateAttendanceAndActivity("error points");
      } else {
        await updateAttendanceInDatabase(
          dataLesson,
          dataStudent,
          PAYMENT_POINTS,
          ""
        );
        return updateAttendanceAndActivity("completed");
      }

    case PAYMENT_ACCOUNT:
      if (dataStudent.free_classes > 0) {
        await updateAttendanceInDatabase(
          dataLesson,
          dataStudent,
          PAYMENT_FREE_CLASSES,
          ""
        );
        return updateAttendanceAndActivity("completed");
      } else {
        const subscriptionData = await findValidPass(dataStudent.$id);
        if (!subscriptionData) {
          return updateAttendanceAndActivity("error balance");
        } else {
          const pricePerLesson =
            subscriptionData.price / subscriptionData.number_of_lessons;
          const totalAmount =
            pricePerLesson > subscriptionData.remains_balance
              ? subscriptionData.remains_balance
              : pricePerLesson;
          await updateAttendanceInDatabase(
            dataLesson,
            dataStudent,
            PAYMENT_ACCOUNT,
            subscriptionData
          );
          //console.log("Сумма которая должна пойти в реализацию", totalAmount);
          return updateAttendanceAndActivity("completed", totalAmount);
        }
      }

    case PAYMENT_CERTIFICATE:
      await updateAttendanceInDatabase(
        dataLesson,
        dataStudent,
        PAYMENT_NONE,
        ""
      );
      return updateAttendanceAndActivity("completed");

    case PAYMENT_TRIAL:
      if (dataLesson.attendance !== "absent") {
        await updateAttendanceInDatabase(
          dataLesson,
          dataStudent,
          PAYMENT_TRIAL,
          ""
        );
      } else {
        await updateAttendanceInDatabase(
          dataLesson,
          dataStudent,
          PAYMENT_NONE,
          ""
        );
      }
      return updateAttendanceAndActivity("completed");

    default:
      return updateAttendanceAndActivity("error unknown");
  }
}

function useRecordAttendance() {
  const { statusOperation, updateOperationInfo } = recordingAnOperation();
  const [attendanceState, setAttendanceState] = useState({
    status: "idle",
    realizationCosts: [],
    studentErrors: [], // Теперь будет содержать объекты с ID ученика и типом ошибки
    isStatisticUpdate: false,
    allStudentsProcessed: false,
  });

  const markAttendance = async (
    lessonsData,
    studentsData,
    changeOfStatus,
    dateOperation
  ) => {
    setAttendanceState((prevState) => ({ ...prevState, status: "loading" }));
    let allStudentsValid = true;
    const studentErrors = []; // Для хранения информации об ошибках

    for (let i = 0; i < studentsData.length; i++) {
      const studentData = studentsData[i];
      const lessonData = lessonsData.find(
        (lesson) => lesson.id_student === studentData.$id
      );
      //console.log("Студент: ", studentData, "Урок: ", lessonData, "Уроки: ", lessonsData);

      if (!lessonData) {
        console.error(
          `Данные урока не найдены для учащегося с ID: ${studentData.$id}`
        );
        studentErrors.push({
          studentId: `${studentData.name} ${studentData.sur_name.charAt(0)}.`,
          error: "Данные урока не найдены",
        });
        continue;
      }

      try {
        const result = await providerAttendanceCheck(
          lessonData,
          studentData,
          false,
          dateOperation
        );

        //console.log(studentData);

        if (
          result.newStatus.startsWith("error") &&
          result.newStatus !== "error duplicate"
        ) {
          allStudentsValid = false;
          studentErrors.push({
            studentId: `${studentData.name} ${studentData.sur_name.charAt(0)}.`,
            error: result.newStatus,
          }); // Сохранение типа ошибки
        }

        // Если операция прошла успешно и есть стоимость урока, обновляем статистику
        if (result.newStatus === "completed" && Number(result.lessonCost) > 0) {
          updateOperationInfo("realization", Math.floor(result.lessonCost));
        }
      } catch (error) {
        console.error("Произошла ошибка", error);
        allStudentsValid = false;
        studentErrors.push({
          studentId: `${studentData.name} ${studentData.sur_name.charAt(0)}.`,
          error: "error unknown",
        }); // Сохранение неизвестной ошибки
      }
    }

    // Если все ученики, кроме тех, у кого ошибка duplicate, обработаны успешно
    if (allStudentsValid && changeOfStatus) {
      for (const lessonData of lessonsData) {
        await updatingLessonAttendance(lessonData.id_lesson); // Updating the lesson status
      }
    }

    setAttendanceState({
      status: allStudentsValid ? "completed" : "error",
      //realizationCosts: results.map((r) => r.realizationCost),
      studentErrors: studentErrors, // Обновление состояния с информацией об ошибках
      isStatisticUpdate: attendanceState.isStatisticUpdate,
      allStudentsProcessed: true,
    });
  };

  return {
    ...attendanceState,
    markAttendance,
  };
}

export default useRecordAttendance;
