import "./students.sass";
import React, { useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import Sidebar from "../../components/sidebar/Sidebar";
import Button from "../../components/button/Button";
import AddNewStudent from "../../components/addNewStudent/AddNewStudent";
import SearchInput from "../../components/searchInput/SearchInput";
import IconButton from "../../components/iconButton/IconButton";
import StudentsList from "../../components/studentsList/StudentsList";
import PopupMenu from "../../components/popupMenu/PopupMenu";
import SettingsMarketingChannels from "../../components/settingsMarketingChannels/SettingsMarketingChannels";

const Students = ({ setModalOpen }) => {
  // Состояния
  const toolbarRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isModalOpen, setIsModalOpen = setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [listUpdate, setListUpdate] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpenMarketigChannels, setOpenMarketigChannels] = useState(false);

  // Анимация у Toolbar
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (toolbarRef.current) {
        const { top } = toolbarRef.current.getBoundingClientRect();
        setIsSticky(top <= 0);
      }
    }, 100);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.title = "Ученики";
  }, []);

  // Открытие модального окна
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  // Поиск по ученикам
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Вызов метода обновления списка
  useEffect(() => {
    setListUpdate((listUpdate) => listUpdate + 1);
  }, []);

  useEffect(() => {
    const toggleBodyOverflow = (value) => {
      document.body.style.overflow = value;
    };

    if (isModalOpen) {
      toggleBodyOverflow("hidden");
    } else {
      toggleBodyOverflow("visible");
    }

    if (isOpenMarketigChannels) {
      toggleBodyOverflow("hidden");
    } else {
      toggleBodyOverflow("visible");
    }

    return () => toggleBodyOverflow("visible");
  }, [isModalOpen, isOpenMarketigChannels]);

  // Закрытие меню
  const handlePopupMenuClose = () => {
    setIsMenuOpen(false);
  };

  // Открытие меню
  const handlePopupMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuItemClick = (item) => {
    switch (item.key) {
      case "settingsChannels":
        setOpenMarketigChannels(true);
        break;
    }
  };

  return (
    <div className="students desktop-version">
      <Sidebar />
      <div className="main-container-pages">
        <div className="main-container-pages__container">
          <div className="main-container-pages__texts">
            <h2 className="main-container-pages__title">Ученики</h2>
            <h3 className="main-container-pages__city">Белгород</h3>
          </div>

          <div className="main-container-pages__container-data students-container">
            <div
              className="toolbar-students-full"
              ref={toolbarRef}
              style={{
                borderRadius: isSticky ? "0" : "12px 12px 0 0",
              }}
            >
              <div className="toolbar-students-full__top-toolbar">
                <SearchInput
                  className={"toolbar-students-full__search-input"}
                  holderText={"Поиск ученика..."}
                  onChange={handleSearch}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M440-560q33 0 56.5-23.5T520-640q0-33-23.5-56.5T440-720q-33 0-56.5 23.5T360-640q0 33 23.5 56.5T440-560Zm0 160q45 0 84.5-19t68.5-54q-35-23-73.5-35T440-520q-41 0-79.5 12T287-473q29 35 68.5 54t84.5 19Zm0 160q-134 0-227-93t-93-227q0-134 93-227t227-93q134 0 227 93t93 227q0 56-18 105.5T692-364l160 160q11 11 11 28t-11 28q-11 11-28 11t-28-11L636-308q-41 32-90.5 50T440-240Zm0-80q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Zm0-240Z" />
                    </svg>
                  }
                />
                <div className="toolbar-students-full__container-activity">
                  <Button
                    className={"toolbar-students-full__add-new-student"}
                    colorStyle={"blue"}
                    onClick={handleModalOpen}
                  >
                    Добавить ученика
                  </Button>
                  <IconButton
                    className={"toolbar-students-full__button-update-data"}
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-70q0-17 11.5-28.5T760-800q17 0 28.5 11.5T800-760v200q0 17-11.5 28.5T760-520H560q-17 0-28.5-11.5T520-560q0-17 11.5-28.5T560-600h128q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q68 0 124.5-34.5T692-367q8-14 22.5-19.5t29.5-.5q16 5 23 21t-1 30q-41 80-117 128t-169 48Z" />
                      </svg>
                    }
                    onClick={() =>
                      setListUpdate((listUpdate) => listUpdate + 1)
                    }
                  />

                  <IconButton
                    className={"toolbar-students-full__button-more"}
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
                      </svg>
                    }
                    onClick={handlePopupMenuOpen}
                  />

                  <PopupMenu
                    onMouseLeave={handlePopupMenuClose}
                    inOpen={isMenuOpen}
                    menuItems={[
                      { key: "settingsChannels", label: "Каналы маркетинга" },
                    ]}
                    onMenuItemClick={handleMenuItemClick}
                    styleMenu={"menu-toolbar-students"}
                  />
                </div>
              </div>
              <div className="toolbar-students-full__bottom-toolbar">
                <h4 className="toolbar-students-full__text-start">Ученик</h4>
                <h4 className="toolbar-students-full__text-center">
                  Номер телефона
                </h4>
                <h4 className="toolbar-students-full__text-center">Баланс</h4>
                <h4 className="toolbar-students-full__text-center">
                  Последний визит
                </h4>
                <h4 className="toolbar-students-full__text-last">Статус</h4>
              </div>
            </div>
            <StudentsList searchTerm={searchTerm} listUpdate={listUpdate} />
          </div>
        </div>
      </div>
      {isModalOpen && <AddNewStudent onModalOpen={setIsModalOpen} />}

      {isOpenMarketigChannels && (
        <SettingsMarketingChannels modalOpen={setOpenMarketigChannels} />
      )}
    </div>
  );
};

export default Students;
