import { useEffect, useRef, useState } from "react";
import "./recordLesson.sass";
import ErrorMessage from "../errorMessage/ErrorMessage";
import { databases } from "../../lib/appwrite";

// Отображение учеников
function StudentsList({ students }) {
  if (students.length === 0) {
    return (
      <div className={`record-lesson__students-list students-list-record`}>
        <h5 className={`students-list-record__item`}>Ученики не указаны</h5>
      </div>
    );
  } else {
    return (
      <div className={`record-lesson__students-list students-list-record`}>
        {students.map((student, index) => (
          <h5 key={index} className={`students-list-record__item`}>
            {student}
          </h5>
        ))}
      </div>
    );
  }
}

// Выбор иконки в зависимости от типа занятия
function IconTypeLesson({ type }) {
  switch (type) {
    case "indiv":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          viewBox="0 -960 960 960"
          width="20"
        >
          <path d="M480-480q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42ZM192-264v-24q0-23 12.5-43.5T239-366q55-32 116.5-49T480-432q63 0 124.5 17T721-366q22 13 34.5 34t12.5 44v24q0 30-21 51t-51 21H264q-30 0-51-21t-21-51Z" />
        </svg>
      );
    case "group":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          viewBox="0 -960 960 960"
          width="20"
        >
          <path d="M84-264q-15 0-25.5-10.5T48-300v-21q0-39 39-63t105-24q14 0 26 1t23 3q-12 18-18.5 39.113Q216-343.775 216-322v58H84Zm216 0q-15 0-25.5-10.5T264-300v-22q0-28 14.5-50t43.5-39q29-17 69-25t89.495-8q49.505 0 89.005 8 39.5 8 68.5 25 29 16 43.5 38.688Q696-349.624 696-322v22q0 15-10.5 25.5T660-264H300Zm444 0v-58q0-22-6.5-42.5T719-404q9-2 20.5-3t28.5-1q66 0 105 24t39 63v21q0 15-10.5 25.5T876-264H744ZM192-456q-30 0-51-21t-21-51q0-30 21-51t51-21q30 0 51 21t21 51q0 30-21 51t-51 21Zm576 0q-30 0-51-21t-21-51q0-30 21-51t51-21q30 0 51 21t21 51q0 30-21 51t-51 21Zm-288-36q-45 0-76.5-31.521-31.5-31.52-31.5-76.549 0-44.93 31.52-76.43 31.521-31.5 76.55-31.5 44.93 0 76.43 31.55Q588-644.9 588-600q0 45-31.55 76.5T480-492Z" />
        </svg>
      );
    case "acter":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          viewBox="0 -960 960 960"
          width="20"
        >
          <path d="M288-96q-100 0-170-70T48-336v-216q0-29.7 21.15-50.85Q90.3-624 120-624h336q29.7 0 50.85 21.15Q528-581.7 528-552v216q0 100-70 170T288-96Zm-72.211-324Q231-420 241.5-430.289q10.5-10.29 10.5-25.5Q252-471 241.711-481.5q-10.29-10.5-25.5-10.5Q201-492 190.5-481.711q-10.5 10.29-10.5 25.5Q180-441 190.289-430.5q10.29 10.5 25.5 10.5Zm144 0Q375-420 385.5-430.289q10.5-10.29 10.5-25.5Q396-471 385.711-481.5q-10.29-10.5-25.5-10.5Q345-492 334.5-481.711q-10.5 10.29-10.5 25.5Q324-441 334.289-430.5q10.29 10.5 25.5 10.5ZM912-792v216q0 100-70 170t-170 70q-13.629 0-26.879-1.5Q631.871-339 619-342q-19-5-31-19.5T576-396v-168q0-45-31.5-75.5T468-670q-15 0-25.5-10.925T432-708v-84q0-29.7 21.15-50.85Q474.3-864 504-864h336q29.7 0 50.85 21.15Q912-821.7 912-792ZM599.789-660Q615-660 625.5-670.289q10.5-10.29 10.5-25.5Q636-711 625.711-721.5q-10.29-10.5-25.5-10.5Q585-732 574.5-721.711q-10.5 10.29-10.5 25.5Q564-681 574.289-670.5q10.29 10.5 25.5 10.5Zm144 0Q759-660 769.5-670.289q10.5-10.29 10.5-25.5Q780-711 769.711-721.5q-10.29-10.5-25.5-10.5Q729-732 718.5-721.711q-10.5 10.29-10.5 25.5Q708-681 718.289-670.5q10.29 10.5 25.5 10.5Zm-70.654 84q-25.865 0-49.5 9.5T585-536q-8 10 0 21t25 11h127q15 0 22.5-11t-.5-21q-15-20-37.5-30t-48.365-10ZM288.849-264Q315-264 337.5-274q22.5-10 37.5-30 8-10 .5-21T353-336H226q-16.5 0-24.75 11t-.25 21q15 21 38.349 30.5 23.349 9.5 49.5 9.5Z" />
        </svg>
      );
    case "trial":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
        >
          <path d="M479.79-96Q450-96 429-117.15T408-168h144q0 30-21.21 51t-51 21ZM372.28-216q-15.28 0-25.78-10.29-10.5-10.29-10.5-25.5t10.34-25.71q10.34-10.5 25.62-10.5h215.76q15.28 0 25.78 10.29 10.5 10.29 10.5 25.5t-10.34 25.71Q603.32-216 588.04-216H372.28ZM321-336q-62-38-95.5-102.5T192-576q0-120 84-204t204-84q120 0 204 84t84 204q0 73-33.5 137.5T639-336H321Z" />
        </svg>
      );
  }
}

async function getUserData(user) {
  try {
    const data = await databases.getDocument(
      "65f9d6f4dbeeccbe7e74",
      "65f9d6fd218cc728529a",
      user
    );

    const userName = `${data.name} ${data.sur_name}`;
    return userName;
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
}

const RecordLesson = ({
  id,
  className,
  style,
  attendance,
  onClick,
  userData,
  userRole,
}) => {
  // Определение типа занятия
  let type;
  switch (userData.type_lesson) {
    case "indiv":
      type = "lesson-indiv";
      break;
    case "group":
      type = "lesson-group";
      break;
    case "acter":
      type = "lesson-acter";
      break;
    case "trial":
      type = "lesson-trial";
      break;
  }
  const [teacherName, setTeacherName] = useState("Загрузка...");
  const [studentsData, setStudentsData] = useState(["Загрузка..."]);
  const [teacherError, setTeacherError] = useState(null);
  const [studentsError, setStudentsError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        // Обработка случая, когда учитель не указан
        const teacherData =
          userData.teacher === ""
            ? "Не указан"
            : await getUserData(userData.teacher);

        // Запрос данных студентов
        const studentsData = await Promise.all(
          userData.students.map((id) => getUserData(id))
        );

        setTeacherName(teacherData);
        setStudentsData(studentsData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setTeacherError("Ошибка загрузки данных учителя");
        setStudentsError("Ошибка загрузки данных студентов");
      }
    }

    if (userData && userData.teacher !== undefined && userData.students) {
      fetchData();
    } else {
      console.error("Invalid user data");
    }
  }, [userData, userData.teacher, userData.students]);

  return (
    <div
      className={`record-lesson ${type} ${
        userData.passed && `lesson-passed ${type}__passed`
      } ${className} ${
        userData.lessonIsNotAvailable ? "lesson-is-not-available-student" : ""
      }`}
      id={id}
      style={style}
      onClick={onClick}
    >
      <h5 className="record-lesson__time">
        {`${Math.floor(userData.time_start + 10)}:${String(
          Math.floor(((userData.time_start + 10) % 1) * 60)
        ).padStart(2, "0")} - ${Math.floor(
          userData.time_start + 10 + userData.duration
        )}:${String(
          Math.floor(((userData.time_start + 10 + userData.duration) % 1) * 60)
        ).padStart(2, "0")}`}
      </h5>

      <div className="record-lesson__teacher-container">
        <i className={`record-lesson__icon-teacher`}>
          <IconTypeLesson type={userData.type_lesson} />
        </i>
        {userRole !== "teacher" && (
          <h4 className={`record-lesson__name-teacher`}>{teacherName}</h4>
        )}
      </div>
      {type === "lesson-group" || type === "lesson-trial" ? (
        <div className="record-lesson__student-container students-more">
          <div className="record-lesson__title-students">
            <i className={`record-lesson__icon-student`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 -960 960 960"
                width="20"
              >
                <path d="M840-324v-240L512-400q-15.207 8-32.103 8Q463-392 448-400L112-568q-10-5-15-13.325t-5-18.5Q92-610 97-618.5t15-13.5l336-168q7.579-4 15.579-6T480-808q8.421 0 16.421 2T512-800l380 190q9 5 14.5 13t5.5 18.918V-324q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q861-288 850.5-298.35 840-308.7 840-324ZM448-160l-192-96q-18-9-29-26.417-11-17.416-11-37.583v-115l232 116q15.207 8 32.103 8Q497-311 512-319l232-116v115q0 20.167-11 37.583Q722-265 704-256l-192 96q-7.579 4-15.579 6T480-152q-8.421 0-16.421-2T448-160Z" />
              </svg>
            </i>
            <h4 className={`record-lesson__name-student`}>Ученики:</h4>
          </div>

          <StudentsList students={studentsData} />
        </div>
      ) : (
        <div className="record-lesson__student-container">
          <i className={`record-lesson__icon-student`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 -960 960 960"
              width="20"
            >
              <path d="M840-324v-240L512-400q-15.207 8-32.103 8Q463-392 448-400L112-568q-10-5-15-13.325t-5-18.5Q92-610 97-618.5t15-13.5l336-168q7.579-4 15.579-6T480-808q8.421 0 16.421 2T512-800l380 190q9 5 14.5 13t5.5 18.918V-324q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q861-288 850.5-298.35 840-308.7 840-324ZM448-160l-192-96q-18-9-29-26.417-11-17.416-11-37.583v-115l232 116q15.207 8 32.103 8Q497-311 512-319l232-116v115q0 20.167-11 37.583Q722-265 704-256l-192 96q-7.579 4-15.579 6T480-152q-8.421 0-16.421-2T448-160Z" />
            </svg>
          </i>
          <h4 className={`record-lesson__name-student`}>
            {studentsData[0] ? studentsData[0] : "Не указан"}
          </h4>
        </div>
      )}
      {!attendance && (
        <ErrorMessage messageText={"Укажите посещаемость учеников!"} />
      )}
    </div>
  );
};

export default RecordLesson;
