import "./statisticGraphic.sass";
import SalesLineChart from "../salesChart/SalesLineChart";
import DateSelector from "../dateSelector/DateSelector";
import React, { useState, useEffect, useCallback } from "react";
import { databases } from "../../lib/appwrite";
import { Query } from "appwrite";
import SalesBarChart from "../salesChart/SalesBarChart";
import { addDays, addSeconds } from "date-fns";
import SalesPieChart from "../salesChart/SalesPieChart";

function calculateTotalAmount(data, startDate, endDate, type) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (type === "numberOfStudents") {
    let latestDate = null;
    let latestAmount = 0;
    data.forEach((item) => {
      const itemDate = new Date(item.date);
      if (itemDate >= start && itemDate <= end) {
        if (!latestDate || itemDate > latestDate) {
          latestDate = itemDate;
          latestAmount = item.total_students; // предполагается что total_students это свойство объекта item
        }
      }
    });
    return latestAmount.toLocaleString("ru-RU");
  } else if (type === "indexOfGroups") {
    let sum = 0;
    let count = 0;
    data.forEach((item) => {
      const itemDate = new Date(item.date);
      if (itemDate >= start && itemDate <= end) {
        sum += item.index_of_groups;
        count++;
      }
    });
    const average = count > 0 ? sum / count : 0;
    return average.toLocaleString("ru-RU");
  } else {
    const totalAmount = data.reduce((total, item) => {
      const itemDate = new Date(item.date);
      if (itemDate >= start && itemDate <= end) {
        if (type === "salesVolume" || type === "realization") {
          return total + item.amount;
        }
      }
      return total;
    }, 0);

    return type === "salesVolume" || type === "realization"
      ? totalAmount.toLocaleString("ru-RU") + " ₽"
      : totalAmount.toLocaleString("ru-RU");
  }
}

function aggregateDataIndexOfGroups(data, type) {
  const result = {};
  const count = {};

  data.forEach((item) => {
    let key;
    if (type === "month") {
      key = item.date.slice(0, 7); // YYYY-MM
    } else if (type === "year") {
      key = item.date.slice(0, 4); // YYYY
    } else {
      key = item.date.slice(0, 10); // YYYY-MM-DD
    }

    if (result[key]) {
      result[key] += item.index_of_groups;
      count[key] += 1;
    } else {
      result[key] = item.index_of_groups;
      count[key] = 1;
    }
  });

  return Object.keys(result).map((key) => ({
    date: key,
    index_of_groups: result[key] / count[key], // Среднее значение
  }));
}

function aggregateData(data, type) {
  const result = {};

  data.forEach((item) => {
    let key;
    if (type === "month") {
      key = item.date.slice(0, 7); // YYYY-MM
    } else if (type === "year") {
      key = item.date.slice(0, 4); // YYYY-MM-DD
    } else {
      key = item.date.slice(0, 10); // YYYY-MM-DD
    }

    if (result[key]) {
      result[key] += item.amount;
    } else {
      result[key] = item.amount;
    }
  });

  return Object.keys(result).map((key) => ({
    date: key,
    amount: result[key],
  }));
}

function aggregateDataStudents(data, type) {
  const result = {};

  data.forEach((item) => {
    let key;
    if (type === "month") {
      key = item.date.slice(0, 7); // YYYY-MM
    } else if (type === "year") {
      key = item.date.slice(0, 4); // YYYY-MM-DD
    } else {
      key = item.date.slice(0, 10); // YYYY-MM-DD
    }

    // Если ключ еще не создан, инициализируем его начальными значениями
    if (!result[key]) {
      result[key] = {
        date: item.date, // сохраняем полную дату для возможного сравнения
        total_students: item.total_students,
        active_students: item.active_students,
      };
    } else {
      // Обновляем только если текущие значения больше или дата позже
      if (item.total_students > result[key].total_students) {
        result[key].total_students = item.total_students;
      }
      if (item.active_students > result[key].active_students) {
        result[key].active_students = item.active_students;
      }
      // Проверяем дату, если текущая дата позже, обновляем все значения
      if (item.date > result[key].date) {
        result[key].date = item.date;
        result[key].total_students = item.total_students;
        result[key].active_students = item.active_students;
      }
    }
  });

  // Преобразуем результат в массив объектов для удобства использования
  return Object.keys(result).map((key) => ({
    date: key,
    total_students: result[key].total_students,
    active_students: result[key].active_students,
  }));
}

const StatisticGraphic = ({
  type,
  period,
  title,
  typeOperation,
  subtitle,
  isUpdate,
  setUpdate,
}) => {
  const [isStartDate, setStartDate] = useState(() => {
    const start = new Date();
    start.setHours(0, 0, 0, 0); // Устанавливаем начало дня
    return start;
  });
  const [isEndDate, setEndDate] = useState(new Date());
  const [isDateRefund, setDateRefund] = useState(false);
  const [isDateUpdating, setDateUpdating] = useState(false);
  const [isAllAmount, setAllAmount] = useState("---");
  const [isData, setData] = useState([]);
  const [isLoadingData, setLoadingData] = useState(false);

  const fetchPaginatedData = async (offset = 0, accumulatedData = []) => {
    const limit = 25; // Можно настроить в зависимости от ограничений API

    let collectionID;
    let typeChecking;
    switch (typeOperation) {
      case "numberOfStudents":
        collectionID = "663656a90027e1d9d07a";
        typeChecking = [
          Query.equal("city", "voronezh"),
          Query.greaterThanEqual("date", isStartDate.toISOString()),
          Query.lessThanEqual("date", isEndDate.toISOString()),
          Query.orderAsc("date"),
        ];
        break;
      case "indexOfGroups":
        collectionID = "6636577f00154ab23ead";
        typeChecking = [
          Query.equal("city", "voronezh"),
          Query.greaterThanEqual("date", isStartDate.toISOString()),
          Query.lessThanEqual("date", isEndDate.toISOString()),
          Query.orderAsc("date"),
        ];
        break;
      default:
        collectionID = "6634dba2001dd0b1157c";
        typeChecking = [
          Query.equal("city", "voronezh"),
          Query.equal("type", typeOperation),
          Query.greaterThanEqual("date", isStartDate.toISOString()),
          Query.lessThanEqual("date", isEndDate.toISOString()),
          Query.orderAsc("date"),
        ];
        break;
    }

    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        collectionID,
        typeChecking,
        limit,
        offset
      );
      const newData = accumulatedData.concat(response.documents);
      if (response.documents.length < limit) {
        return newData;
      } else {
        return fetchPaginatedData(offset + limit, newData);
      }
    } catch (error) {
      console.error("Ошибка при пагинации данных:", error);
      return accumulatedData; // Возвращаем накопленные данные даже в случае ошибки
    }
  };

  const handleUpdateData = useCallback(async () => {
    if (!isStartDate || !isEndDate || !isDateUpdating) return;
    setLoadingData(true);
    try {
      const dataSales = await fetchPaginatedData();
      setData(dataSales);
    } catch (error) {
      console.error("Неожиданная ошибка при обновлении информации:", error);
    } finally {
      setLoadingData(false);
    }
  }, [isStartDate, isEndDate, typeOperation]);

  const handleUpdateAllAmount = () => {
    if (period === "allTime") {
      setAllAmount(
        calculateTotalAmount(isData, "2000-01-01", "3000-01-01", typeOperation)
      );
    } else {
      setAllAmount(
        calculateTotalAmount(isData, isStartDate, isEndDate, typeOperation)
      );
    }
  };

  useEffect(() => {
    handleUpdateData();
  }, [handleUpdateData]);

  // Функция для обновления диапазона дат
  const updateDateRange = (currentStartDate) => {
    let startDate;
    let endDate;
    const today = currentStartDate || new Date();

    switch (period) {
      case "week":
        const dayOfWeek = today.getDay();
        startDate = new Date(today);
        startDate.setDate(today.getDate() - (dayOfWeek || 7) + 1);
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        break;
      case "month":
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case "year":
        startDate = new Date(today.getFullYear(), 0, 1);
        endDate = new Date(today.getFullYear(), 11, 31);
        break;
      default:
        startDate = today;
        endDate = today;
    }

    const newEndDate = addDays(addSeconds(endDate, -1), 1);

    setStartDate(startDate);
    setEndDate(newEndDate);
    setDateUpdating(true);
  };

  useEffect(() => {
    updateDateRange(isStartDate);
  }, [period, isDateRefund]);

  useEffect(() => {
    if (isData) {
      handleUpdateAllAmount();
    }
  }, [isData]);

  useEffect(() => {
    if (type === "year") {
      setStartDate(new Date("2000-01-01"));
      setEndDate(new Date("3000-01-01"));
      setDateRefund(true);
    } else {
      if (isDateRefund === true) {
        setStartDate(() => {
          const start = new Date();
          start.setHours(0, 0, 0, 0);
          return start;
        });
        setDateRefund(false);
      }
    }
  }, [type]);

  useEffect(() => {
    if (isUpdate) {
      if (type === "year") {
        setStartDate(new Date("2000-01-01"));
        setEndDate(new Date("3000-01-01"));
        setDateRefund(true);
      } else {
        if (isDateRefund === true) {
          setStartDate(() => {
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            return start;
          });
          setDateRefund(false);
        }
      }
      handleUpdateData();
      setUpdate(false);
    }
  }, [isUpdate]);

  return (
    <div className={`container-graphic main-container-statistic`}>
      <h4 className="container-graphic__title">{title}</h4>
      {subtitle && <h4 className="container-graphic__subtitle">{subtitle}</h4>}
      <h4 className="container-graphic__all-amount">{isAllAmount}</h4>
      {period !== "allTime" && (
        <DateSelector
          className={"container-graphic__date-selector"}
          date={isStartDate}
          setDateCurrent={setStartDate}
          setEndDate={updateDateRange}
          format={period === "allTime" ? "year" : period}
        />
      )}
      <div className="container-graphic__container-diagramm">
        {typeOperation === "numberOfStudents" ? (
          <SalesBarChart
            initialData={aggregateDataStudents(isData, type)}
            startDate={period === "allTime" ? null : isStartDate}
            endDate={period === "allTime" ? null : isEndDate}
            type={type}
            loading={isLoadingData}
          />
        ) : (
          <SalesLineChart
            initialData={
              typeOperation === "indexOfGroups"
                ? aggregateDataIndexOfGroups(isData, type)
                : aggregateData(isData, type)
            }
            startDate={period === "allTime" ? null : isStartDate}
            endDate={period === "allTime" ? null : isEndDate}
            type={type}
            loading={isLoadingData}
            typeOfValue={typeOperation}
          />
        )}
      </div>

      {typeOperation === "numberOfStudents" && (
        <div className="legend-horizontal">
          <div className="legend__top">
            <div className="legend__indicator legend__indicator__gray-color"></div>
            <h5 className="legend__title">Всего учеников</h5>
          </div>
          <div className="legend__top">
            <div className="legend__indicator legend__indicator__orange-color"></div>
            <h5 className="legend__title">Активные ученики</h5>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatisticGraphic;
