import "./studentInfoComponents.sass";
import React, { useCallback, useEffect, useState } from "react";
import { databases, client } from "../../lib/appwrite";
import { Query } from "appwrite";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "../iconButton/IconButton";
import RecordTaskStudent from "../recordTask/RecordTaskStudent";

const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.length !== 12) {
    console.error("Неверная длина номера телефона:", phoneNumber);
    return phoneNumber; // Возвращаем оригинальный номер, если длина неверна
  }

  const parts = phoneNumber.split("");
  return `${parts.slice(0, 2).join("")} (${parts.slice(2, 5).join("")}) ${parts
    .slice(5, 8)
    .join("")}-${parts.slice(8, 10).join("")}-${parts.slice(10).join("")}`;
};

const StudentFullTasks = ({
  userUID,
  viewingTaskOpen,
  creatorTaskOpen,
  setSelectedUserInfo,
  creatorTaskOpenCheck,
  studentData,
  openingNewTask,
}) => {
  // Значения
  const [isTasks, setTasks] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // Сегодняшняя дата
  const options = { day: "numeric", month: "long", year: "numeric" };
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Функция для получения данных по ролям
  const fetchDataByRole = async (uid) => {
    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "66041861c5966a6ddda5",
        [Query.equal("uid", uid)]
      );
      return response.documents.map((doc) => ({ ...doc, uid }));
    } catch (error) {
      console.error("Ошибка при получении данных клиента:", error);
      return [];
    }
  };

  // Обновление списка
  const handleUpdate = useCallback(async () => {
    try {
      const dataTasks = await fetchDataByRole(userUID);
      setTasks(dataTasks);
    } catch (error) {
      console.error("Неожиданная ошибка при обновлении списка задач:", error);
    } finally {
      setLoading(false);
    }
  }, [userUID]);

  // Обновление списка при открытии
  useEffect(() => {
    setLoading(true);
    handleUpdate();
  }, []);

  // Обновление списка в реальном времени
  useEffect(() => {
    // Подписка на изменения в коллекции
    const unsubscribe = client.subscribe(
      "databases.65f9d6f4dbeeccbe7e74.collections.66041861c5966a6ddda5.documents",
      () => {
        handleUpdate();
      }
    );

    // Отписка от событий при размонтировании компонента
    return () => unsubscribe();
  }, []);

  // Очистка пользователя
  useEffect(() => {
    if (!creatorTaskOpenCheck) {
      setSelectedUserInfo("");
    }
  }, [creatorTaskOpenCheck]);

  // Отметка выполнения задачи
  const handleDoneTask = async (task) => {
    await databases.deleteDocument(
      "65f9d6f4dbeeccbe7e74",
      "66041861c5966a6ddda5",
      task.$id
    );
  };

  // Открытие окна просмотра
  const handleOpenViewingTask = (info) => {
    setSelectedUserInfo(info);
    viewingTaskOpen(true);
  };

  const checkDate = (task) => {
    const completionDate = new Date(task.date_of_completion);
    completionDate.setHours(0, 0, 0, 0); // Устанавливаем начало дня для даты выполнения
    return completionDate.getTime();
  };

  const handleNewTask = () => {
    openingNewTask(true);
    setSelectedUserInfo({
      client: `${studentData.name} ${studentData.sur_name}`,
      phone_number: studentData.phone_number,
      uid: studentData.$id,
    });
  };

  // Запись
  const renderTasks = (tasks) => {
    return tasks.map((task, index) => (
      <RecordTaskStudent
        onClick={() => handleOpenViewingTask(task)}
        key={index}
        type={task.type}
        date={Intl.DateTimeFormat("ru-RU", options).format(
          new Date(task.date_of_completion)
        )}
        overdue={checkDate(task) < today.getTime() ? true : false}
        taskToday={checkDate(task) === today.getTime() ? true : false}
        phone={formatPhoneNumber(task.phone_number)}
        comment={task.comment ? task.comment : "Без комментария"}
        onButtonDoneClick={() => handleDoneTask(task)}
      />
    ));
  };

  return (
    <div className="student-full-tasks main-container">
      <div className="student-full-tasks__toolbar">
        <h4>Задачи</h4>
        <div className="student-full-tasks__buttons-toolbar">
          <IconButton
            onClick={() => {
              handleNewTask();
            }}
            className={"student-full-tasks__button-add-new-task"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M480-790q13 0 21.5-8.5T510-820q0-13-8.5-21.5T480-850q-13 0-21.5 8.5T450-820q0 13 8.5 21.5T480-790ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Zm-520-80q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h168q13-36 43.5-58t68.5-22q38 0 68.5 22t43.5 58h168q33 0 56.5 23.5T840-760v218q0 18-15 28t-32 4q-17-5-35.5-7.5T720-520q-11 0-20.5.5T680-517q-5-1-12-2-5 0-12.5-.5T640-520H320q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440h205q-18 17-32.5 37T467-360H320q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280h123q-2 10-2.5 19.5T440-240q0 20 2 38t7 35q5 17-5 32t-27 15H200Zm500-100v80q0 8 6 14t14 6q8 0 14-6t6-14v-80h80q8 0 14-6t6-14q0-8-6-14t-14-6h-80v-80q0-8-6-14t-14-6q-8 0-14 6t-6 14v80h-80q-8 0-14 6t-6 14q0 8 6 14t14 6h80ZM320-600h320q17 0 28.5-11.5T680-640q0-17-11.5-28.5T640-680H320q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Z" />
              </svg>
            }
          />
          <IconButton
            onClick={() => {
              handleUpdate();
            }}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-70q0-17 11.5-28.5T760-800q17 0 28.5 11.5T800-760v200q0 17-11.5 28.5T760-520H560q-17 0-28.5-11.5T520-560q0-17 11.5-28.5T560-600h128q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q68 0 124.5-34.5T692-367q8-14 22.5-19.5t29.5-.5q16 5 23 21t-1 30q-41 80-117 128t-169 48Z" />
              </svg>
            }
          />
        </div>
      </div>
      <div className="student-full-tasks__container-tasks">
        {renderTasks(isTasks)}
      </div>
      {isLoading && (
        <div className="loading-container-task-student">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}
    </div>
  );
};

export default StudentFullTasks;
