import { Query } from "appwrite";
import { databases } from "../../lib/appwrite";
import IconButton from "../iconButton/IconButton";
import "./studentInfoComponents.sass";
import { useCallback, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import PopupMenu from "../popupMenu/PopupMenu";
import AddNewSubscription from "../addNewSubscription/AddNewSubscription";
import ViewingSubscription from "../viewingSubscription/ViewingSubscription";
import AddFreeLessons from "../addFreeLessons/AddFreeLessons";

const StudentSubscription = ({ data, userUID }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isOpenAddNewSubscription, setOpenAddNewSubscription] = useState(false);
  const [isOpenAddFreeLessons, setOpenAddFreeLessons] = useState(false);
  const [studentSubscriptions, setStudentSubscriptions] = useState([]);
  const [isViewingOpen, setViewingOpen] = useState(false);
  const [isSelectSubscriptionData, setSelectSubscriptionData] = useState(null);

  // Открытие меню
  const handlePopupMenuOpen = () => {
    setMenuOpen(true);
  };

  // Закрытие меню
  const handlePopupMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = (item) => {
    switch (item.key) {
      case "newSubscription":
        setOpenAddNewSubscription(true);
        handlePopupMenuClose();
        break;
      case "addFreeLessons":
        setOpenAddFreeLessons(true);
        handlePopupMenuClose();
        break;
    }
  };

  // Функция для получения данных
  const fetchDataByUid = async (uid) => {
    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "65fef6b362018b62c8c6",
        [Query.equal("user_id", uid)]
      );

      return response.documents.map((doc) => ({ ...doc, uid }));
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
      return [];
    }
  };

  // Обновление списка
  const handleUpdate = useCallback(async () => {
    setLoading(true);
    try {
      const dataSubscription = await fetchDataByUid(userUID);

      setStudentSubscriptions(dataSubscription);
    } catch (error) {
      console.error("Неожиданная ошибка при обновлении списка:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleUpdate();
  }, [userUID]);

  useEffect(() => {
    if (!isViewingOpen) {
      setSelectSubscriptionData(null);
    }
  }, [isViewingOpen]);

  useEffect(() => {
    if (!isOpenAddNewSubscription) {
      handleUpdate();
    }
  }, [isOpenAddNewSubscription]);

  // Запись абонемента
  const renderSubscriptions = () => {
    // Создаем копию массива и разворачиваем ее
    const reversedSubscriptions = studentSubscriptions.slice().reverse();

    return reversedSubscriptions.map((sub, index) => (
      <div
        className={`subscriptions-record ${
          sub.remains_lessons <= 0 ? "subscriptions-record__expired" : ""
        }`}
        key={index}
        onClick={() => {
          setViewingOpen(true);
          setSelectSubscriptionData(sub);
        }}
      >
        <h4 className="subscriptions-record__text">{`${sub.price}₽`}</h4>
        <h4 className="subscriptions-record__text">{sub.number_of_lessons}</h4>
        <h4 className="subscriptions-record__text">{sub.remains_lessons}</h4>
        <h4 className="subscriptions-record__text">{`${Intl.DateTimeFormat(
          "ru-RU"
        ).format(new Date(sub.start_date))} - ${Intl.DateTimeFormat(
          "ru-RU"
        ).format(new Date(sub.end_date))}`}</h4>
      </div>
    ));
  };

  return (
    <div className="student-subscription main-container">
      <div className="student-subscription__toolbar">
        <div className="student-subscription__toolbar-top">
          <h4 className="student-subscription__title">Абонементы</h4>
          <div className="student-subscription__toolbar-buttons">
            <IconButton
              className={"student-subscription__button-update"}
              onClick={() => handleUpdate()}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-70q0-17 11.5-28.5T760-800q17 0 28.5 11.5T800-760v200q0 17-11.5 28.5T760-520H560q-17 0-28.5-11.5T520-560q0-17 11.5-28.5T560-600h128q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q68 0 124.5-34.5T692-367q8-14 22.5-19.5t29.5-.5q16 5 23 21t-1 30q-41 80-117 128t-169 48Z" />
                </svg>
              }
            />
            <IconButton
              onClick={handlePopupMenuOpen}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
                </svg>
              }
            />
          </div>
        </div>
        <div className="student-subscription__toolbar-bottom">
          <h5 className="student-subscription__subtitle">Сумма</h5>
          <h5 className="student-subscription__subtitle">Кол-во уроков</h5>
          <h5 className="student-subscription__subtitle">Доступно уроков</h5>
          <h5 className="student-subscription__subtitle">Срок действия</h5>
        </div>
      </div>
      <div className="student-subscription__list">
        {isLoading && (
          <div className="container-loader-subscription">
            <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
              <CircularProgress color="inherit" />
            </Stack>
          </div>
        )}
        {renderSubscriptions()}
      </div>
      <PopupMenu
        onMouseLeave={handlePopupMenuClose}
        inOpen={isMenuOpen}
        menuItems={[
          { label: "Новый абонемент", key: "newSubscription" },
          { label: "Добавить бесплатные уроки", key: "addFreeLessons" },
        ]}
        onMenuItemClick={handleMenuItemClick}
        styleMenu={"menu-toolbar-subscription"}
      />
      {isOpenAddNewSubscription && (
        <AddNewSubscription
          uid={userUID}
          openModal={setOpenAddNewSubscription}
        />
      )}
      {isViewingOpen && (
        <ViewingSubscription
          modalOpen={setViewingOpen}
          data={isSelectSubscriptionData}
          updateInfo={handleUpdate}
        />
      )}
      {isOpenAddFreeLessons && (
        <AddFreeLessons
          uid={userUID}
          data={data}
          modalOpen={setOpenAddFreeLessons}
        />
      )}
    </div>
  );
};

export default StudentSubscription;
