import React, { useState, useEffect, useCallback } from "react";
import { databases } from "../lib/appwrite";
import { Query } from "appwrite";

const useCityDataStudents = (city) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "663656a90027e1d9d07a",
        [Query.equal("city", city), Query.orderDesc("date")],
        1
      );
      if (response.documents.length > 0) {
        const latestRecord = response.documents[0];
        const totalStudents = latestRecord.total_students;
        const activeStudents = latestRecord.active_students;
        const inactiveStudents = totalStudents - activeStudents;
        setData({ totalStudents, activeStudents, inactiveStudents });
      } else {
        setData({ totalStudents: 0, activeStudents: 0, inactiveStudents: 0 });
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [city]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoading, error };
};

export default useCityDataStudents;
