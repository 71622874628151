import { useEffect, useState } from "react";
import IconButton from "../iconButton/IconButton";
import "./searchInput.sass";

const SearchInput = ({
  onChange,
  holderText,
  width,
  icon,
  className,
  classNameInput,
  value: initialValue,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const handleClear = () => {
    setValue("");
    if (onChange) {
      onChange({ target: { value: "" } });
    }
  };

  return (
    <div className={`search-container ${width} ${className}`}>
      <input
        type="text"
        id={new Date().getTime()}
        placeholder={holderText}
        onChange={handleChange}
        className={`search-input ${classNameInput}`}
        autoComplete="off"
        value={value}
      />
      <i className="search-icon">{icon}</i>
      {value && (
        <IconButton
          className="search-clear-icon"
          onClick={handleClear}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
            >
              <path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" />
            </svg>
          }
        />
      )}
    </div>
  );
};

export default SearchInput;
