import { useCallback, useEffect, useState } from "react";
import "./homeInfoComponents.sass";
import { Query } from "appwrite";
import { databases } from "../../lib/appwrite";
import { CircularProgress, Stack } from "@mui/material";

function calculateTotalAmount(data, type, period) {
  // Определение текущей даты
  const today = new Date();
  let start, end;

  if (period === "week") {
    // Находим день недели для текущей даты (0 - воскресенье, 1 - понедельник, ...)
    const dayOfWeek = today.getDay();

    // Рассчитываем начало текущей недели (понедельник)
    start = new Date(today);
    start.setDate(today.getDate() - (dayOfWeek || 7) + 1);
    start.setHours(0, 0, 0, 0); // Устанавливаем начало дня

    // Рассчитываем конец текущей недели (воскресенье)
    end = new Date(start);
    end.setDate(start.getDate() + 6);
    end.setHours(23, 59, 59, 999); // Устанавливаем конец дня
  } else if (period === "month") {
    // Рассчитываем начало текущего месяца
    start = new Date(today.getFullYear(), today.getMonth(), 1);
    start.setHours(0, 0, 0, 0); // Устанавливаем начало дня

    // Рассчитываем конец текущего месяца
    end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    end.setHours(23, 59, 59, 999); // Устанавливаем конец дня
  }

  if (type === "indexOfGroups") {
    let sum = 0;
    let count = 0;
    data.forEach((item) => {
      const itemDate = new Date(item.date);
      if (itemDate >= start && itemDate <= end) {
        sum += item.index_of_groups;
        count++;
      }
    });
    const average = count > 0 ? sum / count : 0;
    if (average === 0) {
      return "---";
    }
    return average.toLocaleString("ru-RU");
  } else {
    const totalAmount = data.reduce((total, item) => {
      const itemDate = new Date(item.date);
      if (itemDate >= start && itemDate <= end) {
        if (type === "salesVolume" || type === "realization") {
          return total + item.amount;
        }
      }
      return total;
    }, 0);

    if (totalAmount === 0) {
      return "---";
    }

    return type === "salesVolume" || type === "realization"
      ? totalAmount.toLocaleString("ru-RU") + " ₽"
      : totalAmount.toLocaleString("ru-RU");
  }
}

function getWeekData(data, start, end, type) {
  return data.reduce((total, item) => {
    const itemDate = new Date(item.date);
    if (itemDate >= start && itemDate <= end) {
      if (type === "indexOfGroups") {
        return total + item.index_of_groups;
      } else {
        return total + item.amount;
      }
    }
    return total;
  }, 0);
}

function calculateWeeklyChange(data, type, period) {
  const today = new Date();
  let currentStart, currentEnd, lastStart, lastEnd;

  if (period === "week") {
    const dayOfWeek = today.getDay();

    // Настройка дат для текущей недели
    currentStart = new Date(today);
    currentStart.setDate(today.getDate() - (dayOfWeek || 7) + 1);
    currentStart.setHours(0, 0, 0, 0);

    currentEnd = new Date(currentStart);
    currentEnd.setDate(currentStart.getDate() + 6);
    currentEnd.setHours(23, 59, 59, 999);

    // Настройка дат для прошлой недели
    lastStart = new Date(currentStart);
    lastStart.setDate(currentStart.getDate() - 7);

    lastEnd = new Date(currentEnd);
    lastEnd.setDate(currentEnd.getDate() - 7);
  } else if (period === "month") {
    // Настройка дат для текущего месяца
    currentStart = new Date(today.getFullYear(), today.getMonth(), 1);
    currentStart.setHours(0, 0, 0, 0);

    currentEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    currentEnd.setHours(23, 59, 59, 999);

    // Настройка дат для прошлого месяца
    lastStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    lastStart.setHours(0, 0, 0, 0);

    lastEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    lastEnd.setHours(23, 59, 59, 999);
  }

  // Получение сумм за текущий и прошлый периоды
  const currentAmount = getWeekData(data, currentStart, currentEnd, type);
  const lastAmount = getWeekData(data, lastStart, lastEnd, type);

  // Расчет процентного изменения
  if (lastAmount === 0) {
    if (currentAmount === 0) {
      return "---";
    }
  } else {
    const change = ((currentAmount - lastAmount) / lastAmount) * 100;
    return change.toFixed(1);
  }
}

const DataMini = ({ dataType, timePeriod }) => {
  const [isPositiveGrowth, setPositiveGrowth] = useState(false);
  const [isPercentGrowth, setPercentGrowth] = useState("--");
  const [isStartDate, setStartDate] = useState(() => {
    const start = new Date();
    start.setHours(0, 0, 0, 0); // Устанавливаем начало дня
    return start;
  });
  const [isEndDate, setEndDate] = useState(new Date());
  const [isDateUpdating, setDateUpdating] = useState(false);
  const [isAllAmount, setAllAmount] = useState("---");
  const [isData, setData] = useState([]);
  const [isLoadingData, setLoadingData] = useState(false);
  let titleText;
  switch (dataType) {
    case "salesVolume":
      titleText = "Объём продаж";
      break;
    case "realization":
      titleText = "Реализация";
      break;
    case "indexOfGroups":
      titleText = "Индекс групп";
      break;
  }

  const fetchPaginatedData = async (offset = 0, accumulatedData = []) => {
    const limit = 25;

    let collectionID;
    let typeChecking;
    switch (dataType) {
      case "indexOfGroups":
        collectionID = "6636577f00154ab23ead";
        typeChecking = [
          Query.equal("city", "voronezh"),
          Query.greaterThanEqual("date", isStartDate.toISOString()),
          Query.lessThanEqual("date", isEndDate.toISOString()),
          Query.orderAsc("date"),
        ];
        break;
      default:
        collectionID = "6634dba2001dd0b1157c";
        typeChecking = [
          Query.equal("city", "voronezh"),
          Query.equal("type", dataType),
          Query.greaterThanEqual("date", isStartDate.toISOString()),
          Query.lessThanEqual("date", isEndDate.toISOString()),
          Query.orderAsc("date"),
        ];
        break;
    }

    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        collectionID,
        typeChecking,
        limit,
        offset
      );
      const newData = accumulatedData.concat(response.documents);
      if (response.documents.length < limit) {
        return newData;
      } else {
        return fetchPaginatedData(offset + limit, newData);
      }
    } catch (error) {
      console.error("Ошибка при пагинации данных:", error);
      return accumulatedData;
    }
  };

  const handleUpdateData = useCallback(async () => {
    if (!isStartDate || !isEndDate || !isDateUpdating) return;
    setLoadingData(true);
    try {
      const dataSales = await fetchPaginatedData();
      setData(dataSales);
    } catch (error) {
      console.error("Неожиданная ошибка при обновлении информации:", error);
    } finally {
      setLoadingData(false);
    }
  }, [isStartDate, isEndDate, dataType]);

  useEffect(() => {
    setAllAmount(calculateTotalAmount(isData, dataType, timePeriod));
  }, [isData, isStartDate, isEndDate]);

  useEffect(() => {
    handleUpdateData();
  }, [handleUpdateData]);

  useEffect(() => {
    updateDateRange(timePeriod);
  }, [dataType, timePeriod]);

  useEffect(() => {
    setPercentGrowth(calculateWeeklyChange(isData, dataType, timePeriod));
  }, [dataType, isData]);

  useEffect(() => {
    setPositiveGrowth(isPercentGrowth >= 0 ? true : false);
  }, [isPercentGrowth]);

  const updateDateRange = (period) => {
    const today = new Date();

    let currentStartDate, currentEndDate, lastStartDate, lastEndDate;

    if (period === "week") {
      // Получаем день недели для текущей даты (0 - воскресенье, 1 - понедельник, ..., 6 - суббота)
      const dayOfWeek = today.getDay();

      // Рассчитываем начало и конец текущей недели (предполагаем, что неделя начинается с понедельника)
      currentStartDate = new Date(today);
      currentStartDate.setDate(today.getDate() - (dayOfWeek || 7) + 1);
      currentEndDate = new Date(currentStartDate);
      currentEndDate.setDate(currentStartDate.getDate() + 6);

      // Рассчитываем начало и конец прошлой недели
      lastStartDate = new Date(currentStartDate);
      lastStartDate.setDate(currentStartDate.getDate() - 7);
      lastEndDate = new Date(lastStartDate);
      lastEndDate.setDate(lastStartDate.getDate() + 6);
    } else if (period === "month") {
      // Рассчитываем начало и конец текущего месяца
      currentStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
      currentEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      // Рассчитываем начало и конец прошлого месяца
      lastStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      lastEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
    }

    const newEndDate = addDays(addSeconds(currentEndDate, -1), 1);

    // Обновляем состояния для текущего и прошлого периодов
    setStartDate(lastStartDate); // Начальная дата прошлого периода
    setEndDate(newEndDate); // Конечная дата текущего периода
    setDateUpdating(true);
  };

  // Вспомогательные функции для работы с датами
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const addSeconds = (date, seconds) => {
    const result = new Date(date);
    result.setSeconds(result.getSeconds() + seconds);
    return result;
  };

  return (
    <div className="data-main-container data-mini">
      <h5 className="data-mini__title">{titleText}</h5>
      <h4 className="data-mini__info">{isLoadingData ? "---" : isAllAmount}</h4>
      {isLoadingData && (
        <div className="loading-container-data-mini">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" size={16} />
          </Stack>
        </div>
      )}
      <div className="data-mini__container-changes">
        <i>
          {isPositiveGrowth ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="9"
              viewBox="0 0 8 9"
              fill="none"
            >
              <path
                d="M2.95833 2.58333L1.29167 4.25C1.13889 4.40278 0.961806 4.47917 0.760417 4.47917C0.559028 4.47917 0.381944 4.40278 0.229167 4.25C0.0763889 4.09722 0 3.92014 0 3.71875C0 3.51736 0.0763889 3.34028 0.229167 3.1875L3.1875 0.229167C3.34028 0.0763889 3.51389 0 3.70833 0C3.90278 0 4.07639 0.0763889 4.22917 0.229167L7.1875 3.1875C7.34028 3.34028 7.41667 3.51389 7.41667 3.70833C7.41667 3.90278 7.34028 4.07639 7.1875 4.22917C7.03472 4.38194 6.85764 4.45833 6.65625 4.45833C6.45486 4.45833 6.27778 4.38194 6.125 4.22917L4.45833 2.58333V7.95833C4.45833 8.16667 4.38542 8.34375 4.23958 8.48958C4.09375 8.63542 3.91667 8.70833 3.70833 8.70833C3.5 8.70833 3.32292 8.63542 3.17708 8.48958C3.03125 8.34375 2.95833 8.16667 2.95833 7.95833V2.58333Z"
                fill="#00B500"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="9"
              viewBox="0 0 8 9"
              fill="none"
            >
              <path
                d="M2.95833 6.12516L1.29167 4.4585C1.13889 4.30572 0.961806 4.22933 0.760417 4.22933C0.559028 4.22933 0.381944 4.30572 0.229167 4.4585C0.0763889 4.61127 0 4.78836 0 4.98975C0 5.19113 0.0763889 5.36822 0.229167 5.521L3.1875 8.47933C3.34028 8.63211 3.51389 8.7085 3.70833 8.7085C3.90278 8.7085 4.07639 8.63211 4.22917 8.47933L7.1875 5.521C7.34028 5.36822 7.41667 5.19461 7.41667 5.00016C7.41667 4.80572 7.34028 4.63211 7.1875 4.47933C7.03472 4.32655 6.85764 4.25016 6.65625 4.25016C6.45486 4.25016 6.27778 4.32655 6.125 4.47933L4.45833 6.12516V0.750163C4.45833 0.541829 4.38542 0.364745 4.23958 0.218912C4.09375 0.0730788 3.91667 0.000162125 3.70833 0.000162125C3.5 0.000162125 3.32292 0.0730788 3.17708 0.218912C3.03125 0.364745 2.95833 0.541829 2.95833 0.750163V6.12516Z"
                fill="#FF0000"
              />
            </svg>
          )}
        </i>
        <h5 className="data-mini__subtitle-data">
          <span
            className={`percentage-of-change-text ${
              isPositiveGrowth ? "green-text" : "red-text"
            }`}
          >
            {`${
              isPercentGrowth
                ? isPercentGrowth >= 0
                  ? isPercentGrowth
                  : isPercentGrowth.slice(1)
                : "--"
            }%`}
          </span>{" "}
          {timePeriod === "week"
            ? "по сравнению с прошлой неделей"
            : "по сравнению с прошлым месяцем"}
        </h5>
      </div>
    </div>
  );
};

export default DataMini;
