import "./salesChart.sass";
import React, { useState, useEffect } from "react";
import { ru } from "date-fns/locale";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  TimeScale,
} from "chart.js";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import "chartjs-adapter-date-fns"; // Адаптер для форматирования дат НЕ УДАЛЯТЬ
import { format } from "date-fns";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  TimeScale
);

const SalesLineChart = ({
  initialData,
  startDate,
  endDate,
  type,
  loading,
  typeOfValue,
}) => {
  const [data, setData] = useState([]);
  const [isFooterColor, setFooterColor] = useState("#748091");
  const allMonth = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  useEffect(() => {
    if (startDate && endDate) {
      if (initialData && Array.isArray(initialData)) {
        const filteredData = initialData.filter((item) => {
          const itemDate = new Date(item.date).setHours(0, 0, 0, 1);
          const start = new Date(startDate).setHours(0, 0, 0, 0);
          const end = new Date(endDate).setHours(0, 0, 0, 0);
          return itemDate >= start && itemDate <= endDate;
        });
        setData(filteredData);
      }
    } else {
      setData(initialData);
    }
  }, [startDate, endDate, initialData]);

  const options = {
    responsive: true,

    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        mode: "index",
        position: "average",
        intersect: false,
        backgroundColor: "#fff",
        titleColor: "#748091",
        titleAlign: "center",
        bodyColor: "#000",
        bodyAlign: "center",
        displayColors: false,
        borderColor: "rgba(116, 128, 145, 0.25)",
        borderWidth: 2,
        cornerRadius: 8,
        footerColor: isFooterColor,
        bodyFont: {
          size: typeOfValue === "indexOfGroups" ? 16 : 13,
          style: "normal",
          weight: "bold",
        },
        footerAlign: "center",
        footerMarginTop: 8,
        padding: 8,
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.25)",
        caretSize: 4,
        caretPadding: 12,

        callbacks: {
          title: function (context) {
            let dateString = context[0].label; // Теперь это строка, например, "24 мая 2024"
            let dateParts = dateString.split(" ");
            let formattedDate;

            if (type === "day") {
              formattedDate = dateString;
            } else if (type === "month") {
              formattedDate = allMonth[dateParts[0] - 1];
            } else if (type === "year") {
              formattedDate = dateString;
            }

            return formattedDate;
          },

          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              if (typeOfValue === "indexOfGroups") {
                label += context.parsed.y.toFixed(3);
              } else {
                label += new Intl.NumberFormat("ru-RU", {
                  style: "currency",
                  currency: "RUB",
                }).format(context.parsed.y);
              }
            }
            return label;
          },

          footer: function (context) {
            let index = context[0].dataIndex;

            if (index === 0) {
              setFooterColor("#748091");
              return "----";
            }

            let currentData = data[index];

            let previousData = data[index - 1];

            if (typeOfValue === "indexOfGroups") {
              if (
                !currentData.index_of_groups ||
                !previousData.index_of_groups
              ) {
                setFooterColor("#748091");
                return "----";
              }
              let difference =
                currentData.index_of_groups - previousData.index_of_groups;
              let arrow = difference >= 0 ? "↑" : "↓";
              let color = difference >= 0 ? "#00B500" : "#FF0000";
              setFooterColor(color);

              // Возвращаем результат сравнения
              return ` ${arrow} ${difference.toFixed(2)}`;
            } else {
              if (!currentData || !previousData) {
                setFooterColor("#748091");
                return "----";
              }
              // Сравниваем суммы
              let difference = currentData.amount - previousData.amount;
              let arrow = difference >= 0 ? "↑" : "↓";
              let color = difference >= 0 ? "#00B500" : "#FF0000";
              setFooterColor(color);

              // Возвращаем результат сравнения
              return ` ${arrow} ${new Intl.NumberFormat("ru-RU", {
                style: "currency",
                currency: "RUB",
              }).format(difference)}`;
            }
          },
        },
      },
    },

    scales: {
      x: {
        type: "time",
        time: {
          unit: type,
          tooltipFormat:
            type === "year"
              ? "yyyy"
              : type === "day"
              ? "dd MMMM yyyy"
              : "MM yyyy",
          displayFormats: {
            day: "dd MMMM",
            month: "MM yyyy", // Используем стандартный формат для месяца
            year: "yyyy",
          },
        },
        adapters: {
          date: {
            locale: ru,
          },
        },
        grid: { display: false },
        ticks: {
          callback: function (value, index, values) {
            const date = new Date(value);
            const monthNumber = date.getMonth();

            switch (type) {
              case "day":
                return format(date, "dd MMMM", { locale: ru });
              case "month":
                if (monthNumber !== undefined) {
                  return allMonth[monthNumber];
                } else {
                  return format(date, "MM", { locale: ru });
                }
              case "year":
                return format(date, "yyyy", { locale: ru });
              default:
                return format(date, "dd/MM/yyyy", { locale: ru });
            }
          },
        },
      },

      y: {
        ticks:
          typeOfValue === "indexOfGroups"
            ? {
                stepSize: 0.5,
                callback: (value) => value,
              }
            : {
                stepSize:
                  type === "year" ? 1000000 : type === "month" ? 300000 : 10000,
                callback: (value) => `${value / 1000}k`,
              },
      },
    },
    maintainAspectRatio: false,
  };

  const dataChart = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: typeOfValue === "indexOfGroups" ? "" : "Сумма",
        data: data.map((item) =>
          typeOfValue === "indexOfGroups" ? item.index_of_groups : item.amount
        ),
        backgroundColor: "white",
        borderColor: "rgba(255, 190, 0, 1)",
        pointBorderColor: "rgba(255, 190, 0, 1)",
        fill: false,
        pointBorderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 3,
        tension: 0,
      },
    ],
  };

  return loading ? (
    <div className="statistic-data-loading">
      <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
        <CircularProgress color="inherit" />
      </Stack>
    </div>
  ) : data.length === 0 ? (
    <div className="statistic-data-null">Нет данных</div>
  ) : (
    <Line data={dataChart} options={options} />
  );
};

export default SalesLineChart;
