import { useEffect, useState } from "react";
import Button from "../button/Button";
import "./paymentSubscriptionForced.sass";
import { databases } from "../../lib/appwrite";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import OptionMenu from "../optionMenu/OptionMenu";
import recordingAnOperation from "../../hooks/recordingAnOperation";

const optionsLesson = [
  { value: "", label: "Выберите..." },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

const PaymentSubscriptionForced = ({
  isSubscription,
  openModal,
  isUpdate,
  updateInfoSubscription,
}) => {
  const { statusOperation, updateOperationInfo } = recordingAnOperation();

  const [countLesson, setCountLesson] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isErrorVisible, setErrorVisible] = useState("");

  const paymentFromASubscription = async (subscription, lessonCount) => {
    const lessonCountNumber = Number(lessonCount);
    const pricePerLesson =
      (subscription.price / subscription.number_of_lessons) * lessonCountNumber;
    const totalAmount =
      pricePerLesson > subscription.remains_balance
        ? subscription.remains_balance
        : pricePerLesson;

    try {
      await databases.updateDocument(
        "65f9d6f4dbeeccbe7e74",
        "65fef6b362018b62c8c6",
        subscription.$id,
        {
          remains_lessons: subscription.remains_lessons - lessonCountNumber,
          remains_balance: Math.floor(
            subscription.remains_balance - totalAmount
          ),
        }
      );
      console.log(`Списание средств прошло успешно (${totalAmount})`);
      updateOperationInfo("realization", Math.floor(totalAmount));
    } catch (error) {
      console.error("Ошибка при снятии средств", error);
      throw new Error("Ошибка при обновлении подписки");
    }
  };

  const handlePayment = async () => {
    setErrorVisible("");
    if (isSubscription.remains_lessons < Number(countLesson)) {
      setErrorVisible("Недостаточно средств!");
      return;
    }

    setLoading(true);
    try {
      await paymentFromASubscription(isSubscription, countLesson);
      openModal(false);
      isUpdate();
      updateInfoSubscription();
    } catch (error) {
      setErrorVisible("Произошла ошибка, повторите попытку");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="payment-subscription-forced">
      <h4 className="payment-subscription-forced__title">
        Принудительно осуществить оплату
      </h4>

      <OptionMenu
        title={"Кол-во уроков"}
        id={"subscriptionOptionPaymentForced"}
        value={countLesson}
        options={optionsLesson}
        onChange={(e) => setCountLesson(e.target.value)}
      />

      <div className="payment-subscription-forced__buttons">
        <Button
          className={"payment-subscription-forced__button-cancel"}
          colorStyle={"invisible"}
          onClick={() => {
            openModal(false);
          }}
        >
          Отмена
        </Button>
        <Button
          colorStyle={"blue"}
          onClick={handlePayment}
          disabled={!countLesson}
        >
          Сохранить
        </Button>
      </div>
      {isErrorVisible && (
        <h4 className="payment-subscription-forced__error-message">
          {isErrorVisible}
        </h4>
      )}
      {isLoading && (
        <div className="container-loader-subscription">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}
    </div>
  );
};

export default PaymentSubscriptionForced;
