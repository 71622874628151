import "./viewingLessonStyles.sass";
import { useCallback, useEffect, useState } from "react";
import { databases } from "../../lib/appwrite";
import { useNavigate } from "react-router-dom";
import Button from "../button/Button";
import IconButton from "../iconButton/IconButton";
import PopupMenu from "../popupMenu/PopupMenu";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import recordingAttendanceInDatabase from "../../hooks/recordingAttendanceInDatabase";
import StudentIsViewingLesson from "../studentIsViewingLesson/StudentIsViewingLesson";
import { Query } from "appwrite";
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
} from "@mui/material";

const checkPresence = (arr) => {
  if (arr) {
    return arr.some((obj) => obj.wasPresent === true);
  } else {
    return true;
  }
};

const ViewingLessonGroup = ({ dataLesson, setViewOpen }) => {
  // Отметка присутствия
  const { status, studentErrors, markAttendance } =
    recordingAttendanceInDatabase();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAtendance, setLoadingAtendance] = useState(false);
  const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [teacherData, setTeacherData] = useState(null);
  const [studentData, setStudentData] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [idLessonVisible, setIdLessonVisible] = useState(false);
  const [isAlertError, setAlertError] = useState(false);
  const [isErrorMessageAlert, setErrorMessageAlert] = useState(["", ""]);

  const [errorsList, setErrorsList] = useState([]);

  const options = { day: "numeric", month: "long" };
  const dateLesson = new Date(dataLesson.date_start).toLocaleDateString(
    "ru-RU",
    options
  );

  const [attendanceDataStudents, setAttendanceDataStudents] = useState({});
  const [attendanceDataLast, setAttendanceDataLast] = useState({});

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length !== 12) {
      console.error("Неверная длина номера телефона:", phoneNumber);
      setErrorMessageAlert([
        "Ошибка форматирования номера телефона",
        phoneNumber,
      ]);
      setAlertError(true);
      return phoneNumber; // Возвращаем оригинальный номер, если длина неверна
    }

    const parts = phoneNumber.split("");
    return `${parts.slice(0, 2).join("")} (${parts
      .slice(2, 5)
      .join("")}) ${parts.slice(5, 8).join("")}-${parts
      .slice(8, 10)
      .join("")}-${parts.slice(10).join("")}`;
  };

  const handleAttendanceChange = (studentId, data) => {
    setAttendanceDataStudents((prevData) => ({
      ...prevData,
      [studentId]: data,
    }));
  };

  const lessonDateAndTime = `${dateLesson}, ${Math.floor(
    dataLesson.time_start + 10
  )}:${String(Math.floor(((dataLesson.time_start + 10) % 1) * 60)).padStart(
    2,
    "0"
  )} - ${Math.floor(dataLesson.time_start + 10 + dataLesson.duration)}:${String(
    Math.floor(((dataLesson.time_start + 10 + dataLesson.duration) % 1) * 60)
  ).padStart(2, "0")}`;

  async function fetchAttendanceData(students, lessonId) {
    // Проверка посещаемости для каждого ученика
    const attendancePromises = students.map((studentId) =>
      databases.listDocuments("65f9d6f4dbeeccbe7e74", "661cdbdec056444bc33f", [
        Query.equal("id_lesson", lessonId),
        Query.equal("id_student", studentId),
      ])
    );

    // Ожидание выполнения всех промисов посещаемости
    const attendanceResults = await Promise.all(attendancePromises);

    // Обработка результатов и установка данных о посещаемости
    return attendanceResults.map((result, index) => {
      const wasPresent = result.documents.length > 0; // Ученик был отмечен, если найден хотя бы один документ
      let presentDetails = "";
      if (wasPresent) {
        presentDetails = result.documents[0];
      }
      return {
        studentId: students[index],
        wasPresent,
        presentDetails,
      };
    });
  }

  const getUserData = useCallback(async (teacher, students, lessonId) => {
    setIsLoading(true);
    try {
      // Инициализация массива промисов
      const promises = [];

      // Запрос данных учителя, если указан
      if (teacher !== "") {
        const teacherPromise = databases.getDocument(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          teacher
        );
        promises.push(teacherPromise);
      }

      // Запросы данных студентов
      const studentPromises = students.map((studentId) =>
        databases.getDocument(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          studentId
        )
      );
      promises.push(...studentPromises);

      // Ожидание выполнения всех промисов
      const results = await Promise.all(promises);

      // Установка данных учителя и студентов
      if (teacher !== "") {
        setTeacherData(results[0]); // Первый результат всегда будет учителем, если он есть
        setStudentData(results.slice(1)); // Остальные результаты - данные студентов
      } else {
        setStudentData(results); // Все результаты - данные студентов, если учителя нет
      }

      // Вызов метода для поиска посещаемости
      const attendanceData = await fetchAttendanceData(students, lessonId);

      // Обновление состояния с данными о посещаемости
      setAttendanceDataLast(attendanceData);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setErrorMessageAlert(["Ошибка получения данных", error.message]);
      setAlertError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getUserData(dataLesson.teacher, dataLesson.students, dataLesson.$id);
  }, [getUserData, dataLesson]);

  // Открытие меню
  const handlePopupMenuOpen = () => {
    setIsMenuOpen(true);
  };

  // Закрытие меню
  const handlePopupMenuClose = () => {
    setIsMenuOpen(false);
  };

  // Нажатие на пункт меню
  const handleClickMenu = async (key, infoUser) => {
    switch (key) {
      case "edit":
        hundleOpenEditLesson();
        break;

      case "delete":
        handleOpenDialogDeleteLesson();
        break;
      default:
        console.log("Error");
    }
  };

  const hundleOpenEditLesson = () => {
    navigate("/admin/schedule/creator-lesson", {
      state: { lessonData: dataLesson },
    });
  };

  const handleUpdateInfoAttendance = useCallback(async () => {
    const attendanceData = await fetchAttendanceData(
      dataLesson.students,
      dataLesson.$id
    );
    // Обновление состояния с данными о посещаемости
    setAttendanceDataLast(attendanceData);
  }, [dataLesson]);

  useEffect(() => {
    //console.log(`Статус: ${status}`);

    // Обработка статусов
    switch (status) {
      case "loading":
        setLoadingAtendance(true);
        break;
      case "completed":
        setLoadingAtendance(false);
        dataLesson.attendance = true;
        setViewOpen(false);
        break;

      case "error":
        handleUpdateInfoAttendance();

        setLoadingAtendance(false);
        const newErrorsList = []; // Создаем новый массив для ошибок
        if (studentErrors) {
          const errorMessages = {
            duplicate: "Статус урока уже был изменен",
            points: "Недостаточно баллов для оплаты!",
            balance: "Недостаточно средств для оплаты!",
            unknown:
              "Произвестная ошибка, обновите страницу и попробуйте снова",
          };
          // Перебор массива ошибок и добавление сообщений в массив
          studentErrors.forEach((errorInfo) => {
            const errorMessage =
              errorMessages[errorInfo.error.split(" ").pop()] ||
              "Произошла ошибка, повторите попытку позже";
            console.error(
              `Ошибка у ученика ${errorInfo.studentId}: ${errorMessage}`
            );

            newErrorsList.push({
              id: errorInfo.studentId,
              message: errorMessage,
            });
          });
          setErrorsList(newErrorsList); // Обновляем состояние с новым списком ошибок
        }
        break;
      default:
        setErrorsList("");
        break;
    }
  }, [
    status,
    studentErrors,
    dataLesson,
    handleUpdateInfoAttendance,
    setViewOpen,
  ]);

  const handleMarkAttendance = () => {
    // Проверка наличия данных урока и учащихся
    if (
      !dataLesson ||
      !dataLesson.students ||
      dataLesson.students.length === 0
    ) {
      console.error("Данные отсутствуют или учащиеся недоступны");
      setErrorMessageAlert([
        "Ошибка",
        "Данные отсутсвуют или учащиеся недоступны",
      ]);
      setAlertError(true);
      return;
    }

    // Преобразуйте attendanceData в массив объектов
    const attendanceArray = Object.keys(attendanceDataStudents).map((key) => ({
      studentId: key,
      ...attendanceDataStudents[key],
    }));

    const { $id } = dataLesson;

    const studentsData = attendanceArray.map((student) => ({
      id_lesson: $id,
      id_student: student.studentId,
      attendance: student.attendance,
      payment_method:
        dataLesson.type_lesson === "trial" ? "trial" : student.paymentMethod,
      payment_amount: Number(student.paymentAmount),
      module: dataLesson.lesson_module || null,
      lesson: dataLesson.class_number || null,
    }));

    // Вызов функции markAttendance с подготовленными данными
    markAttendance(studentsData, studentData, true, dataLesson.date_start);
  };

  // Удаление абонемента
  const handleDeleteLesson = async () => {
    try {
      await databases.deleteDocument(
        "65f9d6f4dbeeccbe7e74",
        "66143d053c25fc1c3d4d",
        dataLesson.$id
      );

      setViewOpen(false);
    } catch (error) {
      console.error("Ошибка при удалении:", error);
      setErrorMessageAlert(["Ошибка при удалении урока", error.message]);
      setAlertError(true);
    }
  };

  // Открытие окна удаления
  const handleOpenDialogDeleteLesson = () => {
    setOpenDeleteDialog(true);
  };

  // Закрытие окна удаления
  const handleCloseDialogDeleteLesson = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <div className="lesson-overlay">
      {isLoading && (
        <div className="loading-container-lesson">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}

      {!isLoading && (
        <div
          className={`lesson-details-group ${
            dataLesson.type_lesson === "trial"
              ? "max-width-328"
              : "max-width-680"
          }`}
        >
          {isLoadingAtendance && (
            <div className="loading-container-lesson-attendance">
              <Stack sx={{ color: "#748091" }} spacing={2} direction="row">
                <CircularProgress color="inherit" />
              </Stack>
              <h4 className="loading-container-lesson-attendance__text">
                Обновление данных...
              </h4>
            </div>
          )}
          <div className="lesson-group-toolbar">
            <h4 className="lesson-group-toolbar__date-and-time">
              {lessonDateAndTime}
            </h4>

            {idLessonVisible ? (
              <h5
                className="lesson-group-toolbar__lesson-type title-lesson-one"
                onDoubleClick={() => setIdLessonVisible(!idLessonVisible)}
              >
                {`ID ${dataLesson.$id}`}
              </h5>
            ) : (
              <h5
                className="lesson-group-toolbar__lesson-type title-lesson-one"
                onDoubleClick={() => setIdLessonVisible(!idLessonVisible)}
              >
                {dataLesson.type_lesson === "group"
                  ? "Групповой урок"
                  : "Пробный урок"}
              </h5>
            )}

            <IconButton
              className={"lesson-group-toolbar__popup-menu"}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400Z" />
                </svg>
              }
              onClick={handlePopupMenuOpen}
            />

            {checkPresence(
              Object.keys(attendanceDataLast).length === 0
                ? [{ wasPresent: false }]
                : attendanceDataLast
            ) ? (
              <PopupMenu
                onMouseLeave={handlePopupMenuClose}
                inOpen={isMenuOpen}
                menuItems={[{ label: "Удалить", key: "delete" }]}
                onMenuItemClick={(item) => {
                  handleClickMenu(item.key);

                  handlePopupMenuClose();
                }}
                styleMenu={"menu-lesson-viewing"}
              />
            ) : (
              <PopupMenu
                onMouseLeave={handlePopupMenuClose}
                inOpen={isMenuOpen}
                menuItems={[
                  { label: "Редактировать", key: "edit" },
                  { label: "Удалить", key: "delete" },
                ]}
                onMenuItemClick={(item) => {
                  handleClickMenu(item.key);

                  handlePopupMenuClose();
                }}
                styleMenu={"menu-lesson-viewing"}
              />
            )}
          </div>

          <div className="lesson-group-content">
            <div className="lesson-group-content__main-info">
              <div className="lesson-group-content__teacher">
                {teacherData && teacherData.avatar_image ? (
                  <img
                    className="lesson-group-content__avatar"
                    src={teacherData.avatar_image}
                    alt="Avatar"
                  />
                ) : (
                  <div className="lesson-group-content__avatar"></div>
                )}

                <div className="lesson-group-content__info">
                  <h4 className="lesson-group-content__username title-lesson-one">
                    {teacherData
                      ? `${teacherData.name} ${teacherData.sur_name}`
                      : "----"}
                  </h4>
                  <h4 className="lesson-group-content__phone title-lesson-two">
                    {teacherData
                      ? formatPhoneNumber(teacherData.phone_number)
                      : "----"}
                  </h4>
                </div>
              </div>

              {dataLesson.type_lesson !== "trial" && (
                <div className="lesson-group-content__contaier-data">
                  <h4 className="title-lesson-two">Урок</h4>
                  <h4 className="data-text-lesson-two">
                    {dataLesson.lesson_module === 0 &&
                    dataLesson.class_number === 0
                      ? "Урок не указан"
                      : `${dataLesson.lesson_module} модуль ${dataLesson.class_number} урок`}
                  </h4>
                </div>
              )}

              <div className="lesson-group-content__contaier-data">
                <h4 className="title-lesson-two">Комментарий</h4>
                <h4 className="data-text-lesson-two">
                  {dataLesson.comment ? dataLesson.comment : "----"}
                </h4>
              </div>
            </div>
            <h4 className="lesson-group-content__title-students title-lesson-one">
              Ученики
            </h4>

            {studentData.map((student) => {
              // Найти данные посещаемости для текущего студента
              const attendance = attendanceDataLast.find(
                (data) => data.studentId === student.$id
              );

              // Установить значение для disabled и detailsLessonData
              const disabled = attendance ? attendance.wasPresent : false;
              const detailsLessonData = attendance
                ? attendance.presentDetails
                : {};

              return (
                <StudentIsViewingLesson
                  key={student.$id}
                  student={student}
                  onAttendanceChange={handleAttendanceChange}
                  disabled={disabled}
                  detailsLessonData={detailsLessonData}
                  lessonType={dataLesson.type_lesson}
                />
              );
            })}
            {errorsList.length > 0 && (
              <div className="lesson-group-content__errors-container">
                <h3 className="title-lesson-one red-error-text">Ошибки:</h3>

                {errorsList.map((error) => (
                  <h4
                    className="title-lesson-two red-error-text"
                    key={error.id}
                  >
                    {error.id}, Ошибка: {error.message}
                  </h4>
                ))}
              </div>
            )}
          </div>

          <div className="lesson-group-bottom-bar">
            <Button
              colorStyle={"invisible"}
              className={"lesson-group-bottom-bar__button-canel"}
              onClick={() => setViewOpen(false)}
            >
              Закрыть
            </Button>
            <Button
              colorStyle={"blue"}
              className={"lesson-group-bottom-bar__button-save"}
              onClick={handleMarkAttendance}
              disabled={dataLesson.attendance}
            >
              Сохранить
            </Button>
          </div>
        </div>
      )}
      <Dialog
        open={isOpenDeleteDialog}
        onClose={handleCloseDialogDeleteLesson}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Вы уверены, что хотите удалить этот урок?`}
        </DialogTitle>

        <DialogActions>
          <Button
            colorStyle={"invisible"}
            onClick={handleCloseDialogDeleteLesson}
          >
            Отмена
          </Button>
          <Button
            colorStyle={"red"}
            onClick={() => handleDeleteLesson()}
            autoFocus
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>

      {/* <div
        className="viewing-lesson-box-close"
        onClick={() => setViewOpen(false)}
      ></div> */}

      <Snackbar
        open={isAlertError}
        autoHideDuration={6000}
        onClose={() => setAlertError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAlertError(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <AlertTitle>{isErrorMessageAlert[0]}</AlertTitle>
          {isErrorMessageAlert[1]}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ViewingLessonGroup;
