import { useState } from "react";
import { databases } from "../lib/appwrite";
import { ID, Query } from "appwrite";

const operationSearch = async (type) => {
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0); // Устанавливаем начало дня
  const todayEnd = new Date(todayStart);
  todayEnd.setDate(todayEnd.getDate() + 1); // Устанавливаем конец дня

  try {
    const searchResult = await databases.listDocuments(
      "65f9d6f4dbeeccbe7e74",
      "6634dba2001dd0b1157c",
      [
        Query.equal("type", type),
        Query.greaterThanEqual("date", todayStart.toISOString()),
        Query.lessThan("date", todayEnd.toISOString()),
        Query.equal("city", "voronezh"),
      ]
    );
    return searchResult.documents[0];
  } catch (error) {
    console.error("Произошла ошибка при поиске данных операции", error);
  }
};

const savingOperationData = async (type, amount) => {
  const todayOperation = await operationSearch(type);

  try {
    if (todayOperation) {
      await databases.updateDocument(
        "65f9d6f4dbeeccbe7e74",
        "6634dba2001dd0b1157c",
        todayOperation.$id,
        {
          amount: todayOperation.amount + Number(amount),
        }
      );
    } else {
      await databases.createDocument(
        "65f9d6f4dbeeccbe7e74",
        "6634dba2001dd0b1157c",
        ID.unique(),
        {
          city: "voronezh",
          date: new Date().toISOString(),
          type: type,
          amount: Number(amount),
        }
      );
    }
    return new Promise((resolve) => resolve("succes"));
  } catch (error) {
    console.error(`Произошла ошибка при обновлении данных операции`, error);
    return new Promise((resolve) => resolve("error"));
  }
};

const useRecordOperation = () => {
  const [statusOperation, setStatus] = useState("idle");

  const updateOperationInfo = async (typeOperation, amount) => {
    setStatus("loading");
    try {
      const result = await savingOperationData(typeOperation, amount);

      if (result) {
        setStatus(result);
      }
    } catch (error) {
      setStatus("error");
    }
  };

  return {
    statusOperation,
    updateOperationInfo,
  };
};

export default useRecordOperation;
