import "./studentsList.sass";
import React, { useState, useEffect, useCallback } from "react";
import { databases } from "../../lib/appwrite";
import { Query } from "appwrite";
import IconButton from "../iconButton/IconButton";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

const StudentsList = ({ searchTerm, listUpdate }) => {
  const navigate = useNavigate();
  const recordsPerPage = 25; // Количество записей на страницу
  const [currentPage, setCurrentPage] = useState(1);
  const [students, setStudents] = useState([]); // Студенты текущей страницы
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [searchStudentNull, setSearchStudentsNull] = useState(false);

  // получение всего баланса
  async function fetchSubscriptions(userId) {
    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "65fef6b362018b62c8c6",
        [Query.equal("user_id", userId)],
        Query.select(["remains_balance"])
      );

      let totalRemainsBalance = 0;

      response.documents.forEach((doc) => {
        totalRemainsBalance += doc.remains_balance;
      });

      return totalRemainsBalance; // Возвращаем баланс
    } catch (error) {
      console.error("Ошибка при получении данных о балансе:", error);
      throw error;
    }
  }

  // Функция для загрузки студентов текущей страницы
  const fetchStudentsForPage = async (page) => {
    setIsLoading(true);
    try {
      const start = (page - 1) * recordsPerPage;
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "65f9d6fd218cc728529a",
        [
          Query.equal("role", "student"),
          Query.equal("city", "voronezh"),
          Query.limit(recordsPerPage),
          Query.offset(start),
          Query.select([
            "$id",
            "role",
            "user_id",
            "name",
            "sur_name",
            "phone_number",
            "city",
            "last_activity",
          ]),
          ,
          Query.orderDesc("date_of_arrival"),
        ]
      );

      const studentsWithBalance = await Promise.all(
        response.documents.map(async (student) => {
          const balance = await fetchSubscriptions(student.user_id);
          return { ...student, total_balance: balance };
        })
      );
      // Обновление состояния с данными студентов текущей страницы
      setStudents(studentsWithBalance);
      setTotalStudents(response.total);
      setTotalPages(Math.ceil(response.total / recordsPerPage));
    } catch (error) {
      console.error("Ошибка при получении данных студентов:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Обработчик изменения страницы
  useEffect(() => {
    fetchStudentsForPage(currentPage);
  }, [currentPage, listUpdate]);

  // Переход на страницу с информацией о студенте
  const handleOpenStudentIsCard = (uid) => {
    navigate("/admin/students/student-info-card", {
      state: { studentUID: uid },
    });
  };

  // Следующая страница
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Предыдущая страница
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Показатель видимости
  const currentRange = () => {
    const start = (currentPage - 1) * recordsPerPage;
    const end = Math.min(start + recordsPerPage, totalStudents);
    return `${start + 1} - ${end} из ${totalStudents}`;
  };

  // Форматирование данных
  const formatPhoneNumber = (phoneNumber) => {
    const parts = phoneNumber.split("");
    return `${parts.slice(0, 2).join("")} (${parts
      .slice(2, 5)
      .join("")}) ${parts.slice(5, 8).join("")}-${parts
      .slice(8, 10)
      .join("")}-${parts.slice(10, 12).join("")}`;
  };

  const formatter = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
    maximumFractionDigits: 0,
  });

  const userActive = (timestamp) => {
    const nowDate = new Date();
    const dateLastActive = new Date(timestamp);
    const difference = nowDate - dateLastActive;
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const daysInMonth = 30;
    const millisecondsPerMonth = daysInMonth * millisecondsPerDay;

    if (difference <= millisecondsPerMonth) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // Таймер для задержки поиска
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // Задержка в 500 мс

    // Очистка таймера при размонтировании компонента или изменении searchTerm
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  // Выполнение поиска с использованием debouncedSearchTerm
  useEffect(() => {
    // Если searchTerm пустой, то обновляем список по умолчанию
    if (debouncedSearchTerm === "") {
      fetchStudentsForPage(currentPage);
    } else if (debouncedSearchTerm) {
      // Функция для выполнения поиска
      searchStudents("voronezh", debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const searchStudents = async (city, searchTerm) => {
    setIsLoading(true);
    try {
      // Массив промисов для каждого поля поиска
      const searchPromises = [
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("name", searchTerm),
          ]
        ),
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("sur_name", searchTerm),
          ]
        ),
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("phone_number", searchTerm),
          ]
        ),
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("user_id", searchTerm),
          ]
        ),
        // Добавьте другие поля для поиска здесь
      ];

      // Выполнение всех запросов и объединение результатов
      const results = await Promise.all(searchPromises);
      const documents = results
        .flatMap((result) => result.documents)
        // Удаление дубликатов документов
        .filter(
          (doc, index, self) =>
            index === self.findIndex((t) => t.$id === doc.$id)
        );

      // Обработка полученных результатов
      const studentsWithBalance = await Promise.all(
        documents.map(async (student) => {
          const balance = await fetchSubscriptions(student.user_id);
          return { ...student, total_balance: balance };
        })
      );

      // Обновление состояния с данными студентов текущей страницы
      setStudents(studentsWithBalance);
    } catch (error) {
      console.error("Ошибка при поиске студентов:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (students.length > 0) {
      setSearchStudentsNull(false);
    } else {
      setSearchStudentsNull(true);
    }
  }, [students]);

  // Запись ученика
  const renderStudents = () => {
    return students.map((user, index) => (
      <tr
        key={index}
        className="record"
        onClick={() => {
          handleOpenStudentIsCard(user.$id);
        }}
      >
        <td className="record__text-start">{`${user.name} ${user.sur_name}`}</td>
        <td className="record__text-center">
          {formatPhoneNumber(user.phone_number)}
        </td>
        <td className="record__text-center">
          {formatter.format(user.total_balance)}
        </td>
        <td className="record__text-center">
          {user.last_activity
            ? Intl.DateTimeFormat("ru-RU").format(new Date(user.last_activity))
            : "--"}
        </td>
        <td className="record__last-element">
          <h5
            className={`record__user-status-student ${
              userActive(user.last_activity)
                ? "active-status"
                : "non-active-status"
            }`}
          >
            {userActive(user.last_activity) ? "Активный" : "Неактивный"}
          </h5>
        </td>
        <td className="record__arrow-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z" />
          </svg>
        </td>
      </tr>
    ));
  };

  return (
    <div className="students-list">
      {isLoading ? (
        <div className="students-list__container-loader">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      ) : searchStudentNull ? (
        <h4 className="students-list__students-null">Нет данных</h4>
      ) : (
        <table>
          <tbody>{renderStudents()}</tbody>
        </table>
      )}

      <div className="students-list__bottom-toolbar">
        <div className="students-list__current-range">{currentRange()}</div>

        <div className="students-list__buttons">
          <IconButton
            className={"students-list__left-button"}
            onClick={previousPage}
            disabled={currentPage === 1}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="m432-480 156 156q11 11 11 28t-11 28q-11 11-28 11t-28-11L348-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l184-184q11-11 28-11t28 11q11 11 11 28t-11 28L432-480Z" />
              </svg>
            }
          />
          <IconButton
            className={"students-list__right-button"}
            onClick={nextPage}
            disabled={currentPage === totalPages}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z" />
              </svg>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default StudentsList;
