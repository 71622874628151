import "./schedule.sass";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { Query } from "appwrite";
import { startOfWeek, endOfWeek } from "date-fns";
import { client, databases } from "../../lib/appwrite";

import Sidebar from "../../components/sidebar/Sidebar";
import DateSelector from "../../components/dateSelector/DateSelector";
import Button from "../../components/button/Button";
import IconButton from "../../components/iconButton/IconButton";
import TypeSelector from "../../components/typSelector/TypeSelector";
import ScheduleEntries from "../../components/scheduleEntries/ScheduleEntries";
import TransitionToLesson from "../../components/transitionToLesson/TransitionToLesson";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import ToolbarWeek from "../../components/toolbarWeek/ToolbarWeek";
import SearchInput from "../../components/searchInput/SearchInput";
import { Alert, AlertTitle, Snackbar } from "@mui/material";

// Функция для получения всех возможных уроков
const getAllPossibleLessons = () => {
  const lessons = [];
  for (let module = 1; module <= 3; module++) {
    for (let lesson = 1; lesson <= 15; lesson++) {
      lessons.push(`${module}|${lesson}`);
    }
  }
  return lessons;
};

// Функция для проверки, пройдены ли первые три урока модуля
const getIncompleteFirstThreeLessons = (progress, module) => {
  const incompleteLessons = [];
  for (let lesson = 1; lesson <= 3; lesson++) {
    if (!progress.includes(`${module}|${lesson}`)) {
      incompleteLessons.push(`${module}|${lesson}`);
    }
  }
  return incompleteLessons;
};

const getAvailableLessons = (progressList) => {
  getAllPossibleLessons();
  const completedLessons = new Set();

  // Собираем все пройденные уроки
  progressList.forEach((progress) => {
    completedLessons.add(progress);
  });

  // Определяем текущий модуль и последний пройденный урок
  let lastLesson = null;
  progressList.forEach((progress) => {
    const [module, lesson] = progress.split("|").map(Number);
    if (
      !lastLesson ||
      module > lastLesson.module ||
      (module === lastLesson.module && lesson > lastLesson.lesson)
    ) {
      lastLesson = { module, lesson };
    }
  });
  const currentModule = lastLesson ? lastLesson.module : 1;

  // Собираем все неполные первые три урока
  let incompleteFirstThreeLessons = getIncompleteFirstThreeLessons(
    progressList,
    currentModule
  );

  // Убираем дубликаты из неполных первых трех уроков
  incompleteFirstThreeLessons = [...new Set(incompleteFirstThreeLessons)];

  // Если есть неполные первые три урока, возвращаем их
  if (incompleteFirstThreeLessons.length > 0) {
    return incompleteFirstThreeLessons.sort((a, b) => {
      const [, aLesson] = a.split("|").map(Number);
      const [, bLesson] = b.split("|").map(Number);
      return aLesson - bLesson;
    });
  }

  // Собираем доступные уроки для текущего модуля
  const availableLessons = [];
  for (let lesson = 1; lesson <= 15; lesson++) {
    const lessonId = `${currentModule}|${lesson}`;
    if (!completedLessons.has(lessonId)) {
      availableLessons.push(lessonId);
    }
  }

  // Если все уроки текущего модуля пройдены, добавляем первый урок следующего модуля
  if (availableLessons.length === 0 && currentModule < 3) {
    availableLessons.push(`${currentModule + 1}|1`);
  }
  return availableLessons;
};

const updateScheduleItems = (scheduleItems, lessons) => {
  return scheduleItems.map((item) => {
    if (lessons) {
      const currentDateTime = new Date();

      // Преобразуем date_start и time_start в объект Date
      const lessonDate = new Date(item.date_start);
      lessonDate.setHours(10 + item.time_start); // Время начинается с 10 часов
      lessonDate.setMinutes(0);
      lessonDate.setSeconds(0);

      // Рассчитываем время окончания урока
      const lessonEndDate = new Date(lessonDate.getTime());
      lessonEndDate.setMinutes(lessonEndDate.getMinutes() + item.duration * 60);

      // Проверяем, прошло ли время урока
      const isLessonPast = currentDateTime > lessonEndDate;

      if (isLessonPast || item.type_lesson === "trial") {
        item.lessonIsNotAvailable = true;
      } else if (item.available_lessons.length === 0) {
        item.lessonIsNotAvailable = false;
      } else {
        // Проверяем, есть ли хотя бы один общий элемент в available_lessons и lessons
        item.lessonIsNotAvailable = !item.available_lessons.some((lesson) =>
          lessons.includes(lesson)
        );
      }
    } else {
      delete item.lessonIsNotAvailable;
    }
    return item;
  });
};

const Schedule = () => {
  const navigate = useNavigate();
  // Состояния
  const toolbarRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSticky, setIsSticky] = useState(false);
  const [openViewingLesson, setOpenViewingLesson] = useState(false);
  const [isLoadingSearch, setLoadingSearch] = useState(false);
  const [isStudentNotFound, setStudentNotFound] = useState(false);
  const [isAlertError, setAlertError] = useState(false);
  // Переменные
  const [currentDateSchedule, setCurrentDateSchedule] = useState(() => {
    const start = new Date();
    start.setHours(0, 0, 0, 0); // Устанавливаем начало дня
    return start;
  });
  const [scheduleType, setScheduleType] = useState("day");
  const [scheduleItems, setScheduleItems] = useState([]);
  const [dataLessonOpen, setDataLessonOpen] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [studentsList, setStudentsList] = useState([]);
  const [isAvalibleLessons, setAvalibleLessons] = useState("");
  const [isErrorMessage, setErrorMessage] = useState(["", ""]);
  const typeSelectorOptions = [
    { value: "day", label: "День" },
    { value: "week", label: "Неделя" },
  ];

  // Сегодняшняя дата
  const currentDate = new Date();
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = currentDate.toLocaleDateString("ru-RU", options);

  const fetchDataWithPagination = async ({
    collectionId,
    gte,
    lte,
    limit = 25,
    offset = 0,
  }) => {
    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        collectionId,
        [
          Query.equal("city", "voronezh"),
          Query.greaterThanEqual("date_start", new Date(gte).toISOString()),
          Query.lessThanEqual("date_start", new Date(lte).toISOString()),
        ],
        limit,
        offset
      );
      return response.documents;
    } catch (error) {
      console.error("Ошибка при получении данных с пагинацией:", error);
      setErrorMessage(["Ошибка при получении данных", error.message]);
      setAlertError(true);
      return [];
    }
  };

  const getAllDocuments = useCallback(async (collectionId, gte, lte) => {
    let allDocuments = [];
    let offset = 0;
    const limit = 25; // Можно изменить в зависимости от ограничений API

    while (true) {
      const documents = await fetchDataWithPagination({
        collectionId,
        gte,
        lte,
        limit,
        offset,
      });
      allDocuments = allDocuments.concat(documents);
      if (documents.length < limit) {
        // Если количество полученных документов меньше лимита, значит это последняя страница
        break;
      }
      offset += limit; // Увеличиваем смещение для следующего запроса
    }

    return allDocuments;
  }, []);

  const handleUpdateData = useCallback(async () => {
    setIsLoading(true);
    const startDate = startOfWeek(currentDateSchedule, { weekStartsOn: 1 });
    const endDate = endOfWeek(currentDateSchedule, { weekStartsOn: 1 });

    try {
      const scheduleItems = await getAllDocuments(
        "66143d053c25fc1c3d4d",
        startDate,
        endDate
      );

      const lessons = updateScheduleItems(
        scheduleItems,
        isAvalibleLessons || null
      );
      setScheduleItems(lessons);
    } catch (error) {
      console.error("Неожиданная ошибка при обновлении списка:", error);
      setErrorMessage([
        "Неожиданная ошибка при обновлении списка",
        error.message,
      ]);
      setAlertError(true);
    } finally {
      setIsLoading(false);
    }
  }, [currentDateSchedule, isAvalibleLessons, getAllDocuments]);

  useEffect(() => {
    handleUpdateData();
  }, [currentDateSchedule, scheduleType, isAvalibleLessons, handleUpdateData]);

  useEffect(() => {
    document.title = "Расписание занятий";
  }, []);

  // Анимация у Toolbar
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (toolbarRef.current) {
        const { top } = toolbarRef.current.getBoundingClientRect();
        const shouldBeSticky = top <= 0;
        if (shouldBeSticky !== isSticky) {
          setIsSticky(shouldBeSticky);
        }
      }
    }, 100);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);

  useEffect(() => {
    handleUpdateData();
  }, [handleUpdateData]);

  // Подписка на изменения в коллекции
  useEffect(() => {
    // Подписка на изменения в коллекции
    const unsubscribe = client.subscribe(
      "databases.65f9d6f4dbeeccbe7e74.collections.66143d053c25fc1c3d4d.documents",
      async () => {
        handleUpdateData();
      }
    );

    // Отписка от событий при размонтировании компонента
    return () => unsubscribe();
  }, [handleUpdateData]);

  // Эффект для блокировки и разблокировки прокрутки
  useEffect(() => {
    const toggleBodyOverflow = (value) => {
      document.body.style.overflow = value;
    };

    if (openViewingLesson) {
      toggleBodyOverflow("hidden");
    } else {
      toggleBodyOverflow("visible");
      setDataLessonOpen("");
    }

    return () => toggleBodyOverflow("visible");
  }, [openViewingLesson]);

  const handleSelectionChange = useCallback((selectedValue) => {
    setScheduleType(selectedValue);
  }, []);

  const handleOpewCreatorLesson = useCallback(() => {
    navigate("/admin/schedule/creator-lesson");
  }, [navigate]);

  const handleOpenViewingLesson = useCallback((item) => {
    setOpenViewingLesson((prevState) => {
      if (!prevState) {
        setDataLessonOpen(item);
      }
      return true;
    });
  }, []);

  useEffect(() => {
    // Таймер для задержки поиска
    const username = searchTerm.split(" ");
    const timerId = setTimeout(() => {
      if (
        username[0] !== selectedStudent.name ||
        username[1] !== selectedStudent.sur_name
      ) {
        setDebouncedSearchTerm(searchTerm);
      } else {
        setStudentsList([]);
      }
    }, 500); // Задержка в 500 мс

    if (!searchTerm) {
      setStudentsList([]);
      setSelectedStudent("");
      setAvalibleLessons("");
      setStudentNotFound(false);
      setScheduleItems(updateScheduleItems(scheduleItems, null));
    }

    if (
      username[0] !== selectedStudent.name &&
      username[1] !== selectedStudent.sur_name
    ) {
      setStudentsList([]);
    }

    // Очистка таймера при размонтировании компонента или изменении searchTerm
    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  // Выполнение поиска с использованием debouncedSearchTerm
  useEffect(() => {
    if (debouncedSearchTerm) {
      searchStudents("voronezh", debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const searchStudents = async (city, searchTerm) => {
    setLoadingSearch(true);
    try {
      // Массив промисов для каждого поля поиска
      const searchPromises = [
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("name", searchTerm),
          ]
        ),
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("sur_name", searchTerm),
          ]
        ),
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("phone_number", searchTerm),
          ]
        ),
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("user_id", searchTerm),
          ]
        ),
      ];

      // Выполнение всех запросов и объединение результатов
      const results = await Promise.all(searchPromises);
      const documents = results
        .flatMap((result) => result.documents)
        // Удаление дубликатов документов
        .filter(
          (doc, index, self) =>
            index === self.findIndex((t) => t.$id === doc.$id)
        );
      if (documents.length > 0) {
        setStudentsList(documents);
        setStudentNotFound(false);
      } else {
        setStudentNotFound(true);
      }
    } catch (error) {
      console.error("Ошибка при поиске студентов:", error);
      setErrorMessage(["Ошибка при поиске студентов", error.message]);
      setAlertError(true);
    } finally {
      setLoadingSearch(false);
    }
  };

  const searchAvailableLessons = () => {
    if (selectedStudent) {
      const lessons = getAvailableLessons(selectedStudent.progress);
      // console.log("Прогресс", selectedStudent.progress);
      // console.log("Доступные уроки:", lessons);

      setAvalibleLessons(lessons);
    }
  };

  return (
    <div className="schedule desktop-version">
      <Sidebar />
      <div className="main-container-pages">
        <div className="main-container-pages__container">
          <div className="main-container-pages__texts">
            <h2 className="main-container-pages__title">Расписание</h2>
            <h3 className="main-container-pages__date">{formattedDate}</h3>
          </div>
          <div className="toolbar-search-available-lesson">
            <SearchInput
              className={"toolbar-search-available-lesson__search"}
              holderText={
                "Выберите ученика из списка, чтобы найти доступные уроки для записи"
              }
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              icon={
                isLoadingSearch ? (
                  <Stack sx={{ color: "#0065FD" }} direction="row">
                    <CircularProgress color="inherit" size={20} thickness={5} />
                  </Stack>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                  >
                    <path d="M380-320q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l224 224q11 11 11 28t-11 28q-11 11-28 11t-28-11L532-372q-30 24-69 38t-83 14Zm0-80q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
                  </svg>
                )
              }
            />

            {studentsList.length > 0 && (
              <ul className="toolbar-search-available-lesson__drop-down-list">
                {studentsList.map((user, index) => (
                  <li
                    className="toolbar-search-available-lesson__list-item"
                    key={index}
                    onClick={() => {
                      setSelectedStudent(user);
                      setSearchTerm(`${user.name} ${user.sur_name}`);
                      setStudentsList([]);
                    }}
                  >
                    <h5 className="toolbar-search-available-lesson__item-title">
                      {user.name} {user.sur_name}
                    </h5>
                    <span className="toolbar-search-available-lesson__item-subtitle">
                      {user.phone_number}
                    </span>
                  </li>
                ))}
              </ul>
            )}
            {isStudentNotFound && (
              <div className="toolbar-search-available-lesson__student-not-found">
                Не найдено
              </div>
            )}

            <Button
              colorStyle={"blue"}
              className={"toolbar-search-available-lesson__button-search"}
              disabled={!selectedStudent}
              onClick={() => searchAvailableLessons()}
            >
              Найти урок
            </Button>
            <TypeSelector
              options={typeSelectorOptions}
              initialSelected="day"
              onSelectType={(value) => handleSelectionChange(value)}
            />
          </div>
          <div className="calendar">
            {isLoading && (
              <div className="loading-container-calendar">
                <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
                  <CircularProgress color="inherit" />
                </Stack>
              </div>
            )}
            <div
              className="toolbar-calendar"
              ref={toolbarRef}
              style={{
                borderRadius: isSticky ? "0" : "12px 12px 0 0",
              }}
            >
              <div className="toolbar-calendar__top">
                <DateSelector
                  date={currentDateSchedule}
                  setDateCurrent={setCurrentDateSchedule}
                  format={scheduleType}
                />
                <div className="toolbar-calendar__buttons-right">
                  <Button
                    className={"toolbar-calendar__button-new-session"}
                    colorStyle={"blue"}
                    onClick={handleOpewCreatorLesson}
                  >
                    Новая запись
                  </Button>
                  <IconButton
                    onClick={handleUpdateData}
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-70q0-17 11.5-28.5T760-800q17 0 28.5 11.5T800-760v200q0 17-11.5 28.5T760-520H560q-17 0-28.5-11.5T520-560q0-17 11.5-28.5T560-600h128q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q68 0 124.5-34.5T692-367q8-14 22.5-19.5t29.5-.5q16 5 23 21t-1 30q-41 80-117 128t-169 48Z" />
                      </svg>
                    }
                  />
                </div>
              </div>
              {scheduleType === "week" && (
                <div className="toolbar-calendar__bottom ">
                  <ToolbarWeek currentDateSchedule={currentDateSchedule} />
                </div>
              )}
            </div>
            <ScheduleEntries
              items={scheduleItems}
              currentDate={currentDateSchedule}
              typeSession={scheduleType}
              handleItemClick={handleOpenViewingLesson}
              userRole={"admin"}
            />
          </div>
        </div>
        {openViewingLesson && (
          <TransitionToLesson
            setViewOpen={setOpenViewingLesson}
            data={dataLessonOpen}
          />
        )}
      </div>
      <Snackbar
        open={isAlertError}
        autoHideDuration={6000}
        onClose={() => setAlertError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAlertError(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <AlertTitle>{isErrorMessage[0]}</AlertTitle>
          {isErrorMessage[1]}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Schedule;
