import "./infoOfLesson.sass";
import React, { useState, useEffect, useCallback } from "react";
import { databases } from "../../lib/appwrite";
import { Query, ID } from "appwrite";
import { useNavigate } from "react-router-dom";
import Button from "../button/Button";
import Input from "../input/Input";
import InputMultiline from "../input/InputMultiline";
import OptionMenu from "../optionMenu/OptionMenu";
import ErrorMessageTwo from "../errorMessage/ErrorMessageTwo";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useStudents } from "../../context/StudentsContext";
import { Alert, AlertTitle, Snackbar } from "@mui/material";

const lessonDurationSelect = [
  { value: "", label: "Выберите..." },
  { value: 1, label: "1 час" },
  { value: 1.5, label: "1,5 часа" },
  { value: 2, label: "2 часа" },
  { value: 2.5, label: "2,5 часа" },
  { value: 3, label: "3 часа" },
];
const numberModuleOptions = [
  { value: "", label: "Выберите..." },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
];
const numberLessonOptions = [
  { value: "", label: "Выберите..." },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
];
const typeLessons = [
  { value: "", label: "Выберите..." },
  { value: "group", label: "Групповой урок" },
  { value: "indiv", label: "Индивидуальный урок" },
  { value: "acter", label: "Актерское мастерство" },
  { value: "trial", label: "Пробный урок" },
];
const time = [
  { value: "", label: "Выберите..." },
  { value: 0, label: "10:00" },
  { value: 1, label: "11:00" },
  { value: 2, label: "12:00" },
  { value: 3, label: "13:00" },
  { value: 4, label: "14:00" },
  { value: 5, label: "15:00" },
  { value: 6, label: "16:00" },
  { value: 7, label: "17:00" },
  { value: 8, label: "18:00" },
  { value: 9, label: "19:00" },
  { value: 10, label: "20:00" },
  { value: 11, label: "21:00" },
];

// Функция для форматирования номера телефона
const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.length !== 12) {
    console.error("Неверная длина номера телефона:", phoneNumber);
    return phoneNumber; // Возвращаем оригинальный номер, если длина неверна
  }

  const parts = phoneNumber.split("");
  return `${parts.slice(0, 2).join("")} (${parts.slice(2, 5).join("")}) ${parts
    .slice(5, 8)
    .join("")}-${parts.slice(8, 10).join("")}-${parts.slice(10).join("")}`;
};

function checkStudentsProgress(
  students,
  lessonType,
  moduleNumber,
  lessonNumber,
  totalLessonsPerModule = 15
) {
  const results = [];

  // Предварительные вычисления для всех студентов
  const previousModuleLessons = Array.from(
    { length: totalLessonsPerModule },
    (_, i) => `${moduleNumber - 1}|${i + 1}`
  );
  const firstThreeCurrentModuleLessons = [1, 2, 3].map(
    (l) => `${moduleNumber}|${l}`
  );

  students.forEach((student) => {
    const { uid, progress, progressReserved } = student;
    const completedLessons = new Set(
      progress.map((p) => p.split("|").map(Number).join("|"))
    );

    const reservProgress = new Set(
      progressReserved
        ? progressReserved.map((p) => p.split("|").map(Number).join("|"))
        : []
    );

    const errors = checkForErrors(
      completedLessons,
      lessonType,
      moduleNumber,
      lessonNumber,
      totalLessonsPerModule,
      previousModuleLessons,
      firstThreeCurrentModuleLessons,
      reservProgress
    );
    if (errors) {
      results.push({ uid, ...errors });
    }
  });

  return results;
}

function checkForErrors(
  completedLessons,
  lessonType,
  moduleNumber,
  lessonNumber,
  totalLessonsPerModule,
  previousModuleLessons,
  firstThreeCurrentModuleLessons,
  reservProgress
) {
  const hasCompletedThisLesson = completedLessons.has(
    `${moduleNumber}|${lessonNumber}`
  );
  const firstThreeLessonsCompleted = firstThreeCurrentModuleLessons.every((l) =>
    completedLessons.has(l)
  );
  const previousModuleCompleted = previousModuleLessons.every((l) =>
    completedLessons.has(l)
  );

  const hasThisLessonWaiting = reservProgress.has(
    `${moduleNumber}|${lessonNumber}`
  );

  if (
    completedLessons.size === 0 &&
    !(moduleNumber === 1 && lessonNumber === 1)
  ) {
    return {
      error: "Можно записаться только на первый урок первого модуля",
      errorType: "high",
    };
  } else if (hasCompletedThisLesson) {
    return { error: "Урок уже пройден", errorType: "medium" };
  } else if (!previousModuleCompleted && moduleNumber > 1) {
    return { error: "Предыдущий модуль не завершен", errorType: "high" };
  } else if (!firstThreeLessonsCompleted && lessonNumber > 3) {
    return {
      error: "Необходимо пройти первые три урока модуля",
      errorType: "high",
    };
  } else if (lessonType === "indiv" && !(lessonNumber >= 12)) {
    return {
      error: "Индивидуальные занятия возможны только с 12 урока",
      errorType: "high",
    };
  } else if (hasThisLessonWaiting) {
    return { error: "Ученик уже записан на данный урок", errorType: "high" };
  }

  return null;
}

// Функция для получения всех возможных уроков
const getAllPossibleLessons = () => {
  const lessons = [];
  for (let module = 1; module <= 3; module++) {
    for (let lesson = 1; lesson <= 15; lesson++) {
      lessons.push(`${module}|${lesson}`);
    }
  }
  return lessons;
};

// Функция для проверки, пройдены ли первые три урока модуля
const getIncompleteFirstThreeLessons = (progress, module) => {
  const incompleteLessons = [];
  for (let lesson = 1; lesson <= 3; lesson++) {
    if (!progress.includes(`${module}|${lesson}`)) {
      incompleteLessons.push(`${module}|${lesson}`);
    }
  }
  return incompleteLessons;
};

const getAvailableLessons = (progressLists) => {
  getAllPossibleLessons();
  const completedLessons = new Set();

  // Собираем все пройденные уроки
  progressLists.forEach((progressList) => {
    progressList.forEach((progress) => {
      completedLessons.add(progress);
    });
  });

  // Определяем текущий модуль и последний пройденный урок для каждого ученика
  const currentModules = progressLists.map((progressList) => {
    let lastLesson = null;
    progressList.forEach((progress) => {
      const [module, lesson] = progress.split("|").map(Number);
      if (
        !lastLesson ||
        module > lastLesson.module ||
        (module === lastLesson.module && lesson > lastLesson.lesson)
      ) {
        lastLesson = { module, lesson };
      }
    });
    return lastLesson ? lastLesson.module : 1;
  });

  // Проверяем, находятся ли все ученики в одном модуле
  const uniqueModules = new Set(currentModules);
  if (uniqueModules.size !== 1) {
    return []; // Ученики в разных модулях, возвращаем пустой список
  }

  const currentModule = currentModules[0];

  // Собираем все неполные первые три урока для каждого ученика
  let incompleteFirstThreeLessons = [];
  progressLists.forEach((progressList) => {
    incompleteFirstThreeLessons = [
      ...incompleteFirstThreeLessons,
      ...getIncompleteFirstThreeLessons(progressList, currentModule),
    ];
  });

  // Убираем дубликаты из неполных первых трех уроков
  incompleteFirstThreeLessons = [...new Set(incompleteFirstThreeLessons)];

  // Если есть неполные первые три урока, возвращаем их
  if (incompleteFirstThreeLessons.length > 0) {
    return incompleteFirstThreeLessons.sort((a, b) => {
      const [, aLesson] = a.split("|").map(Number);
      const [, bLesson] = b.split("|").map(Number);
      return aLesson - bLesson;
    });
  }

  // Собираем доступные уроки для текущего модуля
  const availableLessons = [];
  for (let lesson = 1; lesson <= 15; lesson++) {
    const lessonId = `${currentModule}|${lesson}`;
    if (!completedLessons.has(lessonId)) {
      availableLessons.push(lessonId);
    }
  }

  // Если все уроки текущего модуля пройдены, добавляем первый урок следующего модуля
  if (availableLessons.length === 0 && currentModule < 3) {
    availableLessons.push(`${currentModule + 1}|1`);
  }

  return availableLessons;
};

// Функция для добавления "(Рекомендуется)" к опциям модулей
const addRecommendedLabelToModules = (options, recommendedValues) => {
  return options.map((option) => {
    if (recommendedValues.includes(option.value.toString())) {
      return { ...option, label: `${option.label} (Рекомендуется)` };
    }
    return option;
  });
};

// Функция для добавления "(Рекомендуется)" к опциям уроков с учетом типа урока
const addRecommendedLabelToLessons = (options, recommendedValues, type) => {
  return options.map((option) => {
    const isRecommended =
      recommendedValues.includes(option.value.toString()) &&
      (type === "group" ||
        (type === "indiv" && option.value >= "12") ||
        type === "");

    if (isRecommended) {
      return { ...option, label: `${option.label} (Рекомендуется)` };
    }
    return option;
  });
};

const InfoOfLesson = ({
  setMaxStudents,
  maxStudents,
  lessonData,
  isLoadingCreator,
  setLoadingCreator,
  lessonType,
}) => {
  const { selectedStudentsLesson } = useStudents();
  const navigate = useNavigate();
  // Состояния
  const [isLoading, setIsLoading] = useState(true);
  const [lessonEdit, setLessonEdit] = useState(false);
  //Значения переменных
  const [users, setUsers] = useState([]);
  const [dateLesson, setDateLesson] = useState("");
  const [timeStartLesson, setTimeStartLesson] = useState("");
  const [lessonDuration, setLessonDuration] = useState(0);
  const [typeLesson, setTypeLesson] = useState("");
  const [lessonTeacher, setLessonTeacher] = useState("");
  const [moduleLesson, setModuleLesson] = useState("");
  const [numberLesson, setNumberLesson] = useState("");
  const [comment, setComment] = useState("");
  const [availableLessons, setAvailableLessons] = useState([]);
  const [isAlertError, setAlertError] = useState(false);
  const [isErrorMessageAlert, setErrorMessageAlert] = useState(["", ""]);
  const [isNumberModuleOptions, setNumberModuleOptions] = useState([
    { value: "", label: "Выберите..." },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
  ]);
  const [isNumberLessonOptions, setNumberLessonOptions] = useState([
    { value: "", label: "Выберите..." },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
  ]);

  let teachers = [];
  teachers = users.map((user) => ({
    value: user.user_id,
    label: `${user.name} ${user.sur_name}`,
  }));
  teachers.unshift({
    value: "",
    label: "Выберите...",
  });

  // Состояния ошибок
  const [errorsStudentProgress, setErrorsStudentProgress] = useState([]);
  const [errorsStudentTrial, setErrorsStudentTrial] = useState([]);
  const [inputErrors, setInputErrors] = useState({});
  const [errorsInputs, setErrorsInputs] = useState(false);

  useEffect(() => {
    lessonType(typeLesson);
  }, [typeLesson]);

  useEffect(() => {
    const userInfo = selectedStudentsLesson.map((user) => ({
      uid: user.data.$id,
      progress: user.data.progress,
      trialLessonAvailable: user.data.trial_lesson_available,
      progressReserved: user.data.progressReserved,
    }));

    if (
      userInfo.length > 0 &&
      typeLesson !== "" &&
      moduleLesson &&
      numberLesson
    ) {
      const errors = checkStudentsProgress(
        userInfo,
        typeLesson,
        Number(moduleLesson),
        Number(numberLesson)
      );
      setErrorsStudentProgress(errors);
    }

    if (userInfo.length > 0 && typeLesson === "trial") {
      const errorsTrial = userInfo
        .filter(
          (user) =>
            user.trialLessonAvailable === false ||
            user.trialLessonAvailable === null
        )
        .map((user) => ({
          uid: user.uid,
          error: "Пробный урок недоступен",
          errorType: "high",
        }));

      setErrorsStudentTrial(errorsTrial);
    }
  }, [selectedStudentsLesson, typeLesson, moduleLesson, numberLesson]);

  useEffect(() => {
    if (lessonData.length !== 0) {
      const {
        date_start,
        time_start,
        duration,
        type_lesson,
        teacher,
        lesson_module,
        class_number,
        comment,
      } = lessonData;
      setDateLesson(new Date(date_start).toISOString().split("T")[0]);
      setTimeStartLesson(time_start);
      setLessonDuration(duration);
      setTypeLesson(type_lesson);
      setLessonTeacher(teacher);
      setModuleLesson(lesson_module);
      setNumberLesson(class_number);
      setComment(comment);
      setLessonEdit(true);
    }
  }, [lessonData]);

  const validateInputs = () => {
    const errors = {};
    const requiredFields = [
      { field: dateLesson, key: "dateLesson" },
      { field: timeStartLesson, key: "timeStartLesson" },
      { field: lessonDuration, key: "lessonDuration" },
      { field: typeLesson, key: "typeLesson" },
    ];

    requiredFields.forEach(({ field, key }) => {
      if (!field || field === "") {
        errors[key] = true;
      }
    });

    if (
      selectedStudentsLesson.length > 0 &&
      !moduleLesson &&
      typeLesson !== "acter" &&
      typeLesson !== "trial"
    ) {
      errors.moduleLesson = true;
    }

    if (
      selectedStudentsLesson.length > 0 &&
      !numberLesson &&
      typeLesson !== "acter" &&
      typeLesson !== "trial"
    ) {
      errors.numberLesson = true;
    }

    if (selectedStudentsLesson.length > maxStudents) {
      errors.maxStudentLimit = true;
    }

    setInputErrors(errors);
    const hasError = Object.keys(errors).length > 0;
    setErrorsInputs(hasError);

    return hasError;
  };

  // Функция для получения данных по ролям из базы данных
  const fetchDataByRole = async (role) => {
    const databaseId = "65f9d6f4dbeeccbe7e74"; // Идентификатор базы данных
    const collectionId = "65f9d6fd218cc728529a"; // Идентификатор коллекции

    try {
      const response = await databases.listDocuments(databaseId, collectionId, [
        Query.equal("role", role),
      ]);
      // Добавляем роль к каждому документу в ответе
      return response.documents.map((doc) => ({ ...doc, role }));
    } catch (error) {
      console.error("Ошибка при получении данных по роли:", error);
      setErrorMessageAlert(["Ошибка при получении данных", error.message]);
      setAlertError(true);
      return [];
    }
  };

  const createNewSession = async () => {
    setIsLoading(true);
    setLoadingCreator(true);

    const hasErrors = validateInputs();

    if (hasErrors) {
      setIsLoading(false);
      setLoadingCreator(false);
      return;
    }

    const teacherName = lessonTeacher || "";

    const data = {
      date_start: new Date(dateLesson).toISOString(),
      time_start: Number(timeStartLesson),
      duration: Number(lessonDuration),
      type_lesson: typeLesson,
      teacher: teacherName,
      students: selectedStudentsLesson.map((student) => student.uid),
      lesson_module:
        typeLesson === "acter" || typeLesson === "trial"
          ? 0
          : Number(moduleLesson),
      class_number:
        typeLesson === "acter" || typeLesson === "trial"
          ? 0
          : Number(numberLesson),
      city: "voronezh",
      comment: comment,
      available_lessons: availableLessons,
    };

    try {
      if (lessonEdit) {
        await databases.updateDocument(
          "65f9d6f4dbeeccbe7e74",
          "66143d053c25fc1c3d4d",
          lessonData.$id,
          data
        );
      } else {
        await databases.createDocument(
          "65f9d6f4dbeeccbe7e74",
          "66143d053c25fc1c3d4d",
          ID.unique(),
          data
        );
      }

      handleCloseCreator();
    } catch (err) {
      console.error(err);
      setErrorMessageAlert(["Ошибка сохранении урока", err.message]);
      setAlertError(true);
    } finally {
      setIsLoading(false);
      setLoadingCreator(false);
    }
  };

  const handleCloseCreator = () => {
    navigate("/admin/schedule");
  };

  // Обновление списка учителей
  const handleUpdate = useCallback(async () => {
    setIsLoading(true);
    try {
      const dataTeacher = await fetchDataByRole("teacher");
      setUsers(dataTeacher);
    } catch (error) {
      console.error(
        "Неожиданная ошибка при обновлении списка пользователей:",
        error
      );
      setErrorMessageAlert([
        "Ошибка обновления списка учителей",
        error.message,
      ]);
      setAlertError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const selectStudentList = () => {
    return (
      <div className="record-selected-user-list">
        {selectedStudentsLesson.map((user) => (
          <div className="record-selected-user-list__record" key={user.uid}>
            {user.data.avatar_image ? (
              <img
                className="record-selected-user-list__avatar"
                src={user.data.avatar_image}
                alt="Avatar"
              />
            ) : (
              <div className="record-selected-user-list__avatar"></div>
            )}

            <div className="record-selected-user-list__data-container">
              <h4 className="record-selected-user-list__username">{`${user.data.name} ${user.data.sur_name}`}</h4>
              <h4 className="record-selected-user-list__phone">
                {formatPhoneNumber(user.data.phone_number)}
              </h4>
            </div>

            <ErrorMessageTwo
              className={"record-selected-user-list__error-message"}
              textMessage={
                typeLesson === "trial"
                  ? errorsStudentTrial.find((err) => err.uid === user.uid)
                      ?.error
                  : errorsStudentProgress.find((err) => err.uid === user.uid)
                      ?.error
              }
              typeError={
                typeLesson === "trial"
                  ? errorsStudentTrial.find((err) => err.uid === user.uid)
                      ?.errorType
                  : errorsStudentProgress.find((err) => err.uid === user.uid)
                      ?.errorType
              }
            />
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    handleUpdate();
  }, [handleUpdate]);

  // Максимальное количесто учеников
  useEffect(() => {
    if (typeLesson === "group" || typeLesson === "trial" || typeLesson === "" || typeLesson === "acter") {
      setMaxStudents(5);
    } else {
      setMaxStudents(1);
    }
  }, [typeLesson, setMaxStudents]);

  useEffect(() => {
    const progressLists = selectedStudentsLesson.map(
      (student) => student.data.progress
    );

    const lessons = getAvailableLessons(progressLists);

    setAvailableLessons(lessons);

    // console.log("Прогресс", progressLists);
    // console.log("Доступные уроки:", lessons);
  }, [selectedStudentsLesson]);

  useEffect(() => {
    const recommendedModules = [
      ...new Set(availableLessons.map((lesson) => lesson.split("|")[0])),
    ];

    const recommendedLessons = availableLessons
      .filter((lesson) => lesson.startsWith(moduleLesson))
      .map((lesson) => lesson.split("|")[1]);

    const progressReserved = selectedStudentsLesson.map(
      (student) => student.data.progressReserved
    );

    // Объединяем все массивы progressReserved в один массив
    const allProgressReserved = [].concat(...progressReserved);

    // Фильтруем уроки, которые не должны быть помечены как "Рекомендуется"
    const filteredRecommendedLessons = recommendedLessons.filter(
      (lesson) => !allProgressReserved.includes(`${moduleLesson}|${lesson}`)
    );

    // Добавляем метку "(Рекомендуется)" к опциям модулей
    setNumberModuleOptions(
      addRecommendedLabelToModules(numberModuleOptions, recommendedModules)
    );

    // Добавляем метку "(Рекомендуется)" к опциям уроков с учетом типа урока
    setNumberLessonOptions(
      addRecommendedLabelToLessons(
        numberLessonOptions,
        filteredRecommendedLessons,
        typeLesson
      )
    );
  }, [availableLessons, typeLesson, moduleLesson, selectedStudentsLesson]);

  return (
    <div className={`container-info-of-lesson`}>
      {isLoadingCreator || isLoading ? (
        <div className="loading-container-info">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      ) : (
        <></>
      )}
      <div className={`container-info-of-lesson__toolbar-container`}>
        <h4 className="container-info-of-lesson__toolbar-title">Информация</h4>
      </div>
      <div
        className="container-info-of-lesson__lesson-info"
        id="containerInfoNewLesson"
      >
        <form
          className="lesson-info"
          autoComplete="off"
          onSubmit={createNewSession}
        >
          <OptionMenu
            id={"typeLessonSelect"}
            options={typeLessons}
            title={"Вид урока"}
            value={typeLesson}
            className={"lesson-info__type-lesson-select"}
            classSelect={inputErrors.typeLesson ? "error" : ""}
            onChange={(e) => setTypeLesson(e.target.value)}
          />
          <OptionMenu
            id={"techerSelect"}
            options={teachers}
            title={"Преподаватель"}
            value={lessonTeacher}
            className={"lesson-info__teacher-select"}
            classSelect={inputErrors.lessonTeacher ? "error" : ""}
            onChange={(e) => setLessonTeacher(e.target.value)}
          />
          <div className="lesson-info__container-date">
            <Input
              title={"Дата"}
              id={"dateLesson"}
              className={`lesson-info__date-input ${
                inputErrors.dateLesson ? "error" : ""
              }`}
              type={"date"}
              value={dateLesson}
              onChange={(e) => setDateLesson(e.target.value)}
              placeholder={"Дата урока"}
            />

            <OptionMenu
              id={"startTimeLessonSelect"}
              options={time}
              title={"Время"}
              value={timeStartLesson}
              className={"lesson-info__time-input"}
              classSelect={inputErrors.timeStartLesson ? "error" : ""}
              onChange={(e) => setTimeStartLesson(e.target.value)}
            />

            <OptionMenu
              id={"durationLessonSelect"}
              options={lessonDurationSelect}
              title={"Длительность"}
              value={lessonDuration}
              className={"lesson-info__duration-lesson"}
              classSelect={inputErrors.lessonDuration ? "error" : ""}
              onChange={(e) => setLessonDuration(e.target.value)}
            />
          </div>
          {typeLesson !== "acter" && typeLesson !== "trial" ? (
            <div className="lesson-info__container-number-lesson">
              <OptionMenu
                id={"moduleLessonSelect"}
                options={isNumberModuleOptions}
                title={"Модуль"}
                value={moduleLesson}
                className={"lesson-info__module-lesson-input"}
                onChange={(e) => setModuleLesson(e.target.value)}
              />
              <OptionMenu
                id={"numberLessonSelect"}
                options={isNumberLessonOptions}
                title={"Урок"}
                value={numberLesson}
                className={"lesson-info__number-lesson-input"}
                onChange={(e) => setNumberLesson(e.target.value)}
              />
            </div>
          ) : (
            <></>
          )}
          <h3 className="lesson-info__title-students">Ученик(-и)</h3>
          <div className="lesson-info__students">
            {selectedStudentsLesson.length === 0 ? (
              <div className="lesson-info__holder-text-students">
                <h4 className="lesson-info__placeholder-students">
                  Выберите учеников справа
                </h4>
                <i className="lesson-info__placeholder-students-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    viewBox="0 -960 960 960"
                    width="20"
                  >
                    <path d="M630-444H228.049q-15.321 0-25.685-10.289Q192-464.579 192-479.789 192-495 202.364-505.5q10.364-10.5 25.685-10.5H630l-80-80q-11-11-11-25.5t10.522-25.5Q561-658 575.5-658q14.5 0 25.5 11l142.333 142.333Q748-500 750.5-493.7q2.5 6.3 2.5 13.5t-2.5 13.5q-2.5 6.3-7.5 11.7L601-313q-11 11-25 10.5T550.522-314Q540-325 540-339.5t11-25.5l79-79Z" />
                  </svg>
                </i>
              </div>
            ) : (
              selectStudentList(selectedStudentsLesson)
            )}
          </div>
          <InputMultiline
            title={"Комментарий"}
            id={"commentSession"}
            className={`lesson-info__comment-input`}
            type={"text"}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={"Комментарий к уроку"}
            rows={"20"}
            cols={"20"}
          />
        </form>
      </div>
      <div className="container-info-of-lesson__bottom-toolbar">
        <span className="container-info-of-lesson__error-message-bottom-toolbar">
          {errorsInputs
            ? inputErrors.maxStudentLimit
              ? "Превышен лимит количества учеников!"
              : "Заполните все поля!"
            : ""}
        </span>
        <div className="container-info-of-lesson__buttons">
          <Button
            colorStyle={"invisible"}
            className={"container-info-of-lesson__button-canel"}
            onClick={handleCloseCreator}
          >
            Отмена
          </Button>
          <Button
            colorStyle={"blue"}
            className={"container-info-of-lesson__button-save"}
            onClick={createNewSession}
            disabled={
              !typeLesson ||
              !dateLesson ||
              !timeStartLesson ||
              !lessonDuration ||
              (errorsStudentTrial.length > 0 &&
                selectedStudentsLesson.length > 0)
            }
          >
            Сохранить
          </Button>
        </div>
      </div>
      <Snackbar
        open={isAlertError}
        autoHideDuration={6000}
        onClose={() => setAlertError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAlertError(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <AlertTitle>{isErrorMessageAlert[0]}</AlertTitle>
          {isErrorMessageAlert[1]}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default InfoOfLesson;
