import { useEffect, useCallback, useState } from "react";
import { startOfWeek, endOfWeek } from "date-fns";
import { Query } from "appwrite";
import { client, databases } from "../lib/appwrite";

const useScheduleData = (currentDateSchedule, userData, userRole) => {
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleItems, setScheduleItems] = useState([]);

  const fetchDataWithPagination = async ({
    collectionId,
    gte,
    lte,
    limit = 25,
    offset = 0,
  }) => {
    try {
      // Создаем базовый массив запросов
      const queries = [
        Query.equal("city", "voronezh"),
        Query.greaterThanEqual("date_start", new Date(gte).toISOString()),
        Query.lessThanEqual("date_start", new Date(lte).toISOString()),
      ];

      // Добавляем условие для учителя только если роль пользователя - учитель и userData загружены
      if (userRole === "teacher" && userData) {
        queries.push(Query.equal("teacher", userData.$id));
      }

      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        collectionId,
        queries,
        limit,
        offset
      );
      return response.documents;
    } catch (error) {
      console.error("Ошибка при получении данных с пагинацией:", error);
      return [];
    }
  };

  const getAllDocuments = async (collectionId, gte, lte) => {
    let allDocuments = [];
    let offset = 0;
    const limit = 25; // Можно изменить в зависимости от ограничений API

    while (true) {
      const documents = await fetchDataWithPagination({
        collectionId,
        gte,
        lte,
        limit,
        offset,
      });
      allDocuments = [...allDocuments, ...documents];
      if (documents.length < limit) {
        // Если количество полученных документов меньше лимита, значит это последняя страница
        break;
      }
      offset += limit; // Увеличиваем смещение для следующего запроса
    }

    return allDocuments;
  };

  const handleUpdateData = useCallback(async () => {
    // Проверяем, что userData загружены для учителя или роль пользователя не учитель
    if (userRole !== "teacher" || (userRole === "teacher" && userData)) {
      setIsLoading(true);
      const startDate = startOfWeek(currentDateSchedule, { weekStartsOn: 1 });
      const endDate = endOfWeek(currentDateSchedule, { weekStartsOn: 1 });

      try {
        const scheduleItems = await getAllDocuments(
          "66143d053c25fc1c3d4d",
          startDate,
          endDate
        );

        setScheduleItems(scheduleItems);
      } catch (error) {
        console.error("Неожиданная ошибка при обновлении списка:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentDateSchedule, userData, userRole]);

  useEffect(() => {
    handleUpdateData();
  }, [handleUpdateData]);

  useEffect(() => {
    handleUpdateData();
  }, [handleUpdateData]);

  useEffect(() => {
    const unsubscribe = client.subscribe(
      "databases.65f9d6f4dbeeccbe7e74.collections.66143d053c25fc1c3d4d.documents",
      handleUpdateData
    );

    return () => unsubscribe();
  }, [handleUpdateData]);

  return { isLoading, scheduleItems };
};

export default useScheduleData;
