import RecordSession from "../recordSession/RecordSession";
import React from "react";
import "./scheduleEntries.sass";
import TimeGrid from "../timeGrid/TimeGrid";

const getCurrentWeekDates = (currentDate) => {
  const startDate = new Date(currentDate);
  const dayOfWeek = startDate.getDay();
  const startOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Если воскресенье, смещаем на -6 дней, иначе на 1 - текущий день недели

  startDate.setDate(startDate.getDate() + startOffset); // Устанавливаем на понедельник текущей недели

  const currentWeek = Array.from({ length: 7 }).map((_, index) => {
    const day = new Date(startDate);
    day.setDate(day.getDate() + index);
    return day;
  });

  return currentWeek;
};


function updateLessonStatus(scheduleItems) {
  const now = new Date();

  scheduleItems.forEach((item) => {
    const dateStart = new Date(item.date_start);

    const adjustedHour =
      item.time_start === 0 ? 10 : (item.time_start % 12) + 10;

    dateStart.setHours(adjustedHour);

    const lessonEndDate = new Date(
      dateStart.getTime() + item.duration * 60 * 60 * 1000
    );

    item.passed = now >= lessonEndDate;
  });
}

const ScheduleWeekColumns = ({ currentDate, items, handleItemClick, userRole }) => (
  <div className="schedule-week-columns">
    {currentDate.map((date, index) => (
      <div className="schedule-week-columns__container" key={index}>
        <RecordSession
          date={date}
          items={items}
          typeSession="week"
          handleItemClick={handleItemClick}
          userRole={userRole}
        />
      </div>
    ))}
  </div>
);

const ScheduleColumns = () => {
  const dates = [1, 2, 3, 4, 5, 6, 7];
  return (
    <div className="schedule-columns">
      {dates.map((date, index) => (
        <div className="schedule-columns__container" key={index}></div>
      ))}
    </div>
  );
};

const ScheduleEntries = ({
  items,
  currentDate,
  typeSession,
  handleItemClick,
  userRole
}) => {
  updateLessonStatus(items);

  if (typeSession === "week") {
    return (
      <div className="schedule-week">
        <TimeGrid />
        <div className="schedule-week__sessions-container">
          <ScheduleColumns />
          <ScheduleWeekColumns
            currentDate={getCurrentWeekDates(currentDate)}
            items={items}
            handleItemClick={handleItemClick}
            userRole={userRole}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="schedule-today">
        <TimeGrid />
        <div className="schedule-today__sessions-container">
          <RecordSession
            date={currentDate}
            items={items}
            typeSession="day"
            handleItemClick={handleItemClick}
            userRole={userRole}
          />
        </div>
      </div>
    );
  }
};

export default ScheduleEntries;
