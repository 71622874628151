import "./creatorTask.sass";
import { useEffect, useState } from "react";
import { databases } from "../../lib/appwrite";
import { ID } from "appwrite";
import { Query } from "appwrite";
import Input from "../input/Input";
import InputMultiline from "../input/InputMultiline";
import Button from "../button/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import InputIcon from "../input/InputIcon";

const iconSelection = (studentIsSelected) => {
  if (studentIsSelected) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20px"
        viewBox="0 -960 960 960"
        width="20px"
        fill="#00CC00"
      >
        <path d="m429-438-60-59q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l85 85q11 11 25 11t25-11l187-187q11-11 11-25.5T641-599q-11-11-25-11t-25 11L429-438Zm51 342q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Z" />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20px"
        viewBox="0 -960 960 960"
        width="20px"
        fill="#FF0000"
      >
        <path d="m480-429 116 116q11 11 25.5 10.5T647-314q11-11 11-25.5t-11-25.46L531-480.5l116-115.54q11-10.96 11-25.46T647-647q-11-11-25.5-11T596-647L480-531 364-647q-11-11-25-11t-25 11q-11 11-11 25.5t10.91 25.5L429-480 313-364q-11 11-10.5 25t11.5 25q11 11 25.5 11t25.41-10.91L480-429Zm.28 333Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Z" />
      </svg>
    );
  }
};

const CreatorTask = ({
  isModalOpen,
  editUserData,
  newTaskClient,
  studentChangeIsAllowed = true,
}) => {
  // Значения
  const [isLoading, setLoading] = useState(false);
  const [isLoadingSearch, setLoadingSearch] = useState(false);
  const [typeToDo, setTypeToDo] = useState("");
  const [dateCompletion, setDateCompletion] = useState("");
  const [student, setStudent] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [comment, setComment] = useState("");
  const [uid, setUid] = useState("");
  const [temporaryUid, setTemporaryUid] = useState("");
  const [taskEdit, setTaskEdit] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [studentsList, setStudentsList] = useState([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(student);
  const [isStudentData, setStudentData] = useState([]);

  // Ошибки
  const [generalError, setGeneralError] = useState(false);
  const [errors, setErrors] = useState({
    dateCompletion: false,
    student: false,
    phoneNumber: false,
  });

  // Функция для проверки на пустоту и установки ошибок
  const validateFields = () => {
    const newErrors = {
      dateCompletion: !dateCompletion,
      student: !student,
      phoneNumber: !phoneNumber,
    };

    setErrors(newErrors);

    const hasError = Object.values(newErrors).some(Boolean);
    setGeneralError(hasError);

    return !hasError;
  };

  // Функция для сброса ошибок
  const resetErrors = () => {
    setErrors({
      dateCompletion: false,
      student: false,
      phoneNumber: false,
    });
    setGeneralError(false);
  };

  // Сохранение задачи
  const handleSaveNewTask = async (e) => {
    e.preventDefault();
    resetErrors();

    if (validateFields()) {
      setLoading(true);

      const taskData = {
        city: "voronezh",
        date_of_completion: new Date(dateCompletion).toISOString(),
        uid,
        client: capitalizeWords(student),
        phone_number: phoneNumber,
        type: typeToDo ? capitalizeWords(typeToDo) : "Связаться",
        comment: comment,
      };

      try {
        if (taskEdit) {
          await databases.updateDocument(
            "65f9d6f4dbeeccbe7e74",
            "66041861c5966a6ddda5",
            taskId,
            taskData
          );
          setTaskEdit(false);
        } else {
          await databases.createDocument(
            "65f9d6f4dbeeccbe7e74",
            "66041861c5966a6ddda5",
            ID.unique(),
            taskData
          );
        }
      } catch (error) {
        console.error("Ошибка при сохранении задачи:", error);
      } finally {
        setLoading(false);
        isModalOpen(false);
      }
    }
  };

  // Слова с большой буквы
  function capitalizeWords(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  // Установка значений при редактировании
  useEffect(() => {
    if (editUserData) {
      setTaskEdit(true);
      setTaskId(editUserData.$id);
      setTypeToDo(editUserData.type);
      setDateCompletion(
        new Date(editUserData.date_of_completion).toISOString().split("T")[0]
      );
      setStudent(editUserData.client);
      setPhoneNumber(editUserData.phone_number);
      setComment(editUserData.comment);
      setUid(editUserData.uid);
      setTemporaryUid(editUserData.uid);
      setStudentData({
        name: editUserData.client.split(" ")[0],
        sur_name: editUserData.client.split(" ")[1],
      });
    }
  }, [editUserData]);

  // Установка значений при создании записи клиента
  useEffect(() => {
    if (newTaskClient) {
      setStudent(newTaskClient.client);
      setPhoneNumber(newTaskClient.phone_number);
      setUid(newTaskClient.uid);
    }
  }, [newTaskClient]);

  // Поля ввода
  const handleTaskType = (e) => {
    setTypeToDo(e.target.value);
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("dateOfCompletion").focus();
    }
  };

  const handleDateCompletion = (e) => {
    setDateCompletion(e.target.value);
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("inputStudentTask").focus();
    }
  };

  const handleTextPhoneNumber = (e) => {
    const value = e.target.value.trim();
    if (value.length <= 12) {
      if (value.length > 0 && !value.startsWith("+")) {
        setPhoneNumber(`+${value}`);
      } else {
        setPhoneNumber(value);
      }
    }
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("inputCommentTask").focus();
    }
  };

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const selectSuggestion = (student) => {
    setStudent(`${student.name} ${student.sur_name}`);
    setPhoneNumber(student.phone_number);
    setUid(student.$id);
    setTemporaryUid(student.$id);
    setStudentData(student);
    setStudentsList([]);
  };

  useEffect(() => {
    // Таймер для задержки поиска
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(student);
    }, 500); // Задержка в 500 мс

    // Очистка таймера при размонтировании компонента или изменении searchTerm
    return () => {
      clearTimeout(timerId);
    };
  }, [student]);

  // Выполнение поиска с использованием debouncedSearchTerm
  useEffect(() => {
    if (debouncedSearchTerm) {
      const username = student.split(" ");

      if (
        username[0] !== isStudentData.name ||
        username[1] !== isStudentData.sur_name
      ) {
        searchStudents("voronezh", debouncedSearchTerm);
      }
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const username = student.split(" ");
    if (
      username[0] !== isStudentData.name ||
      username[1] !== isStudentData.sur_name
    ) {
      setUid("");
    } else {
      setUid(temporaryUid);
    }
  }, [student]);

  const searchStudents = async (city, searchTerm) => {
    setLoadingSearch(true);
    try {
      // Массив промисов для каждого поля поиска
      const searchPromises = [
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("name", searchTerm),
          ]
        ),
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("sur_name", searchTerm),
          ]
        ),
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("phone_number", searchTerm),
          ]
        ),
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("user_id", searchTerm),
          ]
        ),
        // Добавьте другие поля для поиска здесь
      ];

      // Выполнение всех запросов и объединение результатов
      const results = await Promise.all(searchPromises);
      const documents = results
        .flatMap((result) => result.documents)
        // Удаление дубликатов документов
        .filter(
          (doc, index, self) =>
            index === self.findIndex((t) => t.$id === doc.$id)
        );

      // Обновление состояния с данными студентов текущей страницы
      setStudentsList(documents);
    } catch (error) {
      console.error("Ошибка при поиске студентов:", error);
    } finally {
      setLoadingSearch(false);
    }
  };

  return (
    <div className="modal-new-task">
      <div className="modal-new-task__container">
        <h3 className="modal-new-task__title">
          {taskEdit ? "Редактирование" : "Новая задача"}
        </h3>

        <form className="modal-new-task__form-new-task form-new-task">
          <div className="form-new-task__top">
            <Input
              title={"Что сделать"}
              className={`form-new-task__type-to-do ${
                errors.typeToDo && "error"
              }`}
              id={"typeToDoCreator"}
              type={"text"}
              value={typeToDo}
              onChange={handleTaskType}
              placeholder={"Связаться"}
              onKeyDown={handleTaskType}
            />

            <Input
              title={"Дата выполнения"}
              className={`form-new-task__date-of-completion ${
                errors.dateCompletion && "error"
              }`}
              id={"dateOfCompletion"}
              type={"date"}
              value={dateCompletion}
              onChange={handleDateCompletion}
              placeholder={"Дата выполнения"}
              onKeyDown={handleDateCompletion}
            />
          </div>

          <InputIcon
            title={"Ученик"}
            className={"capitalize"}
            classNameContainer={`form-new-task__student ${
              errors.student && "error"
            }`}
            id={"inputStudentTask"}
            type={"text"}
            value={student}
            placeholder={"Ученик"}
            onChange={(e) => setStudent(e.target.value)}
            disabled={!studentChangeIsAllowed}
            icon={
              isLoadingSearch ? (
                <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
                  <CircularProgress color="inherit" size={20} thickness={5} />
                </Stack>
              ) : student ? (
                iconSelection(uid)
              ) : (
                <></>
              )
            }
          />

          {studentsList.length > 0 && (
            <ul className="form-new-task__drop-down-list">
              {studentsList.map((user, index) => (
                <li
                  className="form-new-task__list-item"
                  key={index}
                  onClick={() => selectSuggestion(user)}
                >
                  <h5 className="form-new-task__item-title">
                    {user.name} {user.sur_name}
                  </h5>
                  <span className="form-new-task__item-subtitle">
                    {user.phone_number}
                  </span>
                </li>
              ))}
            </ul>
          )}

          <Input
            title={"Номер телефона"}
            className={`form-new-task__number-phone  ${
              errors.phoneNumber && "error"
            }`}
            id={"inputPhoneTask"}
            type={"text"}
            value={phoneNumber}
            onChange={handleTextPhoneNumber}
            placeholder={"Номер (+7)"}
            onKeyDown={handleTextPhoneNumber}
          />
          <InputMultiline
            title={"Комментарий"}
            className={"form-new-task__comment"}
            id={"inputCommentTask"}
            type={"text"}
            value={comment}
            onChange={handleComment}
            placeholder={"Комментарий"}
          />
        </form>
        {generalError && (
          <h4 className="modal-new-task__error">
            Заполните все обязательные поля!
          </h4>
        )}
        <div className="modal-new-task__buttons">
          <Button
            colorStyle={"invisible"}
            className={"modal-new-task__button-cancel"}
            onClick={(e) => {
              e.preventDefault();
              isModalOpen(false);
            }}
          >
            Отмена
          </Button>
          <Button
            colorStyle={"blue"}
            className={"modal-new-task__button-save"}
            onClick={handleSaveNewTask}
            disabled={!dateCompletion || !student || !phoneNumber}
          >
            Сохранить
          </Button>
        </div>
        {isLoading && (
          <div className="loading-container-new-task">
            <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
              <CircularProgress color="inherit" />
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreatorTask;
