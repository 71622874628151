import "./input.sass";

const InputIcon = ({
  title,
  value,
  placeholder,
  className,
  type,
  onChange,
  ...other
}) => {
  return (
    <label className={`${other.classContainer} container-input-text`}>
      <div className="container-title">
        <h3 className={`${other.placeHolder} title-text`}>{title}</h3>
        <h4 className="subtitle-text">{other.subTitle}</h4>
      </div>
      <div className={`container-input-icon ${other.classNameContainer}`}>
        <input
          autoComplete="new-password"
          className={`input-text input-text-icon background-none ${className}`}
          id={other.id}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onKeyDown={other.onKeyDown}
          lang={other.lang}
          disabled={other.disabled}
        />
        <i className="icon-in-input-text" onClick={other.onClickIcon}>
          {other.icon}
        </i>
      </div>
    </label>
  );
};

export default InputIcon;
