import "./button.sass";
const Button = ({ children, colorStyle, onClick, ...other }) => {
  return (
    <button
      className={`button ${other.className ? other.className : ""} ${
        other.disabled ? "button-disabled" : colorStyle
      } ${other.disabledIcon ? "button-icon-disabled" : ""}`}
      onClick={onClick}
      disabled={other.disabled || other.disabledIcon}
    >
      {children}
    </button>
  );
};

export default Button;
