import { useEffect, useState } from "react";
import Button from "../button/Button";
import Input from "../input/Input";
import "./changingSubscription.sass";
import { databases } from "../../lib/appwrite";
import { format } from "date-fns";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const ChangingSubscription = ({
  idSubscription,
  openModal,
  isUpdate,
  updateInfoSubscription,
}) => {
  const [isDateEnd, setDateEnd] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setDateEnd(format(new Date(idSubscription.end_date), "yyyy-MM-dd"));
  }, [idSubscription]);

  const handleChangingDate = async () => {
    setLoading(true);
    if (!isDateEnd) {
      setLoading(false);
      console.log("Дата окончания не задана.");
      return;
    }

    try {
      await databases.updateDocument(
        "65f9d6f4dbeeccbe7e74",
        "65fef6b362018b62c8c6",
        idSubscription.$id,
        { end_date: new Date(isDateEnd).toISOString() }
      );
      isUpdate();
      updateInfoSubscription();
    } catch (error) {
      console.error("Ошибка при обновлении даты: ", error);
      return;
    } finally {
      setLoading(false);
    }

    openModal(false);
  };

  return (
    <div className="changing-subscription">
      <h4 className="changing-subscription__title">Изменить дату окончания</h4>

      <Input
        title={"Дата окончания"}
        id={"subscriptionInputDateEndChanging"}
        classContainer={"changing-subscription__input"}
        type={"date"}
        value={isDateEnd}
        onChange={(e) => setDateEnd(e.target.value)}
        placeholder={"Дата"}
      />

      <div className="changing-subscription__buttons">
        <Button
          className={"changing-subscription__button-cancel"}
          colorStyle={"invisible"}
          onClick={() => {
            openModal(false);
          }}
        >
          Отмена
        </Button>
        <Button
          colorStyle={"blue"}
          onClick={handleChangingDate}
          disabled={!isDateEnd}
        >
          Сохранить
        </Button>
      </div>
      {isLoading && (
        <div className="container-loader-subscription">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}
    </div>
  );
};

export default ChangingSubscription;
