import { useState } from "react";
import Button from "../button/Button";
import "./typeSelector.sass";

const TypeSelector = ({ options, initialSelected, onSelectType, className }) => {
  const [selectedType, setSelectedType] = useState(initialSelected);

  const handleSelect = (value) => {
    setSelectedType(value);
    onSelectType(value);
  };

  return (
    <div className={`type-selector ${className}`}>

      {options.map((option) => (
        <Button
          key={option.value}
          colorStyle={
            selectedType === option.value ? "blue-inverse" : "invisible"
          }
          onClick={() => handleSelect(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
};

export default TypeSelector;
