import "./home.sass";
import { useCallback, useEffect, useState } from "react";
import DataMini from "../../components/homeInfoComponents/DataMini";
import TasksHome from "../../components/homeInfoComponents/TasksHome";
import WorkerRating from "../../components/homeInfoComponents/WorkerRating";
import Sidebar from "../../components/sidebar/Sidebar";
import CreatorTask from "../../components/creatorTask/CreatorTask";
import ViewingTask from "../../components/viewingTask/ViewingTask";
import DataStudents from "../../components/homeInfoComponents/DataStudent";
import TypeSelector from "../../components/typSelector/TypeSelector";

const Home = () => {
  const [modalViewingTaskOpen, setModalViewingTaskOpen] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState("");
  const [modalNewTaskOpen, setModalNewTaskOpen] = useState(false);
  const [timePeriod, setTimePeriod] = useState("week");

  const typeSelectorOptions = [
    { value: "week", label: "Неделя" },
    { value: "month", label: "Месяц" },
  ];

  useEffect(() => {
    document.title = "Голосовое (Enterprise Resource Planning)";
    
  }, []);

  const handleSelectionChange = useCallback((selectedValue) => {
    setTimePeriod(selectedValue);
  }, []);

  return (
    <div className="home desktop-version">
      <Sidebar />
      <div className="main-container-pages">
        <div className="main-container-pages__container">
          <div className="main-container-pages__texts">
            <h2 className="main-container-pages__title">Главная</h2>
            <TypeSelector
              className={"main-container-pages__selector-pages-end"}
              options={typeSelectorOptions}
              initialSelected={timePeriod}
              onSelectType={(value) => handleSelectionChange(value)}
            />
          </div>

          <div className="home-container">
            <div className="home-container__info">
              <div className="home-flex home-container__sales-volume-data">
                <DataMini dataType={"realization"} timePeriod={timePeriod} />
              </div>

              <div className="home-container__realization-data">
                <DataMini dataType={"salesVolume"} timePeriod={timePeriod} />
              </div>

              <div className="home-container__index-of-groups-data">
                <DataMini dataType={"indexOfGroups"} timePeriod={timePeriod} />
              </div>

              <div className="home-container__students-data">
                <DataStudents />
              </div>

              <div className="home-container__rating-worker">
                <WorkerRating />
              </div>
            </div>

            <div className="home-container__tasks">
              <TasksHome
                setModalNewTaskOpen={setModalNewTaskOpen}
                setSelectedUserInfo={setSelectedUserInfo}
                setModalViewingTaskOpen={setModalViewingTaskOpen}
              />
            </div>
          </div>
        </div>
      </div>
      {modalNewTaskOpen && (
        <CreatorTask
          isModalOpen={setModalNewTaskOpen}
          editUserData={selectedUserInfo}
        />
      )}
      {modalViewingTaskOpen && (
        <ViewingTask
          isOpenViewing={setModalViewingTaskOpen}
          userDataView={selectedUserInfo}
          openingCreatorTask={setModalNewTaskOpen}
          selectedInfo={setSelectedUserInfo}
        />
      )}
    </div>
  );
};

export default Home;
