import { useEffect, useState } from "react";
import IconButton from "../iconButton/IconButton";
import "./checkBox.sass";

const iconCheckBox = (checkBoxStyle, activeCheckBox, checked, disabled) => {
  if (checkBoxStyle === "all") {
    if (activeCheckBox) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <rect width="16" height="16" rx="4" fill="#0065FD" />
          <path
            d="M3.62146 8.71875C3.4129 8.71875 3.23571 8.6473 3.08987 8.5044C2.94404 8.36148 2.87112 8.1844 2.87112 7.97315C2.87112 7.76188 2.943 7.58333 3.08675 7.4375C3.2305 7.29167 3.40862 7.21875 3.62112 7.21875H12.4541C12.6627 7.21875 12.8399 7.2902 12.9857 7.4331C13.1315 7.57602 13.2045 7.7531 13.2045 7.96435C13.2045 8.17562 13.1326 8.35417 12.9888 8.5C12.8451 8.64583 12.667 8.71875 12.4545 8.71875H3.62146Z"
            fill="white"
          />
        </svg>
      );
    } else {
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <rect
          x="1.00098"
          y="1"
          width="14"
          height="14"
          rx="3"
          stroke="#748091"
          strokeWidth="2"
        />
      </svg>;
    }
  } else {
    if (disabled) {
      if (checked) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <rect width="16" height="16" rx="4" fill="#748091" />
            <path
              d="M6.33341 9.60413L11.4167 4.52079C11.5695 4.36801 11.7466 4.29163 11.948 4.29163C12.1494 4.29163 12.3265 4.36801 12.4792 4.52079C12.632 4.67357 12.7084 4.85065 12.7084 5.05204C12.7084 5.25343 12.632 5.43051 12.4792 5.58329L6.85425 11.2083C6.70147 11.3611 6.52439 11.4375 6.323 11.4375C6.12161 11.4375 5.94453 11.3611 5.79175 11.2083L3.52091 8.93746C3.36814 8.78468 3.29175 8.6076 3.29175 8.40621C3.29175 8.20482 3.36814 8.02774 3.52091 7.87496C3.67369 7.72218 3.85078 7.64579 4.05216 7.64579C4.25355 7.64579 4.43064 7.72218 4.58341 7.87496L6.33341 9.60413Z"
              fill="white"
            />
          </svg>
        );
      } else {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <rect width="16" height="16" rx="4" fill="#748091" />
            <path
              d="M8.00011 8.85005L6.05161 10.7834C5.92839 10.9056 5.789 10.9667 5.63345 10.9667C5.47789 10.9667 5.339 10.9056 5.21678 10.7834C5.09456 10.6612 5.03345 10.5223 5.03345 10.3667C5.03345 10.2112 5.09456 10.0718 5.21678 9.94855L7.15011 8.00005L5.21678 6.06822C5.09456 5.94499 5.03345 5.8056 5.03345 5.65005C5.03345 5.49449 5.09456 5.3556 5.21678 5.23338C5.339 5.11116 5.47789 5.05005 5.63345 5.05005C5.789 5.05005 5.92839 5.11116 6.05161 5.23338L8.00011 7.16672L9.93195 5.23338C10.0552 5.11116 10.1946 5.05005 10.3501 5.05005C10.5057 5.05005 10.6446 5.11116 10.7668 5.23338C10.889 5.3556 10.9501 5.49449 10.9501 5.65005C10.9501 5.8056 10.889 5.94499 10.7668 6.06822L8.83345 8.00005L10.7668 9.94855C10.889 10.0718 10.9501 10.2112 10.9501 10.3667C10.9501 10.5223 10.889 10.6612 10.7668 10.7834C10.6446 10.9056 10.5057 10.9667 10.3501 10.9667C10.1946 10.9667 10.0552 10.9056 9.93195 10.7834L8.00011 8.85005Z"
              fill="white"
            />
          </svg>
        );
      }
    } else {
      if (checked) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <rect
              x="0.000976562"
              width="16"
              height="16"
              rx="4"
              fill="#0065FD"
            />
            <path
              d="M6.33464 9.60425L11.418 4.52091C11.5707 4.36814 11.7478 4.29175 11.9492 4.29175C12.1506 4.29175 12.3277 4.36814 12.4805 4.52091C12.6332 4.67369 12.7096 4.85078 12.7096 5.05216C12.7096 5.25355 12.6332 5.43064 12.4805 5.58341L6.85547 11.2084C6.70269 11.3612 6.52561 11.4376 6.32422 11.4376C6.12283 11.4376 5.94575 11.3612 5.79297 11.2084L3.52214 8.93758C3.36936 8.7848 3.29297 8.60772 3.29297 8.40633C3.29297 8.20494 3.36936 8.02786 3.52214 7.87508C3.67491 7.7223 3.852 7.64591 4.05339 7.64591C4.25477 7.64591 4.43186 7.7223 4.58464 7.87508L6.33464 9.60425Z"
              fill="white"
            />
          </svg>
        );
      } else {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <rect
              x="1.00098"
              y="1"
              width="14"
              height="14"
              rx="3"
              stroke="#748091"
              strokeWidth="2"
            />
          </svg>
        );
      }
    }
  }
};

const CheckBox = ({
  activeCheckBox = false,
  setActiveCheckBox,
  checkBoxStyle,
  className,
  setActiveCheckBoxStudent,
  setCheckbox,
  disabled,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(activeCheckBox);
  }, [activeCheckBox]);

  useEffect(() => {
    setCheckbox && setCheckbox(checked);
  }, [checked, setCheckbox]);

  useEffect(() => {
    setActiveCheckBoxStudent ? setChecked(true) : setChecked(false);
  }, [setActiveCheckBoxStudent]);

  return (
    <IconButton
      className={className}
      icon={iconCheckBox(checkBoxStyle, activeCheckBox, checked, disabled)}
      onClick={() => {
        if (!disabled) {
          checkBoxStyle === "all"
            ? setActiveCheckBox(false)
            : setChecked(!checked);
        }
      }}
    />
  );
};

export default CheckBox;
