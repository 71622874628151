import React, { useState, useRef, useEffect } from "react";
import "./imageUploader.sass";
import Button from "../button/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const ImageUploader = ({
  imageFile,
  setFileImageURL,
  loadingImage,
  onLoad,
  containerSetImage,
  className
}) => {
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    setImage(setFileImageURL);
  }, [setFileImageURL]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result.toString());
    };

    if (file) {
      reader.readAsDataURL(file);
    }
    imageFile(file);
    containerSetImage(true);
  };

  const handleClearImage = () => {
    setImage(null);
    containerSetImage(false);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className={`image-uploader-container ${className}`}>
      {loadingImage && (
        <div className="loading-image">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}
      {!image ? (
        <div className="image-uploader-square">
          <svg
            className="icon-add-image"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M800-680q-17 0-28.5-11.5T760-720v-40h-40q-17 0-28.5-11.5T680-800q0-17 11.5-28.5T720-840h40v-40q0-17 11.5-28.5T800-920q17 0 28.5 11.5T840-880v40h40q17 0 28.5 11.5T920-800q0 17-11.5 28.5T880-760h-40v40q0 17-11.5 28.5T800-680ZM440-260q75 0 127.5-52.5T620-440q0-75-52.5-127.5T440-620q-75 0-127.5 52.5T260-440q0 75 52.5 127.5T440-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM120-120q-33 0-56.5-23.5T40-200v-480q0-33 23.5-56.5T120-760h126l50-54q11-12 26.5-19t32.5-7h205q17 0 28.5 11.5T600-800v60q0 25 17.5 42.5T660-680h20v20q0 25 17.5 42.5T740-600h60q17 0 28.5 11.5T840-560v360q0 33-23.5 56.5T760-120H120Z" />
          </svg>
          <span className="title-text-add">Добавить аватар</span>
          <span className="subtitle-text-add">Необязательно</span>
        </div>
      ) : (
        <div className="image-uploader-square">
          <img src={image} alt="uploaded" onLoad={onLoad} />
        </div>
      )}

      <div className="image-uploader-buttons">
        <Button colorStyle={"blue-inverse icons"} onClick={handleButtonClick}>
          <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h207q16 0 30.5 6t25.5 17l57 57h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm400-240v40q0 17 11.5 28.5T600-320q17 0 28.5-11.5T640-360v-40h40q17 0 28.5-11.5T720-440q0-17-11.5-28.5T680-480h-40v-40q0-17-11.5-28.5T600-560q-17 0-28.5 11.5T560-520v40h-40q-17 0-28.5 11.5T480-440q0 17 11.5 28.5T520-400h40Z" />
          </svg>
        </Button>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
        />

        <Button colorStyle={"red-inverse icons"} onClick={handleClearImage}>
          <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M280-120q-33 0-56.5-23.5T200-200v-520q-17 0-28.5-11.5T160-760q0-17 11.5-28.5T200-800h160q0-17 11.5-28.5T400-840h160q17 0 28.5 11.5T600-800h160q17 0 28.5 11.5T800-760q0 17-11.5 28.5T760-720v520q0 33-23.5 56.5T680-120H280Zm200-284 76 76q11 11 28 11t28-11q11-11 11-28t-11-28l-76-76 76-76q11-11 11-28t-11-28q-11-11-28-11t-28 11l-76 76-76-76q-11-11-28-11t-28 11q-11 11-11 28t11 28l76 76-76 76q-11 11-11 28t11 28q11 11 28 11t28-11l76-76Z" />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default ImageUploader;
