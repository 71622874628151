import React from "react";
import "./progressCircle.sass";

const ProgressCircle = ({
  total,
  progress,
  color,
  lessonText,
}) => {
  const radius = 80; // радиус диаграммы
  const strokeWidth = 13; // толщина линии
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI * 0.7;
  const strokeDashoffset = circumference - (progress / total) * circumference;
  let textProgressOne = "";
  let textProgressTwo = "";

  switch (lessonText) {
    case "lesson":
      textProgressOne = "Уроки";
      textProgressTwo = "в модуле"
      break;
    case "module":
      textProgressOne = "Модули";
      textProgressTwo = "завершены"
      break;
    case "allLessons":
      textProgressOne = "Всего";
      textProgressTwo = "уроков"
      break;
  }

  return (
    <div className="progress-circle-container">
      <svg height={radius * 2} width={radius * 2} className="progress-circle">
        <circle
          stroke="#74809110" // цвет недостающей части
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference + " " + circumference}
          style={{ strokeDashoffset: 0 }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={color} // цвет заполненной части
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference + " " + 999}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />

        <text
          className="progress-text"
          x="50%"
          y="40%"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {progress}
        </text>
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="hanging"
          className="progress-text-description"
        >
          <tspan x="50%">{textProgressOne}</tspan>
          <tspan x="50%" dy="1.2em">
            {textProgressTwo}
          </tspan>
        </text>
      </svg>

      <h5 className="progress-circle-container__text-hint-start">0</h5>
      <h5 className="progress-circle-container__text-hint-end">{total}</h5>
    </div>
  );
};

export default ProgressCircle;
