import "./input.sass";

import React from 'react';

const InputMultiline = ({
  title,
  value,
  placeholder,
  className,
  onChange,
  ...other
}) => {
  return (
    <label className={`${other.classContainer} container-input-text`}>
      <div className="container-title">
        <h3 className={`${other.placeHolder} title-text`}>{title}</h3>
        <h4 className="subtitle-text">{other.subTitle}</h4>
      </div>
      <textarea
        autoComplete="new-password"
        className={`input-text ${className}`}
        id={other.id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={other.onKeyDown}
        lang={other.lang}
        disabled={other.disabled}
        rows={other.rows || "4"} // Значение по умолчанию для строк, если не указано другое
        cols={other.cols || "50"} // Значение по умолчанию для столбцов, если не указано другое
      />
    </label>
  );
};

export default InputMultiline;