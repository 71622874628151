"use strict";
import "./editWorker.sass";
import React, { useEffect, useState } from "react";
import { databases, storage } from "../../lib/appwrite";
import Button from "../../components/button/Button";
import ImageUploader from "../../components/imageUploader/ImageUploader";
import Input from "../../components/input/Input";
import OptionMenu from "../../components/optionMenu/OptionMenu";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Удаление аватара
const deleteImageData = async (uid, idAvatar) => {
  await storage.deleteFile("65fb23f53117da6475ba", `${idAvatar}`);
  await databases.updateDocument(
    "65f9d6f4dbeeccbe7e74",
    "65f9d6fd218cc728529a",
    uid,
    {
      avatar_image: "",
      avatar_id: "",
    }
  );
};

// Обновление аватара
const uploadUserAvatar = async (userId, fileImage) => {
  if (!fileImage) {
    return;
  }

  let dataUser;
  try {
    dataUser = await databases.getDocument(
      "65f9d6f4dbeeccbe7e74",
      "65f9d6fd218cc728529a",
      userId
    );
  } catch (error) {
    console.error("Ошибка при получении данных пользователя:", error);
    return;
  }

  const newAvatarId = new Date().getTime().toString();
  const bucketId = "65fb23f53117da6475ba";

  // Попытка удаления существующего файла, если он есть
  if (dataUser && dataUser.avatar_id) {
    try {
      await storage.deleteFile(bucketId, dataUser.avatar_id);
    } catch (error) {
      console.error("Ошибка при удалении существующего файла:", error);
    }
  }

  try {
    // Загрузка нового файла
    await storage.createFile(bucketId, newAvatarId, fileImage);

    const downloadURL = storage.getFilePreview(`${bucketId}`, `${newAvatarId}`);

    await databases.updateDocument(
      "65f9d6f4dbeeccbe7e74",
      "65f9d6fd218cc728529a",
      userId,
      { avatar_image: downloadURL, avatar_id: newAvatarId }
    );

    // console.log("Информация о пользователе успешно обновлена.");
  } catch (error) {
    console.error("Ошибка при обновлении аватара пользователя:", error);
  }
};

const EditWorker = ({ onModalEditOpen, workerUID }) => {
  // Состояния
  const [isLoader, setIsLoader] = useState(false);
  const [loadImageWorker, setLoadImageWorker] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isContainerImage, setContainerImage] = useState(true);
  // Значения полей
  const [userWorker, setUserWorker] = useState("");
  const [fileImage, setFileImage] = useState("");
  const [readingFileImage, setReadingFileImage] = useState("");
  const [isName, setIsName] = useState("");
  const [isSurName, setIsSurName] = useState("");
  const [isPhoneNumber, setIsPhoneNumber] = useState("");
  const [isDateBirth, setIsDateBirth] = useState("");
  const [isEmail, setIsEmail] = useState("");
  const [isPost, setIsPost] = useState("");
  // Значения ошибок
  const [error, setError] = useState(false);
  const [errorFirebase, setErrorFirebase] = useState(false);
  const [errorNullName, setErrorNullName] = useState(false);
  const [errorNullSurName, setErrorNullSurName] = useState(false);
  const [errorNullPhone, setErrorNullPhone] = useState(false);
  const [errorNullPost, setErrorNullPost] = useState(false);
  const [errorNullDateOfBirth, setErrorNullDateOfBirth] = useState(false);
  //Значения селектора
  const options = [
    { value: "none-select", label: "Выберите..." },
    { value: "admin", label: "Администратор" },
    { value: "teacher", label: "Преподаватель" },
  ];

  useEffect(() => {
    !readingFileImage && setLoadImageWorker(true);

    const fetchData = async () => {
      try {
        setIsLoader(true);

        const result = await databases.getDocument(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          workerUID
        );

        if (result) {
          const data = result;
          setUserWorker(data);
          setReadingFileImage(data.avatar_image);
          !data.avatar_image && setLoadImageWorker(false);
          setIsName(data.name);
          setIsSurName(data.sur_name);
          setIsPhoneNumber(data.phone_number);
          setIsDateBirth(
            new Date(data.date_of_birth).toISOString().split("T")[0]
          );
          setIsPost(data.role);
          setIsEmail(data.email);

          setIsLoader(false);
        } else {
          onModalEditOpen(false);
          console.log("Пользователь не найден");
        }
      } catch (error) {
        onModalEditOpen(false);
        console.error("Error fetching document:", error);
      }
    };

    if (workerUID) {
      fetchData();
    }
  }, [workerUID]);

  // Открытие окна удаления
  const handleOpenDialogDelete = () => {
    setOpenDeleteDialog(true);
  };

  // Закрытие окна удаления
  const handleCloseDialogDelete = () => {
    setOpenDeleteDialog(false);
  };

  // Удаление пользователя
  const handleDeleteUser = async (uid, deleteUser) => {
    if (deleteUser) {
      const idAvatar = await databases.getDocument(
        "65f9d6f4dbeeccbe7e74",
        "65f9d6fd218cc728529a",
        uid
      );

      try {
        await databases.deleteDocument(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          uid
        );
        await storage.deleteFile(
          "65fb23f53117da6475ba",
          `${idAvatar.avatar_id}`
        );
        handleCloseDialogDelete();
        onModalEditOpen(false);
      } catch (error) {
        console.error("Ошибка при удалении пользователя:", error);
      }
    }
  };

  // Обновление пользователя
  const handleUpdateDataWorker = async (e) => {
    e.preventDefault();
    setErrorNullName(false);
    setErrorNullSurName(false);
    setErrorNullPhone(false);
    setErrorNullPost(false);
    setErrorNullDateOfBirth(false);
    setErrorFirebase(false);
    setError(false);

    if (
      isName.length === 0 ||
      isSurName.length === 0 ||
      isPhoneNumber.length === 0 ||
      isDateBirth.length === 0 ||
      isPost.length === 0 ||
      isPost === "none-select"
    ) {
      if (isName.length === 0) {
        setErrorNullName(true);
        setError(true);
      }
      if (isSurName.length === 0) {
        setErrorNullSurName(true);
        setError(true);
      }
      if (isPhoneNumber.length === 0) {
        setErrorNullPhone(true);
        setError(true);
      }

      if (isDateBirth.length === 0) {
        setErrorNullDateOfBirth(true);
        setError(true);
      }

      if (isPost.length === 0 || isPost === "none-select") {
        setErrorNullPost(true);
        setError(true);
      }
      setError(true);
    } else {
      setIsLoader(true);

      try {
        // Заполнение данных
        const userData = await databases.updateDocument(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          workerUID,
          {
            name: `${isName.charAt(0).toUpperCase()}${isName.slice(1)}`,
            sur_name: `${isSurName.charAt(0).toUpperCase()}${isSurName.slice(
              1
            )}`,
            phone_number: isPhoneNumber,
            date_of_birth: new Date(isDateBirth).toISOString(),
            role: isPost,
          }
        );

        // Загрузка изображения
        !isContainerImage && deleteImageData(workerUID, userData.avatar_id);
        uploadUserAvatar(workerUID, fileImage);
        // Закрытие окна
        onModalEditOpen(false);
        setIsLoader(false);
      } catch (err) {
        setErrorFirebase(true);
        setIsLoader(false);
        console.error(err);
      } finally {
        setIsLoader(false);
      }
    }
  };

  // Поля ввода
  const handleAddWorker = (e) => {
    e.preventDefault();
  };

  const handleInputName = (e) => {
    setIsName(e.target.value.trim());
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("surNameInputEdit").focus();
    }
  };

  const handleInputSurName = (e) => {
    setIsSurName(e.target.value.trim());
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("numberPhoneInputEdit").focus();
    }
  };

  const handleInputPhone = (e) => {
    const value = e.target.value.trim();
    if (value.length <= 12) {
      if (value.length > 0 && !value.startsWith("+")) {
        setIsPhoneNumber(`+${value}`);
      } else {
        setIsPhoneNumber(value);
      }
    }
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("dateBirthSelectEdit").focus();
    }
  };

  const handleInputDateBirth = (e) => {
    setIsDateBirth(e.target.value.trim());
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("emailWorkerEdit").focus();
    }
  };

  return (
    <div className="modal-edit">
      <div className="modal-edit__content-container">
        <div className="modal-edit__top-container">
          <h2>Редактирование</h2>
          <Button
            className={"modal-edit__button-delete"}
            colorStyle={"red-inverse"}
            onClick={handleOpenDialogDelete}
          >
            <h5>Удалить сотрудника</h5>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="M280-120q-33 0-56.5-23.5T200-200v-520q-17 0-28.5-11.5T160-760q0-17 11.5-28.5T200-800h160q0-17 11.5-28.5T400-840h160q17 0 28.5 11.5T600-800h160q17 0 28.5 11.5T800-760q0 17-11.5 28.5T760-720v520q0 33-23.5 56.5T680-120H280Zm200-284 76 76q11 11 28 11t28-11q11-11 11-28t-11-28l-76-76 76-76q11-11 11-28t-11-28q-11-11-28-11t-28 11l-76 76-76-76q-11-11-28-11t-28 11q-11 11-11 28t11 28l76 76-76 76q-11 11-11 28t11 28q11 11 28 11t28-11l76-76Z" />
            </svg>
          </Button>
        </div>

        <div className="modal-edit__form-container">
          <ImageUploader
            imageFile={setFileImage}
            setFileImageURL={readingFileImage}
            loadingImage={loadImageWorker}
            onLoad={() => {
              setLoadImageWorker(false);
            }}
            containerSetImage={setContainerImage}
          />

          <form
            onSubmit={handleAddWorker}
            className="form-worker-edit"
            autoComplete="off"
          >
            <div className="form-worker-edit__left-inputs">
              <Input
                title={"Имя"}
                className={
                  errorNullName
                    ? "form-worker-edit__input-name capitalize error"
                    : "form-worker-edit__input-name capitalize"
                }
                id={"nameInputEdit"}
                classContainer={"width-204 right-8 bottom-8"}
                type={"text"}
                value={isName}
                onChange={handleInputName}
                placeholder={"Имя"}
                onKeyDown={handleInputName}
              />
              <Input
                title={"Фамилия"}
                id={"surNameInputEdit"}
                className={
                  errorNullSurName
                    ? "form-worker-edit__input-sur-name capitalize error"
                    : "form-worker-edit__input-sur-name capitalize"
                }
                classContainer={"width-204 bottom-8"}
                type={"text"}
                value={isSurName}
                onChange={handleInputSurName}
                placeholder={"Фамилия"}
                onKeyDown={handleInputSurName}
              />
              <Input
                title={"Номер телефона"}
                id={"numberPhoneInputEdit"}
                className={
                  errorNullPhone
                    ? "form-worker-edit__input-phone error"
                    : "form-worker-edit__input-phone"
                }
                classContainer={"width-204 right-8"}
                type={"text"}
                value={isPhoneNumber}
                onChange={handleInputPhone}
                placeholder={"Номер телефона (+7)"}
                onKeyDown={handleInputPhone}
              />
              <Input
                title={"Дата рождения"}
                id={"dateBirthSelectEdit"}
                className={
                  errorNullDateOfBirth
                    ? "form-worker-edit__input-date-birth error"
                    : "form-worker-edit__input-date-birth"
                }
                classContainer={"width-204"}
                type={"date"}
                value={isDateBirth}
                onChange={handleInputDateBirth}
                placeholder={"Дата рождения"}
                onKeyDown={handleInputDateBirth}
              />
            </div>
            <div className="form-worker__right-inputs">
              <Input
                title={"Почта"}
                className={"form-worker-edit__input-email"}
                classContainer={"width-400 bottom-8"}
                type={"new-password"}
                value={isEmail}
                onChange={(e) => e.target.value}
                placeholder={"Почта"}
                disabled={true}
              />

              <OptionMenu
                options={options}
                value={isPost}
                title={"Должность"}
                classSelect={errorNullPost && "error"}
                onChange={(e) => setIsPost(e.target.value)}
                classMenu={"width-400"}
              />
            </div>
          </form>
        </div>

        <div className="modal-edit__buttons">
          {error && <h4>Пожалуйста, заполните все поля!</h4>}

          {errorFirebase && <h4>Произошла ошибка, попробуйте снова</h4>}

          <Button
            className={"modal-edit__close-button"}
            colorStyle={"invisible"}
            onClick={() => {
              onModalEditOpen(false);
            }}
          >
            Отмена
          </Button>
          <Button
            className={"modal-edit__save-button"}
            colorStyle={"blue"}
            onClick={handleUpdateDataWorker}
          >
            Сохранить
          </Button>
        </div>
      </div>
      {isLoader && (
        <div className="modal-edit__container-loader-upload">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDialogDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Удалить сотрудника ${userWorker.name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="x">
            После удаления сотрудник будет полностью недоступен, включая все его
            данные и информацию. Пожалуйста, убедитесь, что это действие
            является окончательным и необратимым.{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button colorStyle={"invisible"} onClick={handleCloseDialogDelete}>
            Отмена
          </Button>
          <Button
            colorStyle={"red"}
            onClick={() => handleDeleteUser(workerUID, true)}
            autoFocus
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditWorker;
