import { useState } from "react";
import "./replenishSubscription.sass";
import Input from "../input/Input";
import Button from "../button/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { databases } from "../../lib/appwrite";

const ReplenishSubscription = ({
  idSubscription,
  openModal,
  isUpdate,
  updateInfoSubscription,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isAmount, setAmount] = useState("");

  const handleReplenishBalance = async () => {
    setLoading(true);
    if (!isAmount) {
      setLoading(false);
      console.log("Сумма не задана.");
      return;
    }

    const newAmountIntroduced = idSubscription.introduced + Number(isAmount);
    const newRemainsBalance = idSubscription.remains_balance + Number(isAmount);
    const pricePerLesson =
      idSubscription.price / idSubscription.number_of_lessons;
    const newRemainsLessons = Math.floor(newRemainsBalance / pricePerLesson);

    try {
      await databases.updateDocument(
        "65f9d6f4dbeeccbe7e74",
        "65fef6b362018b62c8c6",
        idSubscription.$id,
        {
          introduced: newAmountIntroduced,
          remains_balance: newRemainsBalance,
          remains_lessons: newRemainsLessons,
        }
      );
      isUpdate();
      updateInfoSubscription();
    } catch (error) {
      console.error("Ошибка при обновлении даты: ", error);
      return;
    } finally {
      setLoading(false);
    }

    openModal(false);
  };

  return (
    <div className="replenish-subscription">
      <h4 className="replenish-subscription__title">Пополнить</h4>

      <div className="replenish-subscription__data">
        <div className="viewing-subscription__price">
          <h4 className="viewing-subscription__subtitle">
            Стоимость абонемента
          </h4>
          <h4 className="viewing-subscription__text">
            {idSubscription ? `${idSubscription.price} ₽` : "---"}
          </h4>
        </div>

        <div className="viewing-subscription__remains">
          <h4 className="viewing-subscription__subtitle">Остаток</h4>
          <h4 className="viewing-subscription__text">
            {idSubscription
              ? `${idSubscription.price - idSubscription.introduced} ₽`
              : "---"}
          </h4>
        </div>

        <div className="viewing-subscription__introduced">
          <h4 className="viewing-subscription__subtitle">Внесено</h4>
          <h4 className="viewing-subscription__text">
            {idSubscription ? `${idSubscription.introduced} ₽` : "---"}
          </h4>
        </div>
      </div>

      <Input
        title={"Сумма пополнения"}
        id={"subscriptionInputDateReplenishBalance"}
        classContainer={"replenish-subscription__input"}
        type={"number"}
        value={isAmount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder={"0"}
      />

      <div className="replenish-subscription__buttons">
        <Button
          className={"replenish-subscription__button-cancel"}
          colorStyle={"invisible"}
          onClick={() => {
            openModal(false);
          }}
        >
          Отмена
        </Button>
        <Button
          colorStyle={"blue"}
          onClick={handleReplenishBalance}
          disabled={!isAmount || isLoading}
        >
          Сохранить
        </Button>
      </div>
      {isLoading && (
        <div className="container-loader-subscription">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}
    </div>
  );
};

export default ReplenishSubscription;
