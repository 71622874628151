import "./viewingLessonStyles.sass";
import { useEffect, useState } from "react";
import Button from "../button/Button";
import { databases } from "../../lib/appwrite";
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Snackbar,
  Stack,
} from "@mui/material";

const typeOfLesson = (type) => {
  switch (type) {
    case "indiv":
      return "Индивидуальный урок";
    case "group":
      return "Групповой урок";
    case "acter":
      return "Актерское мастерство";
    case "trial":
      return "Пробный урок";
    default:
      return "----";
  }
};

const ViewingLessonTeacher = ({ dataLesson, isModalOpen }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [studentData, setStudentData] = useState([]);
  const [isAlertError, setAlertError] = useState(false);
  const [isErrorMessageAlert, setErrorMessageAlert] = useState(["", ""]);
  const dateLesson = new Date(dataLesson.date_start).toLocaleDateString(
    "ru-RU",
    { day: "numeric", month: "long" }
  );

  console.log(studentData);

  useEffect(() => {
    if (dataLesson) {
      getUserData(dataLesson.students);
    }
  }, [dataLesson]);

  async function getUserData(students) {
    setIsLoading(true);
    try {
      // Инициализация массива промисов
      const promises = [];

      // Запросы данных студентов
      const studentPromises = students.map((studentId) =>
        databases.getDocument(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          studentId
        )
      );
      promises.push(...studentPromises);

      // Ожидание выполнения всех промисов
      const results = await Promise.all(promises);

      setStudentData(results);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setErrorMessageAlert(["Ошибка получения данных", error.message]);
      setAlertError(true);
    } finally {
      setIsLoading(false);
    }
  }

  const studentRecords = (students) => {
    return students.map((student, index) => {
      return (
        <div className="viewing-lesson-teacher__record" key={index}>
          {student && student.avatar_image ? (
            <img
              className="viewing-lesson-teacher__avatar"
              src={student.avatar_image}
              alt="Avatar"
            />
          ) : (
            <div className="viewing-lesson-teacher__avatar"></div>
          )}
          <h4 className="viewing-lesson-teacher__student-name">{`${student.name} ${student.sur_name}`}</h4>
        </div>
      );
    });
  };

  return (
    <div className="viewing-lesson-blackout-teacher">
      {!isLoading && (
        <div className="viewing-lesson-teacher">
          <div className="viewing-lesson-teacher__date-and-type">
            <h4 className="viewing-lesson-teacher__date">
              {`${dateLesson}, ${Math.floor(
                dataLesson.time_start + 10
              )}:${String(
                Math.floor(((dataLesson.time_start + 10) % 1) * 60)
              ).padStart(2, "0")} - ${Math.floor(
                dataLesson.time_start + 10 + dataLesson.duration
              )}:${String(
                Math.floor(
                  ((dataLesson.time_start + 10 + dataLesson.duration) % 1) * 60
                )
              ).padStart(2, "0")}`}
            </h4>
            <h4 className="viewing-lesson-teacher__type">
              {typeOfLesson(dataLesson.type_lesson)}
            </h4>
          </div>
          <div className="viewing-lesson-teacher__details">
            {dataLesson.type_lesson !== "trial" &&
              dataLesson.type_lesson !== "acter" && (
                <div className="viewing-lesson-teacher__info">
                  <h4 className="viewing-lesson-teacher__title">Урок</h4>
                  <h4 className="viewing-lesson-teacher__content">
                    {dataLesson.lesson_module === 0 &&
                    dataLesson.class_number === 0
                      ? "Урок не указан"
                      : `${dataLesson.lesson_module} модуль ${dataLesson.class_number} урок`}
                  </h4>
                </div>
              )}
            <div className="viewing-lesson-teacher__info">
              <h4 className="viewing-lesson-teacher__title">Комментарий</h4>
              <h4 className="viewing-lesson-teacher__content">
                {dataLesson.comment ? dataLesson.comment : "----"}
              </h4>
            </div>
          </div>
          <div className="viewing-lesson-teacher__students">
            <h4 className="viewing-lesson-teacher__students-title">
              Ученик(-и)
            </h4>
            <div className="viewing-lesson-teacher__students-list">
              {studentData ? studentRecords(studentData) : "Нет учеников"}
            </div>
          </div>
          <div className="viewing-lesson-teacher__buttons">
            <Button colorStyle={"invisible"} onClick={() => isModalOpen(false)}>
              Закрыть
            </Button>
          </div>
        </div>
      )}
      {isLoading && (
        <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
          <CircularProgress color="inherit" />
        </Stack>
      )}
      <div
        className="viewing-lesson-box-close"
        onClick={() => isModalOpen(false)}
      ></div>

      <Snackbar
        open={isAlertError}
        autoHideDuration={6000}
        onClose={() => setAlertError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAlertError(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <AlertTitle>{isErrorMessageAlert[0]}</AlertTitle>
          {isErrorMessageAlert[1]}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ViewingLessonTeacher;
