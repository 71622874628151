import { useState } from "react";
import Button from "../button/Button";
import "./addNewSubscription.sass";
import Input from "../input/Input";
import OptionMenu from "../optionMenu/OptionMenu";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { databases } from "../../lib/appwrite";
import recordingAnOperation from "../../hooks/recordingAnOperation";
import { ID } from "appwrite";

const AddNewSubscription = ({ uid, openModal }) => {
  const { updateOperationInfo } = recordingAnOperation();
  const [isLoading, setLoading] = useState(false);
  const [isBalance, setBalance] = useState("");
  const [isLessons, setLessons] = useState("");
  const [isDateStart, setDateStart] = useState("");
  const [isDateEnd, setDateEnd] = useState("");
  const [isTypePayment, setTypePayment] = useState("");
  const [isDownpayment, setDownpayment] = useState("");
  const [isErrorAppWrite, setErrorAppWrite] = useState(false);
  const [isError, setError] = useState(false);
  const optionsPayment = [
    { value: "", label: "Выберите..." },
    { value: "instalment", label: "Рассрочка" },
    { value: "full-amount", label: "Оплата сразу" },
  ];

  const handleSubmit = () => {
    setError(false);
    let isValid = true;

    if (!isBalance && isBalance === "0") {
      isValid = false;
    }

    if (!isLessons && isLessons === "0") {
      isValid = false;
    }
    if (!isDateStart) {
      isValid = false;
    }
    if (!isDateEnd) {
      isValid = false;
    }
    if (!isTypePayment) {
      isValid = false;
    }
    if (isTypePayment === "instalment" && !isDownpayment) {
      isValid = false;
    }

    if (!isValid) {
      setError(true);
    } else {
      setError(false);
      handleSaveNewSubscription();
    }
  };

  const handleSaveNewSubscription = async () => {
    const pricePerLesson = isBalance / isLessons;

    // Проверка деления на ноль
    if (!isLessons || !isBalance) {
      console.error(
        "Некорректные данные: количество занятий или баланс равны нулю."
      );
      return;
    }

    const remainsBalance =
      isTypePayment === "instalment" ? isDownpayment : isBalance;
    const remainsLessons =
      isTypePayment === "instalment"
        ? Math.floor(isDownpayment / pricePerLesson)
        : isLessons;

    try {
      setLoading(true);
      await databases.createDocument(
        "65f9d6f4dbeeccbe7e74",
        "65fef6b362018b62c8c6",
        ID.unique(),
        {
          user_id: uid,
          start_date: new Date(isDateStart).toISOString(),
          end_date: new Date(isDateEnd).toISOString(),
          number_of_lessons: Number(isLessons),
          price: Number(isBalance),
          remains_balance: Number(remainsBalance),
          remains_lessons: Number(remainsLessons),
          introduced: Number(remainsBalance),
          type_payment: isTypePayment,
        }
      );
      updateOperationInfo("salesVolume", Math.floor(Number(isBalance)));
      openModal(false);
    } catch (error) {
      setErrorAppWrite(true);
      console.error("Ошибка при создании абонемента:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-new-subscription">
      <h4 className="add-new-subscription__title">Новый абонемент</h4>
      <div className="add-new-subscription__data-container">
        <Input
          title={"Сумма"}
          id={"subscriptionInputBalance"}
          className={`${isBalance === "" && isError ? "error" : ""} ${
            isBalance === "0" && isError ? "error" : ""
          }`}
          classContainer={"add-new-subscription__input"}
          type={"number"}
          value={isBalance}
          onChange={(e) => setBalance(e.target.value)}
          placeholder={"Сумма"}
        />
        <Input
          title={"Уроки"}
          id={"subscriptionInputLessons"}
          className={`${isLessons === "" && isError ? "error" : ""} ${
            isLessons === "0" && isError ? "error" : ""
          }`}
          classContainer={"add-new-subscription__input"}
          type={"number"}
          value={isLessons}
          onChange={(e) => setLessons(e.target.value)}
          placeholder={"Кол-во уроков"}
        />
        <Input
          title={"Начало"}
          id={"subscriptionInputDateStart"}
          className={isDateStart === "" && isError ? "error" : ""}
          classContainer={"add-new-subscription__input"}
          type={"date"}
          value={isDateStart}
          onChange={(e) => setDateStart(e.target.value)}
          placeholder={"Дата начала"}
        />
        <Input
          title={"Окончание"}
          id={"subscriptionInputDateEnd"}
          className={isDateEnd === "" && isError ? "error" : ""}
          classContainer={"add-new-subscription__input"}
          type={"date"}
          value={isDateEnd}
          onChange={(e) => setDateEnd(e.target.value)}
          placeholder={"Дата окончания"}
        />
        <OptionMenu
          title={"Способ оплаты"}
          id={"subscriptionOptionPayment"}
          classSelect={isTypePayment === "" && isError ? "error" : ""}
          className={`add-new-subscription__input`}
          options={optionsPayment}
          value={isTypePayment}
          onChange={(e) => setTypePayment(e.target.value)}
          placeholder={"Способ оплаты"}
        />
        <Input
          title={"Первый взнос"}
          id={"subscriptionInputDownpayment"}
          className={`${isTypePayment !== "instalment" && "input-hide"} ${
            isDownpayment === "" && isError && isTypePayment === "instalment"
              ? "error"
              : ""
          }`}
          disabled={isTypePayment !== "instalment"}
          classContainer={"add-new-subscription__input"}
          type={"number"}
          value={isDownpayment}
          onChange={(e) => setDownpayment(e.target.value)}
          placeholder={"Сумма"}
        />
      </div>
      <div className="add-new-subscription__buttons">
        {isError && (
          <h4 className="add-new-subscription__error-message">
            Пожалуйста, заполните все поля!
          </h4>
        )}
        {!isError && isErrorAppWrite && (
          <h4 className="add-new-subscription__error-message">
            Произошла ошибка на стороне сервера, повторите попытку
          </h4>
        )}
        <Button
          className={"add-new-subscription__button-cancel"}
          colorStyle={"invisible"}
          onClick={() => {
            openModal(false);
          }}
        >
          Отмена
        </Button>
        <Button colorStyle={"blue"} onClick={handleSubmit}>
          Сохранить
        </Button>
      </div>
      {isLoading && (
        <div className="container-loader-subscription">
          <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}
    </div>
  );
};

export default AddNewSubscription;
