import "./salesChart.sass";
//import { Bar } from "react-chartjs-2";
import { CircularProgress, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import { ru } from "date-fns/locale";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  TimeScale,
} from "chart.js";

import "chartjs-adapter-date-fns"; // Адаптер для форматирования дат НЕ УДАЛЯТЬ
import { Bar } from "react-chartjs-2";
import { format } from "date-fns";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  TimeScale
);

const SalesBarChart = ({ initialData, startDate, endDate, type, loading }) => {
  const [data, setData] = useState([]);
  const [isFooterColor, setFooterColor] = useState("#748091");
  const allMonth = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  useEffect(() => {
    if (startDate && endDate) {
      if (initialData && Array.isArray(initialData)) {
        const filteredData = initialData.filter((item) => {
          const itemDate = new Date(item.date).setHours(0, 0, 0, 1);
          const start = new Date(startDate).setHours(0, 0, 0, 0);
          const end = new Date(endDate).setHours(0, 0, 0, 0);
          return itemDate >= start && itemDate <= endDate;
        });
        setData(filteredData);
      }
    } else {
      setData(initialData);
    }
  }, [startDate, endDate, initialData]);

  const options = {
    responsive: true,
    plugins: {
      legend: { display: true },
      tooltip: {
        enabled: true,
        mode: "index",
        position: "average",
        intersect: false,
        backgroundColor: "#fff",
        titleColor: "#748091",
        titleAlign: "center",
        bodyColor: "#000",
        bodyAlign: "center",
        displayColors: false,
        borderColor: "rgba(116, 128, 145, 0.25)",
        borderWidth: 2,
        cornerRadius: 8,
        footerColor: isFooterColor,
        bodyFont: {
          size: 13,
          style: "normal",
          weight: "bold",
        },
        footerAlign: "center",
        footerMarginTop: 8,
        padding: 8,
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.25)",
        caretSize: 4,
        caretPadding: 12,

        callbacks: {
          title: function (context) {
            let dateString = context[0].label; // Теперь это строка, например, "24 мая 2024"
            let dateParts = dateString.split(" ");
            let formattedDate;

            if (type === "day") {
              formattedDate = dateString;
            } else if (type === "month") {
              formattedDate = allMonth[dateParts[0] - 1];
            } else if (type === "year") {
              formattedDate = dateString;
            }

            return formattedDate;
          },

          label: function (context) {
            let label = context.dataset.label || "";

            // Предполагаем, что данные общего числа учеников и активных учеников хранятся в разных датасетах
            let totalStudents =
              context.chart.data.datasets[0].data[context.dataIndex];
            let activeStudents =
              context.chart.data.datasets[1].data[context.dataIndex];
            let percentage = ((activeStudents / totalStudents) * 100).toFixed(
              1
            ); // Рассчитываем процент

            if (context.datasetIndex === 1) {
              // Если это датасет активных учеников, добавляем информацию о проценте
              label += `: ${context.parsed.y} (${percentage}%)`;
            } else {
              // Для других датасетов просто отображаем значение
              label += `: ${context.parsed.y}`;
            }

            return label;
          },

          footer: function (context) {
            let index = context[0].dataIndex;

            if (index === 0) {
              setFooterColor("#748091");
              return "----";
            }

            let currentData = data[index];

            let previousData = data[index - 1];

            if (!currentData.total_students || !previousData.total_students) {
              setFooterColor("#748091");
              return "----";
            }
            let difference =
              currentData.total_students - previousData.total_students;
            let arrow = difference === 0 ? "" : difference >= 0 ? "↑" : "↓";
            let color =
              difference === 0
                ? "#748091"
                : difference >= 0
                ? "#00B500"
                : "#FF0000";
            setFooterColor(color);

            // Возвращаем результат сравнения
            return `${arrow} ${difference.toFixed(0)}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: type,
          tooltipFormat:
            type === "year"
              ? "yyyy"
              : type === "day"
              ? "dd MMMM yyyy"
              : "MM yyyy",
          displayFormats: {
            day: "dd/MM",
            month: "MM/yyyy",
            year: "yyyy",
          },
        },
        adapters: {
          date: {
            locale: ru,
          },
        },
        grid: { display: false },
        ticks: {
          callback: function (value, index, values) {
            const date = new Date(value);
            const monthNumber = date.getMonth();

            switch (type) {
              case "day":
                return format(date, "dd MMMM", { locale: ru });
              case "month":
                if (monthNumber !== undefined) {
                  return allMonth[monthNumber];
                } else {
                  return format(date, "MM", { locale: ru });
                }
              case "year":
                return format(date, "yyyy", { locale: ru });
              default:
                return format(date, "dd/MM/yyyy", { locale: ru });
            }
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: type === "year" ? 1000 : type === "month" ? 300 : 100,
          callback: (value) => value,
        },
      },
    },
    maintainAspectRatio: false,
  };

  const dataChart = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: "Всего",
        data: data.map((item) => item.total_students),
        backgroundColor: "rgba(116, 128, 145, 1)",
        borderWidth: 1,
        borderRadius: 4,
      },
      {
        label: "Активные",
        data: data.map((item) => item.active_students),
        backgroundColor: "rgba(255, 190, 0, 1)",
        borderWidth: 1,
        borderRadius: 4,
      },
    ],
  };

  return loading ? (
    <div className="statistic-data-loading">
      <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
        <CircularProgress color="inherit" />
      </Stack>
    </div>
  ) : data.length === 0 ? (
    <div className="statistic-data-null">Нет данных</div>
  ) : (
    <Bar data={dataChart} options={options} />
  );
};

export default SalesBarChart;
