import "./sidebar.sass";
import logoSmall from "../../assets/logos/logo-small.svg";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext, AuthContextProvider } from "../../context/AuthContext";
import { useContext } from "react";
import { account } from "../../lib/appwrite";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dispatch, currentUser, logout } = useContext(AuthContext);

  const handleLogout = async () => {
    try {
      logout();
      navigate("/login");
    } catch (error) {
      console.error(`Ошибка при выходе из аккаунта: ${error}`);
    }
  };

  return (
    <div className="sidebar">
      <div className="top-content">
        <div className="sidebar__top">
          <Link to="/admin/dashboard">
            <img
              className="sidebar__logo"
              alt="Логотип ERP системы Голосовое"
            />
          </Link>
        </div>

        <div className="sidebar__center">
          <ul>
            <Link to="/admin/dashboard" style={{ textDecoration: "none" }}>
              <li
                className={
                  location.pathname === "/admin/dashboard" ||
                  location.pathname === "/admin/dashboard/" ||
                  location.pathname === "/admin/" ||
                  location.pathname === "/admin"
                    ? "active"
                    : ""
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M160-200v-360q0-19 8.5-36t23.5-28l240-180q21-16 48-16t48 16l240 180q15 11 23.5 28t8.5 36v360q0 33-23.5 56.5T720-120H600q-17 0-28.5-11.5T560-160v-200q0-17-11.5-28.5T520-400h-80q-17 0-28.5 11.5T400-360v200q0 17-11.5 28.5T360-120H240q-33 0-56.5-23.5T160-200Z" />
                </svg>
                <span>Главная</span>
              </li>
            </Link>

            <Link to="/admin/schedule" style={{ textDecoration: "none" }}>
              <li
                className={
                  location.pathname === "/admin/schedule" ||
                  location.pathname === "/admin/schedule/" ||
                  location.pathname === "/admin/schedule/creator-lesson" ||
                  location.pathname === "/admin/schedule/creator-lesson/"
                    ? "active"
                    : ""
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M580-240q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-40q0-17 11.5-28.5T280-880q17 0 28.5 11.5T320-840v40h320v-40q0-17 11.5-28.5T680-880q17 0 28.5 11.5T720-840v40h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Z" />
                </svg>
                <span>Расписание</span>
              </li>
            </Link>

            <Link to="/admin/students" style={{ textDecoration: "none" }}>
              <li
                className={
                  location.pathname === "/admin/students" ||
                  location.pathname === "/admin/students/" ||
                  location.pathname === "/admin/students/student-info-card" ||
                  location.pathname === "/admin/students/student-info-card/"
                    ? "active"
                    : ""
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M840-320v-236L518-381q-18 10-38 10t-38-10L104-565q-11-6-15.5-15T84-600q0-11 4.5-20t15.5-15l338-184q9-5 18.5-7.5T480-829q10 0 19.5 2.5T518-819l381 208q10 5 15.5 14.5T920-576v256q0 17-11.5 28.5T880-280q-17 0-28.5-11.5T840-320ZM442-141 242-249q-20-11-31-30t-11-41v-152l242 131q18 10 38 10t38-10l242-131v152q0 22-11 41t-31 30L518-141q-9 5-18.5 7.5T480-131q-10 0-19.5-2.5T442-141Z" />
                </svg>
                <span>Ученики</span>
              </li>
            </Link>

            <Link to="/admin/statistic" style={{ textDecoration: "none" }}>
              <li
                className={
                  location.pathname === "/admin/statistic" ||
                  location.pathname === "/admin/statistic/"
                    ? "active"
                    : ""
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M720-160q-33 0-56.5-23.5T640-240v-120q0-33 23.5-56.5T720-440q33 0 56.5 23.5T800-360v120q0 33-23.5 56.5T720-160Zm-240 0q-33 0-56.5-23.5T400-240v-480q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720v480q0 33-23.5 56.5T480-160Zm-240 0q-33 0-56.5-23.5T160-240v-280q0-33 23.5-56.5T240-600q33 0 56.5 23.5T320-520v280q0 33-23.5 56.5T240-160Z" />
                </svg>
                <span>Статистика</span>
              </li>
            </Link>

            <Link to="/admin/team" style={{ textDecoration: "none" }}>
              <li
                className={
                  location.pathname === "/admin/team" ||
                  location.pathname === "/admin/team/"
                    ? "active"
                    : ""
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M411-480q-28 0-46-21t-13-49l12-72q8-43 40.5-70.5T480-720q44 0 76.5 27.5T597-622l12 72q5 28-13 49t-46 21H411Zm24-80h91l-8-49q-2-14-13-22.5t-25-8.5q-14 0-24.5 8.5T443-609l-8 49ZM124-441q-23 1-39.5-9T63-481q-2-9-1-18t5-17q0 1-1-4-2-2-10-24-2-12 3-23t13-19l2-2q2-19 15.5-32t33.5-13q3 0 19 4l3-1q5-5 13-7.5t17-2.5q11 0 19.5 3.5T208-626q1 0 1.5.5t1.5.5q14 1 24.5 8.5T251-596q2 7 1.5 13.5T250-570q0 1 1 4 7 7 11 15.5t4 17.5q0 4-6 21-1 2 0 4l2 16q0 21-17.5 36T202-441h-78Zm676 1q-33 0-56.5-23.5T720-520q0-12 3.5-22.5T733-563l-28-25q-10-8-3.5-20t18.5-12h80q33 0 56.5 23.5T880-540v20q0 33-23.5 56.5T800-440ZM0-280v-23q0-44 44.5-70.5T160-400q13 0 25 .5t23 2.5q-14 20-21 43t-7 49v65H40q-17 0-28.5-11.5T0-280Zm240 0v-25q0-65 66.5-105T480-450q108 0 174 40t66 105v25q0 17-11.5 28.5T680-240H280q-17 0-28.5-11.5T240-280Zm560-120q72 0 116 26.5t44 70.5v23q0 17-11.5 28.5T920-240H780v-65q0-26-6.5-49T754-397q11-2 22.5-2.5t23.5-.5ZM435-560l8-49q2-14 12.5-22.5T480-640q14 0 25 8.5t13 22.5l8 49h-91Z" />
                </svg>
                <span>Команда</span>
              </li>
            </Link>

            <Link to="/admin/tasks" style={{ textDecoration: "none" }}>
              <li
                className={
                  location.pathname === "/admin/tasks" ||
                  location.pathname === "/admin/tasks/"
                    ? "active"
                    : ""
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="m221-313 142-142q12-12 28-11.5t28 12.5q11 12 11 28t-11 28L250-228q-12 12-28 12t-28-12l-86-86q-11-11-11-28t11-28q11-11 28-11t28 11l57 57Zm0-320 142-142q12-12 28-11.5t28 12.5q11 12 11 28t-11 28L250-548q-12 12-28 12t-28-12l-86-86q-11-11-11-28t11-28q11-11 28-11t28 11l57 57Zm339 353q-17 0-28.5-11.5T520-320q0-17 11.5-28.5T560-360h280q17 0 28.5 11.5T880-320q0 17-11.5 28.5T840-280H560Zm0-320q-17 0-28.5-11.5T520-640q0-17 11.5-28.5T560-680h280q17 0 28.5 11.5T880-640q0 17-11.5 28.5T840-600H560Z" />
                </svg>
                <span>Задачи</span>
              </li>
            </Link>
          </ul>
        </div>
      </div>

      <div className="sidebar__bottom">
        <button className="sidebar__exit-button" onClick={handleLogout}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h240q17 0 28.5 11.5T480-800q0 17-11.5 28.5T440-760H200v560h240q17 0 28.5 11.5T480-160q0 17-11.5 28.5T440-120H200Zm487-320H400q-17 0-28.5-11.5T360-480q0-17 11.5-28.5T400-520h287l-75-75q-11-11-11-27t11-28q11-12 28-12.5t29 11.5l143 143q12 12 12 28t-12 28L669-309q-12 12-28.5 11.5T612-310q-11-12-10.5-28.5T613-366l74-74Z" />
          </svg>
          <span>Выход</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
