import "./input.sass";

const Input = ({
  title,
  value,
  placeholder,
  className,
  type,
  onChange,
  titleVisible = true,
  autoComplete = "off",
  ...other
}) => {
  return (
    <label className={`container-input-text ${other.classContainer}`}>
      {titleVisible && (
        <div className="container-title">
          <h3 className={`${other.placeHolder} title-text`}>{title}</h3>
          <h4 className="subtitle-text">{other.subTitle}</h4>
        </div>
      )}
      <input
        autoComplete={autoComplete}
        className={`input-text ${className} ${other.disabled && "input-hide"}`}
        id={other.id}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={other.onKeyDown}
        lang={other.lang}
        disabled={other.disabled}
      />
    </label>
  );
};

export default Input;
