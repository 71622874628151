import { useStudents } from "../../context/StudentsContext";
import CheckBox from "../checkBox/CheckBox";
import "./renderUsers.sass";
import React, { useCallback } from "react";

// Форматирование данных
// Функция для форматирования номера телефона
const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.length !== 12) {
    console.error("Неверная длина номера телефона:", phoneNumber);
    return phoneNumber; // Возвращаем оригинальный номер, если длина неверна
  }

  const parts = phoneNumber.split("");
  return `${parts.slice(0, 2).join("")} (${parts.slice(2, 5).join("")}) ${parts
    .slice(5, 8)
    .join("")}-${parts.slice(8, 10).join("")}-${parts.slice(10).join("")}`;
};

const formatter = new Intl.NumberFormat("ru-RU", {
  style: "currency",
  currency: "RUB",
  maximumFractionDigits: 0,
});

const RenderUsers = ({
  userStudents,
  maxStudents,
  setErrorLimitStudents,
  lessonType,
}) => {
  const { addStudent, selectedStudentsLesson } = useStudents();

  const handleRowClick = useCallback(
    (user) => {
      const exceededTheLimit = addStudent(
        { uid: user.$id, data: user },
        maxStudents
      );
      setErrorLimitStudents(exceededTheLimit);
    },
    [maxStudents, addStudent, setErrorLimitStudents]
  );

  // Фильтрация учеников в зависимости от типа урока
  const filteredStudents = userStudents.filter((user) => {
    if (lessonType === "trial") {
      return user.trial_lesson_available === true;
    } else if (!lessonType) {
      return true; // Все ученики показываются, если lessonType пуст
    } else {
      return user.trial_lesson_available === false;
    }
  });

  return (
    <table>
      <tbody>
        {filteredStudents.map((user, index) => (
          <tr
            key={index}
            className={`record-student-lesson ${
              user.trial_lesson_available ? "trial-lesson-student" : ""
            }`}
            onClick={() => handleRowClick(user)}
          >
            <td className="record-student-lesson__user-student">{`${user.name} ${user.sur_name}`}</td>
            <td className="record-student-lesson__phone-number-student">
              {formatPhoneNumber(user.phone_number)}
            </td>
            <td className="record-student-lesson__balance-student">
              {formatter.format(user.total_balance)}
            </td>

            <td className="record-student-lesson__check-box-icon">
              <CheckBox
                setActiveCheckBoxStudent={selectedStudentsLesson.some(
                  (u) => u.uid === user.$id
                )}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RenderUsers;
