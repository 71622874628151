import { useEffect, useState } from "react";
import { databases, storage } from "../../lib/appwrite";
import ImageUploader from "../imageUploader/ImageUploader";
import Input from "../input/Input";
import "./studentInfoComponents.sass";
import Button from "../button/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import InputMultiline from "../input/InputMultiline";

// Удаление аватара
const deleteImageData = async (uid, idAvatar) => {
  await storage.deleteFile("65fb23f53117da6475ba", `${idAvatar}`);
  await databases.updateDocument(
    "65f9d6f4dbeeccbe7e74",
    "65f9d6fd218cc728529a",
    uid,
    {
      avatar_image: "",
      avatar_id: "",
    }
  );
};

// Обновление аватара
const uploadUserAvatar = async (userId, fileImage) => {
  if (!fileImage) {
    return;
  }

  let dataUser;
  try {
    dataUser = await databases.getDocument(
      "65f9d6f4dbeeccbe7e74",
      "65f9d6fd218cc728529a",
      userId
    );
  } catch (error) {
    console.error("Ошибка при получении данных пользователя:", error);
    return;
  }

  const newAvatarId = new Date().getTime().toString();
  const bucketId = "65fb23f53117da6475ba";

  // Попытка удаления существующего файла, если он есть
  if (dataUser && dataUser.avatar_id) {
    try {
      await storage.deleteFile(bucketId, dataUser.avatar_id);
    } catch (error) {
      console.error("Ошибка при удалении существующего файла:", error);
    }
  }

  try {
    // Загрузка нового файла
    await storage.createFile(bucketId, newAvatarId, fileImage);

    const downloadURL = storage.getFilePreview(`${bucketId}`, `${newAvatarId}`);

    await databases.updateDocument(
      "65f9d6f4dbeeccbe7e74",
      "65f9d6fd218cc728529a",
      userId,
      { avatar_image: downloadURL, avatar_id: newAvatarId }
    );

    // console.log("Информация о пользователе успешно обновлена.");
  } catch (error) {
    console.error("Ошибка при обновлении аватара пользователя:", error);
  }
};

const StudentEdit = ({ userUID, data, updateData }) => {
  const [isLoader, setLoader] = useState(false);

  const [loadImage, setLoadImage] = useState(false);
  const [fileImage, setFileImage] = useState("");
  const [readingFileImage, setReadingFileImage] = useState("");
  const [isContainerImage, setContainerImage] = useState(true);

  const [isName, setName] = useState("");
  const [isSurname, setSurname] = useState("");
  const [isPhoneNumber, setPhoneNumber] = useState("");
  const [isDateOfBirth, setDateOfBirth] = useState("");
  const [isCredit, setCredit] = useState("");
  const [isFreeClasses, setFreeClasses] = useState("");
  const [isComment, setComment] = useState("");

  const [errorAppWrite, setErrorAppWrite] = useState(false);

  useEffect(() => {
    if (data) {
      setName(data.name);
      setSurname(data.sur_name);
      setPhoneNumber(data.phone_number);
      setDateOfBirth(new Date(data.date_of_birth).toISOString().split("T")[0]);
      setCredit(data.points);
      setFreeClasses(data.free_classes);
      setComment(data.comment);
      setReadingFileImage(data.avatar_image);
      !data.avatar_image && setLoadImage(false);
    }
  }, [data]);

  const handleInputPhone = (e) => {
    const value = e.target.value.trim();
    if (value.length <= 12) {
      if (value.length > 0 && !value.startsWith("+")) {
        setPhoneNumber(`+${value}`);
      } else {
        setPhoneNumber(value);
      }
    }
  };

  const handleUpdateUserInfo = () => {
    updateData();
  };

  const handleUpdateData = async () => {
    setLoader(true);

    try {
      // Заполнение данных
      await databases.updateDocument(
        "65f9d6f4dbeeccbe7e74",
        "65f9d6fd218cc728529a",
        userUID,
        {
          name:
            isName === ""
              ? data.name
              : `${isName.charAt(0).toUpperCase()}${isName.slice(1)}`,

          sur_name:
            isSurname === ""
              ? data.sur_name
              : `${isSurname.charAt(0).toUpperCase()}${isSurname.slice(1)}`,

          phone_number:
            isPhoneNumber === "" ? data.phone_number : isPhoneNumber,

          date_of_birth:
            isDateOfBirth === ""
              ? data.date_of_birth
              : new Date(isDateOfBirth).toISOString(),
          points: isCredit === "" ? data.points : Number(isCredit),

          free_classes:
            isFreeClasses === "" ? data.free_classes : Number(isFreeClasses),
          comment: isComment,
        }
      );

      // Загрузка изображения
      !isContainerImage && deleteImageData(userUID, data.avatar_id);
      uploadUserAvatar(userUID, fileImage);

      handleUpdateUserInfo();
    } catch (err) {
      setErrorAppWrite(true);
      console.error(err);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="student-edit main-container">
      <div className="student-edit__container-data">
        <ImageUploader
          className={"student-edit__image-uploader"}
          imageFile={setFileImage}
          setFileImageURL={readingFileImage}
          loadingImage={loadImage}
          onLoad={() => {
            setLoadImage(false);
          }}
          containerSetImage={setContainerImage}
        />
        <div className="student-edit__inputs">
          <Input
            title={"Имя"}
            className={"student-edit__user-name capitalize"}
            classContainer={"student-edit__input"}
            id={"nameInputEditStudent"}
            type={"text"}
            value={isName}
            onChange={(e) => setName(e.target.value)}
            placeholder={"Имя"}
          />
          <Input
            title={"Фамилия"}
            className={"student-edit__user-surname capitalize"}
            classContainer={"student-edit__input"}
            id={"surnameInpitEditStudent"}
            type={"text"}
            value={isSurname}
            onChange={(e) => setSurname(e.target.value)}
            placeholder={"Фамилия"}
          />

          <Input
            title={"Номер телефона"}
            className={"student-edit__phone"}
            classContainer={"student-edit__input"}
            id={"phoneNumberEditStudent"}
            type={"text"}
            value={isPhoneNumber}
            onChange={handleInputPhone}
            placeholder={"Номер"}
          />

          <Input
            title={"Дата рождения"}
            className={"student-edit__date-of-birt"}
            classContainer={"student-edit__input"}
            id={"dateOfBirthEditStudent"}
            type={"date"}
            value={isDateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            placeholder={"xx.xx.xxxx"}
          />
          <Input
            title={"Кол-во баллов"}
            className={"student-edit__total-credit"}
            classContainer={"student-edit__input"}
            id={"creditEditStudent"}
            type={"number"}
            value={isCredit}
            onChange={(e) => setCredit(e.target.value)}
            placeholder={"Баллы"}
          />
          <Input
            title={"Бесплатные уроки"}
            className={"student-edit__free-classes"}
            classContainer={"student-edit__input"}
            id={"freeClassesEditStudent"}
            type={"number"}
            value={isFreeClasses}
            onChange={(e) => setFreeClasses(e.target.value)}
            placeholder={"Уроки"}
          />

          <InputMultiline
            title={"Комментарий"}
            id={"commentEditStudent"}
            className={`student-edit__input-comment`}
            classContainer={"input-add-student-comment"}
            type={"text"}
            value={isComment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={"Комментарий"}
          />
        </div>
      </div>
      <div className="student-edit__buttons">
        {errorAppWrite && (
          <h4 className="student-edit__error-message">
            Произошла ошибка на стороне сервера
          </h4>
        )}
        {/* <Button
          className={"student-edit__delete-student"}
          colorStyle={"red-inverse"}
        >
          Удалить ученика
        </Button> */}
        <Button colorStyle={"blue"} onClick={handleUpdateData}>
          Сохранить изменения
        </Button>
      </div>
      {isLoader && (
        <div className="loading-container-student-edit">
          <Stack sx={{ color: "#748091" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
          <h4 className="loading-container-student-edit__text">
            Обновление данных...
          </h4>
        </div>
      )}
    </div>
  );
};

export default StudentEdit;
