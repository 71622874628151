import ProgressCircle from "../progressCircle/ProgressCircle";
import "./studentInfoComponents.sass";

function calculateProgress(progressData) {
  const lessonsPerModule = 15;
  const moduleLessons = {};

  progressData.forEach((item) => {
    const [module, lesson] = item.split("|").map(Number);
    if (!moduleLessons[module]) {
      moduleLessons[module] = new Set(); // Используем Set для хранения уникальных уроков
    }
    moduleLessons[module].add(lesson);
  });

  let fullModulesCompleted = 0;
  let lessonsInCurrentModule = 0;
  let lastModule = Math.max(...Object.keys(moduleLessons).map(Number));

  // Подсчет полностью завершенных модулей
  Object.keys(moduleLessons).forEach((module) => {
    if (moduleLessons[module].size === lessonsPerModule) {
      fullModulesCompleted++;
    }
    if (Number(module) === lastModule) {
      lessonsInCurrentModule = moduleLessons[module].size; // Количество уроков в последнем модуле
    }
  });

  return {
    fullModulesCompleted,
    lessonsInCurrentModule,
  };
}

const StudentProgressInfo = ({ data }) => {
  let studentProgress;
  let allStudentLesson;

  if (data) {
    studentProgress = calculateProgress(data.progress);
  } else {
    studentProgress = { fullModulesCompleted: 0, lessonsInCurrentModule: 0 };
  }

  switch (studentProgress.fullModulesCompleted) {
    case 0:
      allStudentLesson = studentProgress.lessonsInCurrentModule;
      break;
    case 1:
      allStudentLesson = 15 + studentProgress.lessonsInCurrentModule;
      break;
    case 2:
      allStudentLesson = 30 + studentProgress.lessonsInCurrentModule;
      break;
    case 3:
      allStudentLesson = 45 + studentProgress.lessonsInCurrentModule;
      break;
    case 4:
      allStudentLesson = 60;
      break;
    default:
      allStudentLesson = 0;
  }

  return (
    <div className="student-progress-info main-container">
      <div className="student-progress-info__progress-data">
        <ProgressCircle
          progress={
            studentProgress.lessonsInCurrentModule === 15
              ? 0
              : studentProgress.lessonsInCurrentModule
          }
          total={15}
          color={"#FFBE00"}
          lessonText={"lesson"}
        />
        <ProgressCircle
          progress={studentProgress.fullModulesCompleted}
          total={4}
          color={"#0065FD"}
          lessonText={"module"}
        />
        <ProgressCircle
          progress={allStudentLesson}
          total={60}
          color={"#394DAA"}
          lessonText={"allLessons"}
        />
      </div>
    </div>
  );
};

export default StudentProgressInfo;
