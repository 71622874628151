import { useContext } from "react";

import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Schedule from "./pages/schedule/Schedule";
import Students from "./pages/students/Students";
import Statistic from "./pages/statistic/Statistic";
import Team from "./pages/team/Team";
import Tasks from "./pages/tasks/Tasks";
import CreatorLesson from "./pages/creatorLesson/CreatorLesson";
import StudentIsCard from "./pages/studentIsCard/StudentIsCard";
import ConnectionStatus from "./components/connectionStatus/ConnectionStatus";

import { AuthContext } from "./context/AuthContext";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { StudentProvider } from "./context/StudentsContext";
import TeacherHome from "./pages/teacherHome/TeacherHome";

export default function App() {
  const { currentUser, role } = useContext(AuthContext);


  const adminRoutes = [
    { path: "/admin", element: <Home /> },
    { path: "/admin/dashboard", element: <Home /> },
    { path: "/admin/schedule", element: <Schedule /> },
    {
      path: "/admin/schedule/creator-lesson",
      element: (
        <StudentProvider>
          <CreatorLesson />
        </StudentProvider>
      ),
    },
    { path: "/admin/students", element: <Students /> },
    { path: "/admin/students/student-info-card", element: <StudentIsCard /> },
    { path: "/admin/statistic", element: <Statistic /> },
    { path: "/admin/team", element: <Team /> },
    { path: "/admin/tasks", element: <Tasks /> },
  ];

  const teacherRoutes = [
    { path: "/teacher", element: <TeacherHome /> },
    { path: "/teacher/home", element: <TeacherHome /> },
  ];

  const developerRoutes = [
    { path: "/admin", element: <Home /> },
    { path: "/admin/dashboard", element: <Home /> },
    { path: "/admin/schedule", element: <Schedule /> },
    {
      path: "/admin/schedule/creator-lesson",
      element: (
        <StudentProvider>
          <CreatorLesson />
        </StudentProvider>
      ),
    },
    { path: "/admin/students", element: <Students /> },
    { path: "/admin/students/student-info-card", element: <StudentIsCard /> },
    { path: "/admin/statistic", element: <Statistic /> },
    { path: "/admin/team", element: <Team /> },
    { path: "/admin/tasks", element: <Tasks /> },
    { path: "/teacher", element: <TeacherHome /> },
    { path: "/teacher/home", element: <TeacherHome /> },
  ];

  const getRoutes = (routes) => {
    return routes.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={currentUser ? route.element : <Navigate to="/login" />}
      />
    ));
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          {role === "teacher" && getRoutes(teacherRoutes)}
          {role === "admin" && getRoutes(adminRoutes)}
          {role === "developer" && getRoutes(developerRoutes)}
          {/* Добавьте маршрут для несуществующих страниц */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
      <ConnectionStatus />
    </div>
  );
}
