import { useEffect, useRef, useState } from "react";
import CheckBox from "../checkBox/CheckBox";
import IconButton from "../iconButton/IconButton";
import SearchInput from "../searchInput/SearchInput";
import "./selectedStudentsList.sass";
import { databases } from "../../lib/appwrite";
import { Query } from "appwrite";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import RenderUsers from "../renderUsers/RenderUsers";
import { CSSTransition } from "react-transition-group";
import { useStudents } from "../../context/StudentsContext";
import Button from "../button/Button";
import { Alert, AlertTitle, Snackbar } from "@mui/material";

const SelectedStudentsList = ({
  maxStudents,
  isLoadingCreator,
  setOpenAddStudent,
  isUpdateStudentsList,
  setUpdateStudentsList,
  lessonType
}) => {
  const { selectedStudentsLesson, clearStudents } = useStudents();
  const recordsPerPage = 25; // Количество записей на страницу
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsList, setStudentsList] = useState([]); // Студенты текущей страницы
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const messageErrorRef = useRef(null);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errorLimitStudents, setErrorLimitStudents] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [searchStudentNull, setSearchStudentsNull] = useState(false);
  const [isAlertError, setAlertError] = useState(false);
  const [isErrorMessageAlert, setErrorMessageAlert] = useState(["", ""]);

  useEffect(() => {
    if (selectedStudentsLesson.length > 0) {
      setCheckBoxAll(true);
    } else {
      setCheckBoxAll(false);
    }
  }, [selectedStudentsLesson]);

  useEffect(() => {
    if (!checkBoxAll && selectedStudentsLesson.length > 0) {
      clearStudents();
    }
  }, [checkBoxAll]);

  useEffect(() => {
    if (selectedStudentsLesson.length > maxStudents) {
      setErrorLimitStudents(true);
    }
  }, [selectedStudentsLesson]);

  useEffect(() => {
    let timer;

    if (errorLimitStudents) {
      timer = setTimeout(() => {
        setErrorLimitStudents(false);
      }, 4000);
    }

    return () => clearTimeout(timer);
  }, [errorLimitStudents]);

  // Следующая страница
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Предыдущая страница
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Показатель видимости
  const currentRange = () => {
    const start = (currentPage - 1) * recordsPerPage;
    const end = Math.min(start + recordsPerPage, totalStudents);
    return `${start + 1} - ${end} из ${totalStudents}`;
  };

  // получение всего баланса
  async function fetchSubscriptions(userId) {
    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "65fef6b362018b62c8c6",
        [Query.equal("user_id", userId)],
        Query.select(["remains_balance"])
      );

      let totalRemainsBalance = 0;

      response.documents.forEach((doc) => {
        totalRemainsBalance += doc.remains_balance;
      });

      return totalRemainsBalance; // Возвращаем баланс
    } catch (error) {
      console.error("Ошибка при получении данных о балансе:", error);
      setErrorMessageAlert([
        "Ошибка при получении данных о балансе",
        error.message,
      ]);
      setAlertError(true);
      throw error;
    }
  }

  // Функция для загрузки студентов текущей страницы
  const fetchStudentsForPage = async (page) => {
    const start = (page - 1) * recordsPerPage;

    const optionsFilter = [
      Query.equal("role", "student"),
      Query.equal("city", "voronezh"),
      Query.limit(recordsPerPage),
      Query.offset(start),
      Query.orderDesc("date_of_arrival"),
    ];

    setIsLoading(true);
    try {
      // Получение списка студентов
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "65f9d6fd218cc728529a",
        optionsFilter
      );

      const studentsWithProgress = await Promise.all(
        response.documents.map(async (student) => {
          // Получение всех документов без фильтрации по массиву students
          const scheduleResponse = await databases.listDocuments(
            "65f9d6f4dbeeccbe7e74",
            "66143d053c25fc1c3d4d"
          );
      
          // Фильтрация расписания для текущего студента
          const studentSchedule = scheduleResponse.documents
            .filter((schedule) => schedule.students.includes(student.$id))
            .map((schedule) => ({
              module: schedule.lesson_module,
              lesson: schedule.class_number,
              attendance: schedule.attendance,
            }));
      
          // Формирование прогресса с учетом attendance
          const progressReserved = studentSchedule
            .filter((s) => !s.attendance) // Исключаем уроки с attendance === true
            .map((s) => `${s.module}|${s.lesson}`);
      
          const balance = await fetchSubscriptions(student.user_id);
      
          return {
            ...student,
            total_balance: balance,
            progressReserved,
          };
        })
      );

      // Обновление состояния с данными студентов текущей страницы
      setStudentsList(studentsWithProgress);
      setTotalStudents(response.total);
      setTotalPages(Math.ceil(response.total / recordsPerPage));
    } catch (error) {
      console.error("Ошибка при получении данных студентов:", error);
      setErrorMessageAlert(["Ошибка загрузки учеников", error.message]);
      setAlertError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Обработчик изменения страницы
  useEffect(() => {
    fetchStudentsForPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (isUpdateStudentsList) {
      fetchStudentsForPage(1);
      setCurrentPage(1);
      setUpdateStudentsList(false);
    }
  }, [isUpdateStudentsList]);

  useEffect(() => {
    // Таймер для задержки поиска
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // Задержка в 500 мс

    // Очистка таймера при размонтировании компонента или изменении searchTerm
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  // Выполнение поиска с использованием debouncedSearchTerm
  useEffect(() => {
    // Если searchTerm пустой, то обновляем список по умолчанию
    if (debouncedSearchTerm === "") {
      fetchStudentsForPage(currentPage);
    } else if (debouncedSearchTerm) {
      // Функция для выполнения поиска
      searchStudents("voronezh", debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const searchStudents = async (city, searchTerm) => {
    setIsLoading(true);
    try {
      // Массив промисов для каждого поля поиска
      const searchPromises = [
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("name", searchTerm),
          ]
        ),
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("sur_name", searchTerm),
          ]
        ),
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("phone_number", searchTerm),
          ]
        ),
        databases.listDocuments(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          [
            Query.equal("city", city),
            Query.equal("role", "student"),
            Query.search("user_id", searchTerm),
          ]
        ),
      ];

      // Выполнение всех запросов и объединение результатов
      const results = await Promise.all(searchPromises);
      const documents = results
        .flatMap((result) => result.documents)
        // Удаление дубликатов документов
        .filter(
          (doc, index, self) =>
            index === self.findIndex((t) => t.$id === doc.$id)
        );

      // Обработка полученных результатов
      const studentsWithBalance = await Promise.all(
        documents.map(async (student) => {
          const balance = await fetchSubscriptions(student.user_id);
          return { ...student, total_balance: balance };
        })
      );

      // Обновление состояния с данными студентов текущей страницы
      setStudentsList(studentsWithBalance);
    } catch (error) {
      console.error("Ошибка при поиске студентов:", error);
      setErrorMessageAlert(["Ошибка при поиске учеников", error.message]);
      setAlertError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (studentsList.length > 0) {
      setSearchStudentsNull(false);
    } else {
      setSearchStudentsNull(true);
    }
  }, [studentsList]);

  return (
    <div className={`container-selected-students`}>
      {isLoadingCreator && <div className="disabled-container"></div>}

      <CSSTransition
        nodeRef={messageErrorRef}
        in={errorLimitStudents}
        timeout={300}
        classNames="error-message-limit-students-animation"
        unmountOnExit
      >
        <div
          className="container-selected-students__error-message"
          ref={messageErrorRef}
        >
          Вы выбрали максимальное количество учеников для данного типа урока!
        </div>
      </CSSTransition>

      <div className={`container-selected-students__toolbar-container`}>
        <div className="container-selected-students__top-toolbar">
          <h4 className="container-selected-students__toolbar-title">
            Ученики
          </h4>
          <SearchInput
            className={"container-selected-students__search-input"}
            holderText={"Поиск ученика..."}
            onChange={(e) => setSearchTerm(e.target.value)}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M440-560q33 0 56.5-23.5T520-640q0-33-23.5-56.5T440-720q-33 0-56.5 23.5T360-640q0 33 23.5 56.5T440-560Zm0 160q45 0 84.5-19t68.5-54q-35-23-73.5-35T440-520q-41 0-79.5 12T287-473q29 35 68.5 54t84.5 19Zm0 160q-134 0-227-93t-93-227q0-134 93-227t227-93q134 0 227 93t93 227q0 56-18 105.5T692-364l160 160q11 11 11 28t-11 28q-11 11-28 11t-28-11L636-308q-41 32-90.5 50T440-240Zm0-80q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Zm0-240Z" />
              </svg>
            }
          />
          <IconButton
            className={"container-selected-students__button-update-data"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-70q0-17 11.5-28.5T760-800q17 0 28.5 11.5T800-760v200q0 17-11.5 28.5T760-520H560q-17 0-28.5-11.5T520-560q0-17 11.5-28.5T560-600h128q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q68 0 124.5-34.5T692-367q8-14 22.5-19.5t29.5-.5q16 5 23 21t-1 30q-41 80-117 128t-169 48Z" />
              </svg>
            }
            onClick={() => fetchStudentsForPage(currentPage)}
          />
        </div>

        <div className="container-selected-students__bottom-toolbar">
          <h4 className="container-selected-students__text-start">Ученик</h4>
          <h4 className="container-selected-students__text-center">
            Номер телефона
          </h4>
          <h4 className="container-selected-students__text-end">Баланс</h4>
        </div>
        <CheckBox
          className={"container-selected-students__check-box-all"}
          checkBoxStyle={"all"}
          setActiveCheckBox={setCheckBoxAll}
          activeCheckBox={checkBoxAll}
        />
      </div>

      <div className="container-selected-students__students-list">
        {isLoading ? (
          <div className="container-selected-students__container-loader">
            <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
              <CircularProgress color="inherit" />
            </Stack>
          </div>
        ) : searchStudentNull ? (
          <h4 className="container-selected-students__students-null">
            Нет данных
          </h4>
        ) : (
          <RenderUsers
            userStudents={studentsList}
            checkBoxAll={checkBoxAll}
            setCheckBoxAll={setCheckBoxAll}
            selectedStudents={selectedStudentsLesson}
            maxStudents={maxStudents}
            setErrorLimitStudents={setErrorLimitStudents}
            lessonType={lessonType}
          />
        )}
      </div>

      <div className="container-selected-students__bottom-toolbar-page">
        <Button
          colorStyle={"blue"}
          className={"container-selected-students__add-student"}
          onClick={() => setOpenAddStudent(true)}
        >
          Добавить ученика
        </Button>
        <div className="container-selected-students__container-selector-page">
          <div className="container-selected-students__current-range">
            {currentRange()}
          </div>

          <div className="container-selected-students__buttons">
            <IconButton
              className={"container-selected-students__left-button"}
              onClick={previousPage}
              disabled={currentPage === 1}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="m432-480 156 156q11 11 11 28t-11 28q-11 11-28 11t-28-11L348-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l184-184q11-11 28-11t28 11q11 11 11 28t-11 28L432-480Z" />
                </svg>
              }
            />
            <IconButton
              className={"container-selected-students__right-button"}
              onClick={nextPage}
              disabled={currentPage === totalPages}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z" />
                </svg>
              }
            />
          </div>
        </div>
      </div>
      <Snackbar
        open={isAlertError}
        autoHideDuration={6000}
        onClose={() => setAlertError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAlertError(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <AlertTitle>{isErrorMessageAlert[0]}</AlertTitle>
          {isErrorMessageAlert[1]}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SelectedStudentsList;
