import "./iconButton.sass";

const IconButton = ({ className, icon, onClick, onMouseEnter, disabled }) => {
  return (
    <i
      className={`icon-button ${className}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {icon}
    </i>
  );
};

export default IconButton;
