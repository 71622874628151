import "./salesChart.sass";
import { CircularProgress, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import { ru } from "date-fns/locale";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  TimeScale,
  ArcElement,
} from "chart.js";

import "chartjs-adapter-date-fns"; // Адаптер для форматирования дат НЕ УДАЛЯТЬ
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  TimeScale,
  ArcElement
);

const SalesPieChart = ({ initialData, loading }) => {
  const [dataChart, setDataChart] = useState({
    datasets: [],
  });

  useEffect(() => {
    if (initialData) {
      setDataChart({
        datasets: [
          {
            labels: ["Активные", "Неактивные"],
            data: [initialData.inactiveStudents, initialData.activeStudents],
            backgroundColor: ["rgba(0, 101, 253, 1)", "rgba(60, 96, 150, 1)"],
            borderWidth: 0,
            hoverBackgroundColor: [
              "rgba(0, 101, 253, 1)",
              "rgba(60, 96, 150, 1)",
            ],
            cutout: "80%",
            spacing: 1,
          },
        ],
      });
    }
  }, [initialData]);

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
    },

    maintainAspectRatio: false,
  };

  return loading ? (
    <div>Loading...</div>
  ) : (
    <Doughnut data={dataChart} options={options} />
  );
};

export default SalesPieChart;
