import { useEffect, useState } from "react";
import IconButton from "../iconButton/IconButton";
import "./viewingSubscription.sass";
import PopupMenu from "../popupMenu/PopupMenu";
import ChangingSubscription from "../changingSubscription/ChangingSubscription";
import ReplenishSubscription from "../replenishSubscription/ReplenishSubscription";
import { databases } from "../../lib/appwrite";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../button/Button";
import PaymentSubscriptionForced from "../paymentSubscriptionForced/PaymentSubscriptionForced";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import recordingAnOperation from "../../hooks/recordingAnOperation";

const ViewingSubscription = ({ data, modalOpen, updateInfo }) => {
  const { statusOperation, updateOperationInfo } = recordingAnOperation();

  const [isData, setData] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isOpenChanging, setOpenChanging] = useState(false);
  const [isOpenReplenish, setOpenReplenish] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [isOpenPaymentDialog, setOpenPaymentDialog] = useState(false);
  let optionsPopupMenu = [
    { label: "Списать оплату", key: "payForLesson" },
    { label: "Изменить", key: "toChange" },
    { label: "Обнулить", key: "resetSubscription" },
    { label: "Удалить", key: "deleteSubscription" },
  ];

  if (data.type_payment === "instalment" && data.introduced < data.price) {
    optionsPopupMenu.unshift({ label: "Пополнить", key: "replenishBalance" });
  }

  // Открытие меню
  const handlePopupMenuOpen = () => {
    setMenuOpen(true);
  };

  // Закрытие меню
  const handlePopupMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = (item) => {
    switch (item.key) {
      case "replenishBalance":
        setOpenReplenish(true);
        handlePopupMenuClose();
        break;

      case "payForLesson":
        setOpenPaymentDialog(true);
        handlePopupMenuClose();
        break;

      case "toChange":
        setOpenChanging(true);
        handlePopupMenuClose();
        break;

      case "resetSubscription":
        handleOpenDialogReset();
        handlePopupMenuClose();
        break;

      case "deleteSubscription":
        handleOpenDialogDelete();
        handlePopupMenuClose();
        break;
    }
  };

  useEffect(() => {
    if (data) {
      handleUpdateInfo();
    }
  }, [data]);

  const handleUpdateInfo = async () => {
    setLoading(true);
    try {
      const dataSubscriptionInfo = await databases.getDocument(
        "65f9d6f4dbeeccbe7e74",
        "65fef6b362018b62c8c6",
        data.$id
      );
      setData(dataSubscriptionInfo);
    } catch (error) {
      console.error("Ошибка при обновлении: ", error);
      return;
    } finally {
      setLoading(false);
    }
  };

  // Открытие окна удаления
  const handleOpenDialogDelete = () => {
    setOpenDeleteDialog(true);
  };

  // Закрытие окна удаления
  const handleCloseDialogDelete = () => {
    setOpenDeleteDialog(false);
  };

  // Удаление абонемента
  const handleDeleteSubscription = async () => {
    try {
      await databases.deleteDocument(
        "65f9d6f4dbeeccbe7e74",
        "65fef6b362018b62c8c6",
        data.$id
      );

      handleCloseDialogDelete();
      updateInfo();
      modalOpen(false);
    } catch (error) {
      console.error("Ошибка при удалении:", error);
    }
  };

  // Открытие окна обнуления
  const handleOpenDialogReset = () => {
    setOpenResetDialog(true);
  };

  // Закрытие окна обнуления
  const handleCloseDialogReset = () => {
    setOpenResetDialog(false);
  };

  // Обнуление абонемента
  const handleResetSubscription = async () => {
    const totalAmountReset = data.remains_balance;
    try {
      await databases.updateDocument(
        "65f9d6f4dbeeccbe7e74",
        "65fef6b362018b62c8c6",
        data.$id,
        {
          remains_lessons: 0,
          remains_balance: 0,
        }
      );
      updateOperationInfo("realization", Math.floor(totalAmountReset));
      handleCloseDialogReset();
      updateInfo();
      modalOpen(false);
    } catch (error) {
      console.error("Ошибка при удалении:", error);
    }
  };

  return (
    <div className="viewing-blackout">
      <div
        className="viewing-zone-click"
        onClick={() => {
          modalOpen(false);
        }}
      ></div>

      {isOpenChanging && (
        <ChangingSubscription
          idSubscription={data}
          openModal={setOpenChanging}
          isUpdate={updateInfo}
          updateInfoSubscription={handleUpdateInfo}
        />
      )}

      {isOpenPaymentDialog && (
        <PaymentSubscriptionForced
          isSubscription={data}
          openModal={setOpenPaymentDialog}
          isUpdate={updateInfo}
          updateInfoSubscription={handleUpdateInfo}
        />
      )}

      {isOpenReplenish && (
        <ReplenishSubscription
          idSubscription={data}
          openModal={setOpenReplenish}
          isUpdate={updateInfo}
          updateInfoSubscription={handleUpdateInfo}
        />
      )}

      {!isOpenChanging && !isOpenReplenish && !isOpenPaymentDialog && (
        <div className="viewing-subscription">
          {isLoading && (
            <div className="container-loader-subscription">
              <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
                <CircularProgress color="inherit" />
              </Stack>
            </div>
          )}
          <div className="viewing-subscription__toolbar">
            <h4 className="viewing-subscription__title">Информация</h4>
            <IconButton
              onClick={handlePopupMenuOpen}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
                </svg>
              }
            />
          </div>
          <div className="viewing-subscription__content">
            <div className="viewing-subscription__price min-width-subscription-info">
              <h4 className="viewing-subscription__subtitle">
                Стоимость абонемента
              </h4>
              <h4 className="viewing-subscription__text">
                {isData ? `${isData.price}₽` : "---"}
              </h4>
            </div>
            <div className="viewing-subscription__all-classes min-width-subscription-info">
              <h4 className="viewing-subscription__subtitle">Кол-во уроков</h4>
              <h4 className="viewing-subscription__text">
                {isData ? isData.number_of_lessons : "---"}
              </h4>
            </div>
            <div className="viewing-subscription__introduced min-width-subscription-info">
              <h4 className="viewing-subscription__subtitle">Внесено</h4>
              <h4 className="viewing-subscription__text">
                {isData ? `${isData.introduced}₽` : "---"}
              </h4>
            </div>
            <div className="viewing-subscription__remains-balance min-width-subscription-info">
              <h4 className="viewing-subscription__subtitle">
                Осталось на балансе
              </h4>
              <h4 className="viewing-subscription__text">
                {isData ? `${isData.remains_balance}₽` : "---"}
              </h4>
            </div>
            <div className="viewing-subscription__remains-lessons min-width-subscription-info">
              <h4 className="viewing-subscription__subtitle">
                Кол-во доступных уроков
              </h4>
              <h4 className="viewing-subscription__text">
                {isData ? isData.remains_lessons : "---"}
              </h4>
            </div>
            <div className="viewing-subscription__validity-period min-width-subscription-info">
              <h4 className="viewing-subscription__subtitle">Срок действия</h4>
              <h4 className="viewing-subscription__text">
                {isData
                  ? `${Intl.DateTimeFormat("ru-RU").format(
                      new Date(isData.start_date)
                    )} - ${Intl.DateTimeFormat("ru-RU").format(
                      new Date(isData.end_date)
                    )}`
                  : "---"}
              </h4>
            </div>
          </div>
          <PopupMenu
            onMouseLeave={handlePopupMenuClose}
            inOpen={isMenuOpen}
            menuItems={optionsPopupMenu}
            onMenuItemClick={handleMenuItemClick}
            styleMenu={"menu-toolbar-subscription"}
          />
        </div>
      )}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDialogDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Подтверждение удаления абонемента`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="x">
            Вы собираетесь удалить абонемент. Пожалуйста, учтите, что при этом
            будет удалена вся оставшаяся информация, связанная с ним. Это
            включает в себя все данные об абонементе и оставшуюся денежную
            сумму.<br></br>
            <b className="red-text">Важно:</b> Оставшиеся денежные средства не
            будут учитываться в статистике и не могут быть восстановлены. Они
            будут безвозвратно потеряны.<br></br>Вы уверены, что хотите
            продолжить удаление?{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button colorStyle={"invisible"} onClick={handleCloseDialogDelete}>
            Отмена
          </Button>
          <Button
            colorStyle={"red"}
            onClick={() => handleDeleteSubscription()}
            autoFocus
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openResetDialog}
        onClose={handleCloseDialogReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Обнулить абонемент?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="x">
            При обнулении абонемента все занятия по нему станут недоступны, а
            оставшаяся сумма сгорит. Вы уверены?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button colorStyle={"invisible"} onClick={handleCloseDialogReset}>
            Отмена
          </Button>
          <Button
            colorStyle={"red"}
            onClick={() => handleResetSubscription()}
            autoFocus
          >
            Обнулить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewingSubscription;
