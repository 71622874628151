"use strict";
import "./toolbarWeek.sass";

const getCurrentWeekDates = (currentDate) => {
  const now = new Date(currentDate);
  const dayOfWeek = now.getDay();
  const firstDayOfWeek = new Date(now);
  firstDayOfWeek.setDate(
    now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
  );

  const currentWeek = Array.from({ length: 7 }).map((_, i) => {
    const day = new Date(firstDayOfWeek);
    day.setDate(firstDayOfWeek.getDate() + i);
    return day;
  });

  return currentWeek;
};

const dateActive = (dateWeek) => {
  const date = new Date(dateWeek);
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const formatDateWeek = (date) => {
  const weekday = date.toLocaleDateString("ru-RU", { weekday: "short" });
  const formattedWeekday = `${weekday.charAt(0).toUpperCase()}${weekday.slice(
    1
  )}`;
  return `${formattedWeekday}, ${date.getDate()}`;
};

const ToolbarWeek = ({ currentDateSchedule }) => {
  const weekDates = getCurrentWeekDates(currentDateSchedule);


  return (
    <div className="toolbar-week-date">
      <div className="week-grid">
        {weekDates.map((date) => (
          <div
            key={date.toISOString()}
            className={`week-day ${dateActive(date) && "week-day__active"}`}
          >
            <div className="week-day__name-date">{formatDateWeek(date)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToolbarWeek;
