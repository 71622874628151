import { createContext, useEffect, useReducer } from "react";
import { Account } from "appwrite";
import { client } from "../lib/appwrite";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("currentUser")) || null,
  role: localStorage.getItem("role") || null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    const account = new Account(client);

    // Проверяем JWT в localStorage
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      account.get().then(
        (user) => {
          const role = localStorage.getItem("role");
          dispatch({ type: "LOGIN", payload: { user, role } });
        },
        (error) => {
          console.log(error); // Обработка ошибок
        }
      );
    }
  }, []);

  useEffect(() => {
    if (state.currentUser) {
      localStorage.setItem("currentUser", JSON.stringify(state.currentUser));
      localStorage.setItem("role", state.role);
    } else {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("role");
    }
  }, [state.currentUser, state.role]);

  const logout = async () => {
    try {
      const account = new Account(client);
      await account.deleteSession("current"); // Завершаем текущую сессию
      dispatch({ type: "LOGOUT" });
    } catch (error) {
      console.error("Ошибка при выходе из аккаунта:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser: state.currentUser,
        role: state.role,
        dispatch,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
