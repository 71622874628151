import { useEffect, useState } from "react";
import "./recordLessonWeek.sass";
import ErrorMessage from "../errorMessage/ErrorMessage";
import { databases } from "../../lib/appwrite";

async function getUserData(user) {
  try {
    const data = await databases.getDocument(
      "65f9d6f4dbeeccbe7e74",
      "65f9d6fd218cc728529a",
      user
    );

    const userName = `${data.name} ${data.sur_name}`;
    return userName;
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
}

const RecordLessonWeek = ({
  id,
  className,
  style,
  attendance,
  onClick,
  userData,
  userRole,
}) => {
  const [type, setType] = useState("");

  useEffect(() => {
    switch (userData.type_lesson) {
      case "indiv":
        setType("lesson-indiv");
        break;
      case "group":
        setType("lesson-group");
        break;
      case "acter":
        setType("lesson-acter");
        break;
      case "trial":
        setType("lesson-trial");
        break;
      default:
        setType("");
    }
  }, [userData]);

  // Определение типа записи

  const [teacherName, setTeacherName] = useState("Загрузка...");

  useEffect(() => {
    let isActive = true; // Флаг для предотвращения установки состояния для размонтированного компонента

    async function fetchUserData() {
      if (!userData.teacher) {
        setTeacherName("Не указан");
        return;
      }

      try {
        const teacherData = await getUserData(userData.teacher);
        if (isActive) {
          setTeacherName(teacherData);
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        if (isActive) {
          setTeacherName("Ошибка");
        }
      }
    }

    fetchUserData();

    return () => {
      isActive = false; // При размонтировании компонента меняем флаг
    };
  }, [userData.teacher]);

  return (
    <div
      className={`record-lesson-week ${type} ${
        userData.passed && `lesson-passed ${type}__passed`
      } ${className} ${
        userData.lessonIsNotAvailable ? "lesson-is-not-available-student" : ""
      }`}
      id={id}
      style={style}
      onClick={onClick}
    >
      <h5 className={`record-lesson-week__time`}>{`${Math.floor(
        userData.time_start + 10
      )}:${String(Math.floor(((userData.time_start + 10) % 1) * 60)).padStart(
        2,
        "0"
      )} - ${Math.floor(userData.time_start + 10 + userData.duration)}:${String(
        Math.floor(((userData.time_start + 10 + userData.duration) % 1) * 60)
      ).padStart(2, "0")}`}</h5>
      {userRole !== "teacher" && (
        <h4 className={`record-lesson-week__name-teacher`}>{teacherName}</h4>
      )}

      {!attendance && (
        <ErrorMessage messageText={"Укажите посещаемость учеников!"} />
      )}
    </div>
  );
};

export default RecordLessonWeek;
