import { useLocation, useNavigate } from "react-router-dom";
import "./studentIsCard.sass";
import { useCallback, useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Button from "../../components/button/Button";
import TypeSelector from "../../components/typSelector/TypeSelector";
import MainStudentInfo from "../../components/studentInfoComponents/MainStudentInfo";
import StudentProgressInfo from "../../components/studentInfoComponents/StudentProgressInfo";
import StudentFullInfo from "../../components/studentInfoComponents/StudentFullInfo";
import StudentSubscription from "../../components/studentInfoComponents/StudentSubscription";
import StudentTasks from "../../components/studentInfoComponents/StudentTasks";
import CreatorTask from "../../components/creatorTask/CreatorTask";
import ViewingTask from "../../components/viewingTask/ViewingTask";
import StudentFullTasks from "../../components/studentInfoComponents/StudentFullTasks";
import { databases } from "../../lib/appwrite";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import StudentEdit from "../../components/studentInfoComponents/StudentEdit";
import IconButton from "../../components/iconButton/IconButton";
import StudentFutureLessons from "../../components/studentInfoComponents/StudentFutureLessons";
import StudentHistoryLessons from "../../components/studentInfoComponents/StudentHistoryLessons";

const StudentIsCard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [studentUid, setStudentUid] = useState("");
  const [studentPage, setStudentPage] = useState("home");
  const [openViewingTask, setOpenViewingTask] = useState(false);
  const [openCreatorTask, setOpenCreatorTask] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState("");
  const [openNewTask, setOpenNewTask] = useState(false);
  const [userData, setUserData] = useState(null);
  const typeSelectorOptions = [
    { value: "home", label: "Основное" },
    { value: "tasks", label: "Задачи" },
    { value: "records", label: "Записи" },
    { value: "edit", label: "Редактирование" },
  ];

  useEffect(() => {
    if (userData) {
      document.title = `Информация об ученике (${userData.name} ${userData.sur_name})`;
    } else {
      document.title = "Информация об ученике";
    }
  }, [, userData]);

  const handleBackNavigate = () => {
    navigate("/admin/students");
  };

  // Функция для получения данных
  async function fetchDocument() {
    try {
      const result = await databases.getDocument(
        "65f9d6f4dbeeccbe7e74",
        "65f9d6fd218cc728529a",
        studentUid
      );
      setUserData(result);
    } catch (error) {
      console.error("Ошибка получения данных: ", error);
    }

    setLoading(false);
  }

  // Вызов fetchDocument при изменении studentUid или userData
  useEffect(() => {
    if (studentUid !== "" && !userData) {
      setLoading(true);
      fetchDocument();
    } else {
      //console.log("Данные уже получены или повреждены");
    }
  }, [studentUid, userData]);

  useEffect(() => {
    const { state } = location;
    if (state && state.studentUID) {
      const { studentUID } = state;
      const studentId = studentUID !== "" ? studentUID : "";
      setStudentUid(studentId);
    }
  }, [location.state]);

  useEffect(() => {
    const toggleBodyOverflow = (value) => {
      document.body.style.overflow = value;
    };

    if (openCreatorTask || openViewingTask) {
      toggleBodyOverflow("hidden");
    } else {
      toggleBodyOverflow("visible");
      setSelectedUserInfo("");
    }

    return () => toggleBodyOverflow("visible");
  }, [openCreatorTask, openViewingTask]);

  // Обработчик для обновления данных
  const handleUpdateData = () => {
    fetchDocument();
  };

  const handleSelectionChange = useCallback((selectedValue) => {
    setStudentPage(selectedValue);
  }, []);

  return (
    <div className="student-is-card desktop-version">
      <Sidebar />
      <div className="main-container-pages">
        <div className="main-container-pages__container">
          <div className="main-container-pages__texts">
            <div className="main-container-pages__toolbar-texts">
              <Button
                className={"main-container-pages__button-back"}
                colorStyle={"blue-inverse icons"}
                onClick={handleBackNavigate}
              >
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z" />
                </svg>
              </Button>
              <h2 className="main-container-pages__title">
                Информация об ученике
              </h2>
            </div>
          </div>
          {isLoading ? (
            <div className="loading-container-student">
              <Stack sx={{ color: "#0065FD" }} spacing={2} direction="row">
                <CircularProgress color="inherit" />
              </Stack>
            </div>
          ) : (
            <div className="main-container-pages__container-data student-info-pages">
              <div className="student-info-pages__toolbar">
                <TypeSelector
                  className={"student-info-pages__selector-pages"}
                  options={typeSelectorOptions}
                  initialSelected={studentPage}
                  onSelectType={(value) => handleSelectionChange(value)}
                />
                <IconButton
                  onClick={() => handleUpdateData()}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-70q0-17 11.5-28.5T760-800q17 0 28.5 11.5T800-760v200q0 17-11.5 28.5T760-520H560q-17 0-28.5-11.5T520-560q0-17 11.5-28.5T560-600h128q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q68 0 124.5-34.5T692-367q8-14 22.5-19.5t29.5-.5q16 5 23 21t-1 30q-41 80-117 128t-169 48Z" />
                    </svg>
                  }
                />
              </div>

              {studentPage === "home" && (
                <div className="student-info-pages__information">
                  <MainStudentInfo data={userData} />
                  <StudentProgressInfo data={userData} />
                  <StudentFullInfo data={userData} />
                  <StudentSubscription userUID={studentUid} data={userData} />

                  <StudentTasks
                    userUID={studentUid}
                    viewingTaskOpen={setOpenViewingTask}
                    creatorTaskOpen={setOpenCreatorTask}
                    creatorTaskOpenCheck={openCreatorTask}
                    setSelectedUserInfo={setSelectedUserInfo}
                  />
                </div>
              )}
              {studentPage === "tasks" && (
                <div className="student-info-pages__tasks">
                  <StudentFullTasks
                    userUID={studentUid}
                    viewingTaskOpen={setOpenViewingTask}
                    creatorTaskOpen={setOpenCreatorTask}
                    creatorTaskOpenCheck={openCreatorTask}
                    setSelectedUserInfo={setSelectedUserInfo}
                    studentData={userData}
                    openingNewTask={setOpenNewTask}
                  />
                </div>
              )}
              {studentPage === "records" && (
                <div className="student-info-pages__lessons-info">
                  <StudentFutureLessons data={userData} />
                  <StudentHistoryLessons data={userData} />
                </div>
              )}
              {studentPage === "edit" && (
                <StudentEdit
                  userUID={studentUid}
                  data={userData}
                  updateData={handleUpdateData}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {openCreatorTask && (
        <CreatorTask
          isModalOpen={setOpenCreatorTask}
          editUserData={selectedUserInfo}
          studentChangeIsAllowed={false}
        />
      )}
      {openNewTask && (
        <CreatorTask
          isModalOpen={setOpenNewTask}
          newTaskClient={selectedUserInfo}
          studentChangeIsAllowed={false}
        />
      )}
      {openViewingTask && (
        <ViewingTask
          isOpenViewing={setOpenViewingTask}
          userDataView={selectedUserInfo}
          openingCreatorTask={setOpenCreatorTask}
          selectedInfo={setSelectedUserInfo}
        />
      )}
    </div>
  );
};

export default StudentIsCard;
