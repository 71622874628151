import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "./errorMessage.sass";

function ErrorMessage({ messageText }) {
  const iconRef = useRef(null);
  const messageRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ top: 0, left: 0 });
  const [messageVisible, setMessageVisible] = useState(false);

  useEffect(() => {
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      setIconPosition({
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, []);

  return (
    <>
      <div ref={iconRef} className="error-lesson-container-week__error-icon">
        <i
          className="error-lesson-container-week__error-icon"
          onMouseEnter={() => setMessageVisible(true)}
          onMouseLeave={() => setMessageVisible(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <rect width="16" height="16" rx="8" fill="white" />
            <path
              d="M7.99588 11.5556C8.18367 11.5556 8.34238 11.492 8.47201 11.365C8.60163 11.238 8.66645 11.0806 8.66645 10.8928C8.66645 10.705 8.60294 10.5463 8.47591 10.4167C8.34888 10.287 8.19147 10.2222 8.00369 10.2222C7.8159 10.2222 7.65719 10.2857 7.52756 10.4128C7.39793 10.5398 7.33312 10.6972 7.33312 10.885C7.33312 11.0728 7.39663 11.2315 7.52365 11.3611C7.65069 11.4907 7.8081 11.5556 7.99588 11.5556ZM7.99588 8.88889C8.18367 8.88889 8.34238 8.825 8.47201 8.69722C8.60163 8.56944 8.66645 8.41111 8.66645 8.22222V5.11111C8.66645 4.92222 8.60294 4.76389 8.47591 4.63611C8.34888 4.50833 8.19147 4.44444 8.00369 4.44444C7.8159 4.44444 7.65719 4.50833 7.52756 4.63611C7.39793 4.76389 7.33312 4.92222 7.33312 5.11111V8.22222C7.33312 8.41111 7.39663 8.56944 7.52365 8.69722C7.65069 8.825 7.8081 8.88889 7.99588 8.88889ZM8.00489 15.1111C7.02618 15.1111 6.10472 14.9259 5.24052 14.5556C4.37633 14.1852 3.62015 13.6759 2.97201 13.0278C2.32386 12.3796 1.8146 11.6237 1.44423 10.76C1.07386 9.89634 0.888672 8.9735 0.888672 7.99152C0.888672 7.00952 1.07386 6.0895 1.44423 5.23148C1.8146 4.37345 2.32386 3.62037 2.97201 2.97222C3.62015 2.32407 4.37607 1.81481 5.23975 1.44444C6.10344 1.07407 7.02628 0.888885 8.00827 0.888885C8.99027 0.888885 9.91028 1.07407 10.7683 1.44444C11.6263 1.81481 12.3794 2.32407 13.0276 2.97222C13.6757 3.62037 14.185 4.37484 14.5553 5.23563C14.9257 6.09643 15.1109 7.01618 15.1109 7.99489C15.1109 8.9736 14.9257 9.89506 14.5553 10.7593C14.185 11.6235 13.6757 12.3796 13.0276 13.0278C12.3794 13.6759 11.6249 14.1852 10.7642 14.5556C9.90335 14.9259 8.9836 15.1111 8.00489 15.1111Z"
              fill="#FF0000"
            />
          </svg>
        </i>
      </div>
      {ReactDOM.createPortal(
        <CSSTransition
          in={messageVisible}
          timeout={150}
          classNames="error-message-animation"
          unmountOnExit
          nodeRef={messageRef}
        >
          <div
            ref={messageRef}
            style={{
              top: `${iconPosition.top - 40}px`,
              left: `${iconPosition.left - 260}px`,
            }}
            className="error-message"
          >
            {messageText}
          </div>
        </CSSTransition>,
        document.body
      )}
    </>
  );
}

export default ErrorMessage;
