import React, { createContext, useContext, useState } from "react";
import { databases } from "../lib/appwrite";

// Создаем контекст
const StudentContext = createContext();

// Провайдер контекста
export const StudentProvider = ({ children }) => {
  const [selectedStudentsLesson, setSelectedStudentsLesson] = useState([]);
  const [isLoadingStudentsData, setLoadingStudentsData] = useState(false);

  // Добавление ученика с учетом максимального количества
  const addStudent = (student, maxStudents) => {
    let selectedStudents = false;
    setSelectedStudentsLesson((prevStudents) => {
      // Проверяем, есть ли уже ученик с таким ID
      const isExisting = prevStudents.some((s) => s.uid === student.uid);
      if (isExisting) {
        // Если есть, удаляем его
        return prevStudents.filter((s) => s.uid !== student.uid);
      } else {
        // Проверяем, не превышен ли лимит учеников
        if (prevStudents.length < maxStudents) {
          // Если нет, добавляем в список
          return [...prevStudents, student];
        } else {
          selectedStudents = true;
          // Если лимит превышен, возвращаем предыдущее состояние
          return prevStudents;
        }
      }
    });
    return selectedStudents;
  };

  // Функция для поиска и добавления учеников
  const findAndAddStudents = async (studentIds) => {
    setLoadingStudentsData(true);
    try {
      // Перебираем массив ID учеников
      for (const studentId of studentIds) {
        // Выполняем запрос к базе данных для поиска ученика по ID
        const student = await databases.getDocument(
          "65f9d6f4dbeeccbe7e74",
          "65f9d6fd218cc728529a",
          studentId
        );

        // Если ученик найден, добавляем его в список
        if (student) {
          setSelectedStudentsLesson((prevStudents) => {
            // Проверяем, есть ли ученик в списке
            const isExisting = prevStudents.some((s) => s.$id === student.$id);
            if (!isExisting) {
              // Если нет, добавляем ученика в список
              return [...prevStudents, { uid: student.$id, data: student }];
            }
            return prevStudents;
          });
        }
      }
    } catch (error) {
      console.error("Ошибка при поиске учеников:", error);
    } finally {
      setLoadingStudentsData(false);
    }
  };

  // Удаление ученика
  const removeStudent = (studentId) => {
    setSelectedStudentsLesson((prevStudents) =>
      prevStudents.filter((student) => student.uid !== studentId)
    );
  };

  // Очистка списка учеников
  const clearStudents = () => {
    setSelectedStudentsLesson([]);
  };

  return (
    <StudentContext.Provider
      value={{
        selectedStudentsLesson,
        addStudent,
        removeStudent,
        clearStudents,
        findAndAddStudents,
        isLoadingStudentsData,
      }}
    >
      {children}
    </StudentContext.Provider>
  );
};
// Хук для использования контекста
export const useStudents = () => {
  return useContext(StudentContext);
};
