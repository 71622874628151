import { useCallback, useEffect, useState } from "react";
import RecordTask from "../recordTask/RecordTask";
import TasksMini from "../tasksMini/TasksMini";
import "./homeInfoComponents.sass";
import { client, databases } from "../../lib/appwrite";
import { Query } from "appwrite";

const TasksHome = ({
  setModalViewingTaskOpen,
  setSelectedUserInfo,
  setModalNewTaskOpen,
}) => {
  // Значения
  const [isTasks, setTasks] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // Сегодняшняя дата
  const options = { day: "numeric", month: "long", year: "numeric" };
  // Фильтрация и поиск записей
  const todayDatabase = new Date();
  todayDatabase.setHours(23, 59, 59, 99);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Функция для получения данных по ролям
  const fetchDataByCity = async (city) => {
    try {
      const response = await databases.listDocuments(
        "65f9d6f4dbeeccbe7e74",
        "66041861c5966a6ddda5",
        [
          Query.equal("city", city),
          Query.lessThanEqual(
            "date_of_completion",
            todayDatabase.toISOString()
          ),
          Query.orderAsc("date_of_completion"),
        ]
      );
      return response.documents.map((doc) => ({ ...doc, city }));
    } catch (error) {
      console.error("Ошибка при получении данных города:", error);
      return [];
    }
  };

  // Обновление списка
  const handleUpdate = useCallback(async () => {
    try {
      const dataTasks = await fetchDataByCity("voronezh");
      setTasks(dataTasks);
    } catch (error) {
      console.error("Неожиданная ошибка при обновлении списка задач:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Обновление списка при открытии
  useEffect(() => {
    setLoading(true);
    handleUpdate();
  }, []);

  // Открытие окна просмотра
  const handleOpenViewingTask = (info) => {
    setSelectedUserInfo(info);
    setModalViewingTaskOpen(true);
  };

  // Нажатие на пункт меню
  const handleClickMenu = async (key, infoUser) => {
    switch (key) {
      case "done":
        await databases.deleteDocument(
          "65f9d6f4dbeeccbe7e74",
          "66041861c5966a6ddda5",
          infoUser.$id
        );
        break;

      case "edit":
        setSelectedUserInfo(infoUser);
        setModalNewTaskOpen(true);
        break;

      case "delete":
        await databases.deleteDocument(
          "65f9d6f4dbeeccbe7e74",
          "66041861c5966a6ddda5",
          infoUser.$id
        );
        break;
    }
  };

  // Обновление списка в реальном времени
  useEffect(() => {
    // Подписка на изменения в коллекции
    const unsubscribe = client.subscribe(
      "databases.65f9d6f4dbeeccbe7e74.collections.66041861c5966a6ddda5.documents",
      () => {
        handleUpdate();
      }
    );

    // Отписка от событий при размонтировании компонента
    return () => unsubscribe();
  }, []);

  // Запись
  const renderTasks = (tasks) => {
    return tasks.map((task, index) => (
      <RecordTask
        onClick={() => handleOpenViewingTask(task)}
        key={index}
        type={task.type}
        client={task.client}
        date={Intl.DateTimeFormat("ru-RU", options).format(
          new Date(task.date_of_completion)
        )}
        overdue={new Date(task.date_of_completion) < today ? true : false}
        itemClick={(item) => {
          handleClickMenu(item.key, task);
        }}
      />
    ));
  };

  return (
    <TasksMini className={"tasks-home"} colorLine={"all"} title={"Задачи"}>
      {isTasks.length > 0
        ? renderTasks(isTasks)
        : !isLoading && (
            <h4 className="tasks-home__text-placeholder">Нет записей</h4>
          )}
    </TasksMini>
  );
};

export default TasksHome;
